import TaxonomySaga from "redux/actions/taxonomy/Taxonomy.saga.js";
import AssociatedKPsSaga from "redux/actions/associatedKPs/associatedKPs.saga.js";
import MetadataSaga from 'redux/actions/metadata/metadata.saga';
import docvizSaga from 'redux/actions/docviz/docviz.saga.js';
import authorizationSaga from "redux/actions/authorization/authorization.saga.js";
import caseSaga from "redux/actions/casecode/case.saga.js";
import VersioningSaga from "redux/actions/versioning/versioning.saga";
import KnowledgePageSaga from "redux/actions/knowledgepage/knowledgepage.saga";
import KnowledgeHistorySaga from "redux/actions/knowledgehistory/knowledgehistory.saga";
import PaRecommendedSaga from "redux/actions/parecommened/parecommended.saga";
import CaseMetadataSaga from "redux/actions/caseMetadata/caseMetadata.saga.js";
import CaseOwnersSaga from "redux/actions/caseOwners/caseOwners.saga";
import CasesBTSaga from "redux/actions/casesBT/casesBT.saga";
import MyNavigatorSaga from "redux/actions/mynavigator/mynavigator.saga";
import UploadSaga from "redux/actions/upload/upload.saga";
import { put, call, all } from 'redux-saga/effects';
import { globalSagaFailure } from "./global.action.js";
import recommendedTaggingSaga from "redux/actions/recommendedtagging/recommendedtagging.saga.js";
import polyhierarchySaga from "redux/actions/polyhierarchy/polyhierarchy.saga.js";
import faceCardGeneratorSagav1 from "redux/actions/faceCardGeneratorv1/faceCardGeneratorv1.saga.js";
import proposalBioSaga from "redux/actions/proposalBio/proposalBio.saga.js";
import caseHistorySaga from "redux/actions/caseHistory/caseHistory.saga";
import GenaiSaga from "redux/actions/genai/genai.saga.js";
import materialStatusSaga from "./actions/materialstatus/materialstatus.saga.js";
import contributeknowledgeSaga from "./actions/contributeknowledge/contributeknowledge.saga.js";

export default function* globalSaga() {
    try {
        yield all([
            call(AssociatedKPsSaga),
            call(TaxonomySaga),
            call(MetadataSaga),
            call(authorizationSaga),
            call(caseSaga),
            call(VersioningSaga),
            call(docvizSaga),
            call(KnowledgeHistorySaga),
            call(proposalBioSaga),
            call(PaRecommendedSaga),
            call(CasesBTSaga),
            call(KnowledgePageSaga),
            call(CaseMetadataSaga),
            call(MyNavigatorSaga),
            call(UploadSaga),
            call(GenaiSaga),
            call(CaseOwnersSaga),
            call(recommendedTaggingSaga),
            call(faceCardGeneratorSagav1),
            call(polyhierarchySaga),
            call(caseHistorySaga),
            call(materialStatusSaga),
            call(contributeknowledgeSaga)
        ]);
    } catch (err) {
        yield put(globalSagaFailure(err))
    }
}