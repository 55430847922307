import { connectRouter, routerMiddleware } from "connected-react-router";
import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import history from "redux/history";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import {
  name as notifyReducerName,
  reducer as notifyReducer,
} from "./reducers/notification/notification_reducer";
import {
  name as uploadReducerName,
  reducer as uploadReducer,
} from "./reducers/upload/upload_reducer";
import {
  name as genaiReducerName,
  reducer as genaiReducer,
} from "./reducers/genai/genai_reducer";
import {
  name as materialstatusReducerName,
  reducer as materialstatusReducer,
} from "./reducers/materialstatus/materialstatus_reducer";
import {
  name as caseCodeName,
  reducer as caseCodeReducer,
} from "./reducers/casecode/casecode_reducer";
import {
  name as allMaterialsReducerName,
  reducer as allMaterialsReducer,
} from "./reducers/materials/all_materials_reducer";
import {
  name as materialReducerName,
  reducer as materialReducer,
} from "./reducers/materials/material_reducer";
import {
  name as metadataReducerName,
  reducer as metadataReducer,
} from "./reducers/metadata/metadata_reducer";

import {
  name as knowledgePageReducerName,
  reducer as knowledgePageReducer,
} from "./reducers/knowledgepage/knowledgepage_reducer";
import {
  name as proposalBioReducerName,
  reducer as proposalBioReducer,
} from "./reducers/proposalBio/proposalBio_reducer";


import {
  name as taxonomyReducerName,
  reducer as taxonomyReducer,
} from "./reducers/taxonomy/taxonomy_reducer";

import {
  name as knowledgeitemReducerName,
  reducer as knowledgeitemReducer,
} from "./reducers/knowledgeitem/knowledgeitem_reducer";

import {
  name as globalReducerName,
  reducer as globalReducer,
} from "./reducers/global/global_reducer";

import {
  name as authorizationReducerName,
  reducer as authorizationReducer,
} from "./reducers/authorization/authorization_reducer";

import {
  name as peopleresultsReducerName,
  reducer as peopleresultsReducer,
} from "./reducers/peopleresults/peopleresults_reducer";

import {
  name as pvtPeopleResultsReducerName,
  reducer as pvtCCOResultsReducer,
} from "./reducers/pvtpeopleresults/pvtpeopleresults_reducer";

import {
  name as mynavigatorReducerName,
  reducer as mynavigatorReducer,
} from "./reducers/mynavigator/mynavigator_reducer";

import {
  name as versioningReducerName,
  reducer as versioningReducer,
} from "./reducers/versioning/versioning_reducer";

import {
  name as caseReducerName,
  reducer as caseReducer,
} from "./reducers/casecode/case_reducer";

import {
  name as associatedKPsReducerName,
  reducer as associatedKPsReducer,
} from "./reducers/associatedKPs/associatedKPs_reducer";

import {
  name as docvizReducerName,
  reducer as docvizReducer,
} from "./reducers/docviz/docviz_reducer";

import {
  name as knowledgeHistoryName,
  reducer as knowledgeHistory,
} from "./reducers/knowledgehistory/knowledgehistory_reducer";

import {
  name as parecommenderName,
  reducer as parecommender,
} from "./reducers/parecommened/parecommened_reducer";

import {
  name as caseMetadataName,
  reducer as caseMetadataReducer,
} from "./reducers/caseMetadata/caseMetadata_reducer";

import {
  name as myCasesName,
  reducer as myCasesReducer,
} from "./reducers/casesBT/casesBT_reducer";

import {
  name as caseConsumptionName,
  reducer as caseConsumptionReducer,
} from "./reducers/caseConsumption/caseConsumption_reducer";

import {
  name as caseOwnersName,
  reducer as caseOwnersReducer,
} from "./reducers/caseOwners/caseOwners_reducer";

import {
  name as recommendedTaggingReducerName,
  reducer as recommendedTaggingReducer,
} from "./reducers/recommendedtagging/recommendedtagging_reducer";
import {
  name as faceCardGeneratorReducerName,
  reducer as faceCardGeneratorReducer,
} from "./reducers/faceCardGenerator/faceCardGenerator_reducer";

import {
  name as faceCardGeneratorReducerNamev1,
  reducer as faceCardGeneratorReducerv1,
} from "./reducers/faceCardGeneratorv1/faceCardGeneratorv1_reducer";

import {
  name as polyhierarchyReducerName,
  reducer as polyhierarchyReducer,
} from "./reducers/polyhierarchy/polyhierarchy_reducer";
import {
  name as caseHistoryReducerName,
  reducer as caseHistoryReducer,
} from "./reducers/caseHistory/caseHistory_reducer";
import {
  name as contributeknowledgeReducerName,
  reducer as contributeknowledgeReducer,
} from "./reducers/contributeknowledge/contributeknowledge_reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const middleware = [
  thunkMiddleware,
  sagaMiddleware,
  routerMiddleware(history),
  createLogger({ collapsed: true }),
];

const store = createStore(
  combineReducers({
    router: connectRouter(history),
    [notifyReducerName]: notifyReducer,
    [uploadReducerName]: uploadReducer,
    [genaiReducerName]: genaiReducer,
    [materialstatusReducerName]: materialstatusReducer,
    [caseOwnersName]: caseOwnersReducer,
    [caseCodeName]: caseCodeReducer,
    [caseReducerName]: caseReducer,
    [myCasesName]: myCasesReducer,
    [allMaterialsReducerName]: allMaterialsReducer,
    [materialReducerName]: materialReducer,
    [metadataReducerName]: metadataReducer,
    [taxonomyReducerName]: taxonomyReducer,
    [proposalBioReducerName]: proposalBioReducer,
    [knowledgeitemReducerName]: knowledgeitemReducer,
    [knowledgeitemReducerName]: knowledgeitemReducer,
    [peopleresultsReducerName]: peopleresultsReducer,
    [pvtPeopleResultsReducerName]: pvtCCOResultsReducer,
    [knowledgePageReducerName]: knowledgePageReducer,
    [globalReducerName]: globalReducer,
    [authorizationReducerName]: authorizationReducer,
    [mynavigatorReducerName]: mynavigatorReducer,
    [versioningReducerName]: versioningReducer,
    [associatedKPsReducerName]: associatedKPsReducer,
    [knowledgeHistoryName]: knowledgeHistory,
    [parecommenderName]: parecommender,
    [docvizReducerName]: docvizReducer,
    [caseMetadataName]: caseMetadataReducer,
    [caseConsumptionName]: caseConsumptionReducer,
    [recommendedTaggingReducerName]: recommendedTaggingReducer,
    [faceCardGeneratorReducerName]: faceCardGeneratorReducer,
    [faceCardGeneratorReducerNamev1]: faceCardGeneratorReducerv1,
    [polyhierarchyReducerName]: polyhierarchyReducer,
    [caseHistoryReducerName]: caseHistoryReducer,
    [contributeknowledgeReducerName]: contributeknowledgeReducer
  }),
  composeEnhancers(applyMiddleware(...middleware))
);
const runSaga = sagaMiddleware.run;
export { store, runSaga };
