/**
 * @desc this function is get all children/leaf nodes having further parents
 * @param item as object (key value pair)
 * @returns array of objects
 */
export const getAllLeafChildren = (item, responseKeyName) => {
  let childLeafs = [];
  const {children} = responseKeyName;
  let childrenItems = item[children];
  for (let eachchild in childrenItems) {
    if (!(childrenItems[eachchild][children].length)) {
      childLeafs.push(childrenItems[eachchild]);
    } else {
      let otherchild = getLeafChildren(childrenItems[eachchild], responseKeyName);
      childLeafs.push(...otherchild);
    }
  }
  return childLeafs;
}

/**
 * @desc this function is get all children/leaf nodes having no parent
 * @param item as object (key value pair)
 * @returns array of objects
 */

export const getLeafChildren = (item, responseKeyName, childLeaf = []) => {
  let childLeafs = childLeaf;
  const {children} = responseKeyName;
  let childrenItems = item[children];
  for (let eachchild in childrenItems) {
    if (!(childrenItems[eachchild][children].length)) {
      childLeafs.push(childrenItems[eachchild]);
    } else {
      getLeafChildren(childrenItems[eachchild], responseKeyName, childLeafs);
    }
  }

  return childLeafs;
}

/**
 * @desc this function is remove duplicate key value pairs
 * @param duplicatedata as array of objects
 * @returns array with unique key value pairs
 */
export const removeDuplicatesObjects = (duplicatedata) => {
    let uniqueObject = {};
    for (let i in duplicatedata) {
      let Id = duplicatedata[i]['Id'];
      uniqueObject[Id] = duplicatedata[i];
    }
  
    return uniqueObject;
  }