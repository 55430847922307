import { CONTRIBUTE_KNOWLEDGE } from "redux/constants";
export const name = "contributeknowledge";

const initialState = {
  loading: false,
  error: '',
  data: []
}
export const selectors = {
  getKnowledgeType: (state) => state[name].data.value,
};


export function reducer(state = initialState, action) {

  switch (action.type) {
    case CONTRIBUTE_KNOWLEDGE.GET_CONTRIBUTE_KNOWLEDGE:
      return {
        ...state,
        loading: true,
      };
    case CONTRIBUTE_KNOWLEDGE.GET_CONTRIBUTE_KNOWLEDGE_PENDING:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case CONTRIBUTE_KNOWLEDGE.GET_CONTRIBUTE_KNOWLEDGE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case CONTRIBUTE_KNOWLEDGE.GET_CONTRIBUTE_KNOWLEDGE_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};