/*
 * Use this component you need the prerequisite
 * react-bootstrap bootstrap npm modules
 * install these npm modules with npm install --save and modules name
 * after installation of dependencies
 * you can use this component like this <AddKnowledgeItems />
 * also you need inject required props
 */

// React
import React, { useEffect, useState, useCallback } from "react";
// React Prop types to validate data types of props
import PropTypes from "prop-types";

// React-Bootstrap
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// Semantic UI
import { Form } from "semantic-ui-react";
import OutsideClickHandler from 'react-outside-click-handler';
// Array move
import arrayMove from "array-move";
// React redux
//react-redux
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { bindActionCreators } from "redux";

// Shared components
import DraggableList from "components/shared/Draggable/DraggableList";
import { HelpIcon } from "components/shared/HelpIcon";
import Image from "components/shared/Image";
import { DeleteModal } from "components/DeleteModal/DeleteModal";
import { HelpModal } from "components/HelpModal";

// Reducer and Selectors
import { selectors as knowledgeItemSelectors } from "redux/reducers/knowledgeitem/knowledgeitem_reducer";
import { actions as knowledgeItemActions } from "redux/actions/knowledgeitem/knowledgeitem_actions";

// Assets, Images
import searchKPImage from "assets/images/search_kp.svg";
import SearchIcon from "assets/images/Enter/enter-arrow.svg";

// local configuration
import CONFIG from "./AddKnowledgeItems.config";

// Component scss(css)
import "./AddKnowledgeItems.scss";
import { isKPSavedinBundle } from "utils/helpers/helpers";

import { KnowledgeItem } from "components/KnowledgeItem";
import { Attachment } from "components/shared/Attachment";
import LABELS from "labels";
import GLOBAL_CONFIG from "config";

const {
  placeHolder: {
    title,
    searchbox,
    recentContributionsTitle
  }
} = CONFIG;

export const CustomListing = (props) => {
  const { renderListing, isDraggable } = props;
  return (
    isDraggable ?
      <DraggableList {...props} componentToDrag={renderListing()} />
      :
      renderListing()
  );
};

/**
 * @desc return the knowledge items list with search input
 * @returns html
 */
export const AddKnowledgeItems = ({
  defaultState,
  defaultStateKoidList,
  limit,
  validate,
  getItems,
  maxLength,
  handleOnSelection,
  handleOnSearch,
  handleOnRecentContributions,
  sendAnalyticsForHelpModal,
  minimumItemsMessage,
  handleDocvizPreview,
  searchLabel,
  searchPlaceHolder,
  recentTitle,
  showHelpIcon,
  className,
  showDelete,
  isDraggable,
  renderItemsList,
  rightChildren,
  nothingSearched,
  showSearchField,
  getKPFile
}) => {
  // Local constant
  const [itemsState, setItemsState] = useState(defaultState);
  const [koidList, setkoidList] = useState(defaultStateKoidList);
  const [inputState, setInputState] = useState({
    ...CONFIG.defaultValues,
  });
  const [validation, setValidation] = useState({
    ...CONFIG.defaultValidation,
  });
  // for delete pop
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isHelpmodalOpen, setHelpmodalOpen] = useState(false);
  const [knowledgeIndex, setKnowledgeIndex] = useState(null);

  const [hitEnter, setHitEnter] = useState(false);
  const [id, setId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchRecentItems, setSearchRecentItems] = useState(false);
  const [localSearchItem, setLocalSearchItem] = useState([]);
  const [noresultsFound, setnoresultsFound] = useState(false);
  // get the keys from redux store
  const { searchedItem, noResultsFound, recentContributions } = useSelector(
    (state) => ({
      // searched item in state
      searchedItem: knowledgeItemSelectors.getSearchedKnowledgeItemData(state),
      noResultsFound: knowledgeItemSelectors.getRecordStatus(state),
      recentContributions: knowledgeItemSelectors.getRecentContributions(state)
    }),
    shallowEqual
  );

  // dispatcher
  const dispatch = useDispatch((dispatch) =>
    bindActionCreators(...knowledgeItemActions, dispatch)
  );

  // use effect when items state update
  useEffect(() => {
    if (defaultState) {
      setItemsState(defaultState);
    }

    if (defaultStateKoidList) {
      setkoidList(defaultStateKoidList);
    }


  }, [setItemsState, defaultState, defaultStateKoidList, setkoidList]);

  /**
   * @desc reset local state
   * @memberof AddKnowledgeItems
   * @param no param
   * @return no return
   */
  const resetLocalState = useCallback(() => {
    setLocalSearchItem("");
    setnoresultsFound(false);
    setId("");
    setSearchTerm("");
    setSearchRecentItems(false);

    const { resetSearchState } = knowledgeItemActions;
    dispatch(resetSearchState());
  }, [dispatch]);

  // use effect to make an api call to search the material/Knowledge Object
  useEffect(() => {
    try {
      if (id) {
        handleOnSearch(id, "");
        resetLocalState();
      }
    } catch (err) {
      console.log(err);
    }
  }, [id, handleOnSearch, resetLocalState]);

  // use effect to make an api call to search the material/Knowledge Object
  useEffect(() => {
    try {
      if (searchTerm) {
        handleOnSearch("", searchTerm);
        resetLocalState();
      }
    } catch (err) {
      console.log(err);
    }
  }, [searchTerm, handleOnSearch, resetLocalState]);

  // use effect to make an api call to search for user's recent contributions
  useEffect(() => {
    try {
      if (searchRecentItems) {
        resetLocalState();
        handleOnRecentContributions();
      }
    } catch (err) {
      console.log(err);
    }
  }, [searchRecentItems, handleOnRecentContributions, resetLocalState]);

  // use effect to update item searched visible on screen
  useEffect(() => {
    // check searchedItem available
    if (hitEnter) {
      setLocalSearchItem(searchedItem);
    } else if (searchedItem) {
      setLocalSearchItem(recentContributions);
    }
  }, [searchedItem, recentContributions, hitEnter]);

  // use effect to update no record found
  useEffect(() => {
    // check noResultsFound true available
    if (noResultsFound) {
      setnoresultsFound(noResultsFound);
    }
  }, [noResultsFound]);

  // componentDidMount - clears the suggestion box state
  useEffect(() => {
    if (inputState.searchbox === "") {
      setLocalSearchItem("");
      setnoresultsFound(false);
    }
  }, [inputState.searchbox]);

  /**
   * @desc handle Error msg
   * @memberof AddKnowledgeItems
   * @param {Boolean} error
   * @param {String} errorMsg
   * @return html/null
   */
  const ErrorMsg = (error, errorMsg) =>
    !error ? <span className="add-ki__errormsg">{errorMsg}</span> : null;

  /**
   * @desc handle the button add
   * @memberof AddKnowledgeItems
   * @return no return
   */
  const handleSubmit = (event) => {
    // stop default redirect
    event.preventDefault();
    event.stopPropagation();

    // update the validation state
    setValidation({ ...CONFIG.defaultValidation });

    if (inputState.searchbox.trim().length > 0) {
      if (validate && CONFIG.inspectUrl(inputState.searchbox)) {
        // check if the search string is a url (to differentiate from just text)
        const kpId = CONFIG.inspectKpId(inputState.searchbox);
        const materialId = CONFIG.findMaterialIdFromUrl(inputState.searchbox);
        if (!kpId.length && (!materialId.length || !CONFIG.inspectMaterialId(inputState.searchbox))) {
          //regular search text
          setSearchTerm(inputState.searchbox);
          setHitEnter(true);
        } else {
          if (!CONFIG.inspectWhitelistedUrl(inputState.searchbox)) {
            // update the state if search string has url but not bcg whitelisted
            setValidation({ ...validation, urlCustom: false });
          } else if (!CONFIG.inspectMaterialUrl(inputState.searchbox)) {
            // update the state if search string has url but not bcg whitelisted
            setValidation({ ...validation, midCustom: false });
          } else {
            //valid whitelisted url
            if (kpId.length > 0) {
              // check if kp consumption url
              setId(kpId);
              setHitEnter(true);
            } else {
              setId(materialId);
              setHitEnter(true);
            }
          }
        }
      } else if (CONFIG.inspectMaterialId(inputState.searchbox) || CONFIG.inspectKpId(inputState.searchbox)) {
        // check if the search string is a material id
        // OR check if the search string is a kp guid
        setId(inputState.searchbox);
        setHitEnter(true);
      } else {
        //regular search text
        setSearchTerm(inputState.searchbox);
        setHitEnter(true);
      }
    } else {
      // update the state if search string is empty
      setValidation({ ...validation, searchbox: false });
    }
  };

  /**
   * @desc handle the input event
   * @memberof AddKnowledgeItems
   * @param {Event} event - input current event
   * @return no return
   */
  const handleChange = (event) => {
    // update validation state
    setValidation({ ...CONFIG.defaultValidation });
    // update input state
    setInputState({
      ...inputState,
      [event.target.name]: event.target.value,
    });
    if (event.type === "change" && event.target.value.length === 0) {
      setSearchRecentItems(true);
      setHitEnter(false);
    }
  };

  /**
   * @desc sort the array
   * @memberof AddKnowledgeItems
   * @param {Object} {oldIndex, newIndex}
   * @param {String} errorMsg
   * @return no return
   */
  const handleSort = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      // sort the array
      setItemsState(arrayMove(itemsState, oldIndex, newIndex));
      setkoidList(arrayMove(koidList, oldIndex, newIndex));

      //update the parent
      getItems(
        arrayMove(koidList, oldIndex, newIndex),
        true,
        arrayMove(itemsState, oldIndex, newIndex),
        "reorder"
      );
    };
  }

  /**
   * @desc handle the button delete
   * @memberof AddKnowledgeItems
   * @param {Number} ind - current index
   * @return no return
   */
  const handleDelete = (ind, isModal = false) => {
    if (isModal) {
      // mutate input state and delete
      itemsState.splice(ind, 1);
      // update the state
      setItemsState([...itemsState]);

      koidList.splice(ind, 1);

      setkoidList([...koidList]);

      getItems([...koidList], true, [], "delete");
      // modal set to false
      setIsModalVisible(false);
      // index to be null
      setKnowledgeIndex(null);
    } else {
      // modal set to true
      setIsModalVisible(true);
      // index to be ind
      setKnowledgeIndex(ind);
    }
  };

  /**
   * @desc handle the button preview
   * @memberof AddKnowledgeItems
   * @param {Number} ind - current index
   * @return no return
   */
  const handlePreview = (ind) => {
    const kpId = itemsState[ind]?.id;
    handleDocvizPreview(kpId, itemsState[ind]?.legacyMaterialId, itemsState[ind]?.legacyAttachmentId, itemsState[ind]?.paRecommended, itemsState[ind]?.attachment?.documentPath);
  };

  /**
   * @desc Opens the help modal/pop up window with carousel component
   */
  const openModalwithCarousel = () => {
    setHelpmodalOpen(true);

    //Auto close the pop up after configrured timelimit(30seconds - now)
    const {
      KNOWLEDGE_BUNDLE_LIMITS: {
        HELPMODAL_AUTOCLOSE_TIME_LIMIT: timelimit_helpmodal,
      },
    } = GLOBAL_CONFIG;
    setTimeout(() => {
      closeHelpModal();
    }, timelimit_helpmodal * 1000);

    // Send Analytics data to Adobe
    sendAnalyticsForHelpModal();
  };

  /**
   * @desc closes the help modal on esc, or click of cancel button
   */
  const closeHelpModal = () => setHelpmodalOpen(false);

  /**
   * @description it handles the search result(material found) on click event, it passes the local search term further
   * @param none
   */

  const handleSelection = (selectedObj) => {

    handleOnSelection(selectedObj);
    resetLocalState();
    setHitEnter(true);
    // update the input state
    setInputState({ ...CONFIG.defaultValues });
  };

  const handleClose = () => setIsModalVisible(false);

  /**
   * @desc submit the form
   * @memberof AddKnowledgeItems
   * @param {Object} {event}
   * @return no return
   */
  const handleKeyPress = (e) => {
    // submit the form
    if (e.keyCode === 13) {
      handleSubmit(e);
    }
    if (e.keyCode === 27) {
      resetLocalState();
    }
  };

  // when textbox is clicked, get recent items
  const onClickHandler = () => {
    const { findMaterialIdFromUrl } = CONFIG;
    const { searchbox } = inputState;
    const materialId = findMaterialIdFromUrl(searchbox);
    if (!materialId.length && !searchRecentItems) {
      setSearchRecentItems(true);
      setHitEnter(false);
    }
  };

  // when user clicks outside of the recent items list
  const onOutsideClickHandler = () => {
    resetLocalState();
  };

  const getListingComponent = () => {
    if (renderItemsList) {
      const actions = {
        handlePreview,
        handleDelete,
      };
      return renderItemsList({ data: itemsState, actions });
    }
    else {
      return (<KnowledgeItem
        icon1={{
          callBack: handlePreview,
          icon: "preview",
        }}
        icon2={showDelete ? {
          callBack: handleDelete,
          icon: "delete",
        } : undefined}
      />);
    }
  }

  // Return Knowledge Objects/items with list
  return (
    <Container fluid className={`knowledge-objects layout__component ${className}`}>
      {!!minimumItemsMessage && <div className="assets-message">{minimumItemsMessage}</div>}
      <Row>
        {(
          <Col>
            {showSearchField && <div className="knowledge-objects__searchWrap">
              <OutsideClickHandler
                onOutsideClick={onOutsideClickHandler}
              >
                <Form className="add-ki">
                  <Form.Group
                    inline
                    data-testid="knowledge-objects"
                    className="add-ki__group"
                  >
                    {defaultState.length < limit ? (
                      <Form.Field className="add-ki__field">
                        <div className="add-ki__wrapper">
                          <label className="add-ki__label">
                            <div className="add-ki__labelwrap">
                              {searchLabel}
                              {showHelpIcon && <div className="radiations">
                                <div onClick={openModalwithCarousel}>
                                  <HelpIcon />
                                </div>
                              </div>}
                            </div>
                          </label>
                        </div>
                        <Form.Input
                          className="add-ki__input"
                          data-testid="input-urlorid"
                          type={CONFIG.inputType}
                          value={inputState.searchbox}
                          placeholder={searchPlaceHolder}
                          onChange={handleChange}
                          autoComplete="off"
                          onPaste={handleChange}
                          onKeyDown={handleKeyPress}
                          onClick={onClickHandler}
                          name="searchbox"
                          maxLength={maxLength}
                          icon={
                            <span onClick={handleSubmit}>
                              <Image
                                src={SearchIcon}
                                alt={LABELS.ALT_TEXTS.ENTER_ICON}
                                className="searchicon"
                              />
                            </span>
                          }
                        />
                        {ErrorMsg(validation.searchbox, nothingSearched || CONFIG.errorMsg.searchbox)}
                        {ErrorMsg(validation.midCustom, CONFIG.errorMsg.url[0])}
                        {ErrorMsg(validation.urlCustom, CONFIG.errorMsg.url[1])}
                      </Form.Field>
                    ) : (<p>{CONFIG.errorMsg.default}</p>)}
                    {!!(localSearchItem.length) && (
                      <Form.Field
                        className="add-ki__searchedField add-ki__field">
                        {
                          !hitEnter &&
                          <div className="add-ki__recentContributions">
                            {recentTitle}
                          </div>
                        }
                        {localSearchItem.map(eachterm => {
                          let itemPresentinList = isKPSavedinBundle(eachterm.id, koidList);
                          return (
                            <Form.Field
                              onClick={() => !itemPresentinList ? handleSelection(eachterm) : null}
                              key={eachterm.id}
                              className={`${itemPresentinList ? "disabled" : ""}`}
                            >
                              <Attachment key={eachterm.id} item={eachterm} />
                            </Form.Field>
                          )
                        }
                        )}
                      </Form.Field>
                    )}

                    {!!noresultsFound && (
                      <Form.Field className="add-ki__searchedField add-ki__field">
                        No Record Found
                      </Form.Field>
                    )}
                  </Form.Group>
                </Form>
              </OutsideClickHandler>
              {rightChildren}
            </div>}
            {showSearchField && !!!itemsState.length && (
              <div className="knowledge-objects--empty">
                <Image
                  src={searchKPImage}
                  alt="Search your Knowledge Pages"
                  className="add-ki__image--empty-list"
                />
              </div>
            )}
            {itemsState.length !== 0 && (
              <CustomListing
                items={itemsState}
                onSort={handleSort}
                dragHandle
                countOfItems={itemsState.length}
                renderListing={getListingComponent}
                isDraggable={isDraggable}
                getKPFile={getKPFile}
              />
            )}
          </Col>
        )}

      </Row>
      <DeleteModal
        heading={CONFIG.popConfirm.heading}
        button1={{
          text: CONFIG.popConfirm.buttonText.cancel,
          clickHandler: handleClose,
        }}
        button2={{
          text: CONFIG.popConfirm.buttonText.delete,
          clickHandler: () => handleDelete(knowledgeIndex, true),
        }}
        isOpen={isModalVisible}
        onRequestClose={handleClose}
      />
      <HelpModal isOpen={isHelpmodalOpen} onRequestClose={closeHelpModal} />
    </Container>
  );
};

// PropTypes
AddKnowledgeItems.propTypes = {
  defaultState: PropTypes.arrayOf(PropTypes.shape(CONFIG.defaultValues)),
  limit: PropTypes.number,
  validate: PropTypes.bool,
  getItems: PropTypes.func,
  maxLength: PropTypes.number,
  handleOnSelection: PropTypes.func,
  handleOnSearch: PropTypes.func,
  handleOnRecentContributions: PropTypes.func,
  minimumItemsMessage: PropTypes.string,
  handleDocvizPreview: PropTypes.func,
  searchLabel: PropTypes.string,
  searchPlaceHolder: PropTypes.string,
  recentTitle: PropTypes.string,
  showHelpIcon: PropTypes.bool,
  showDelete: PropTypes.bool,
  className: PropTypes.string,
  renderItemsList: PropTypes.func,
  isDraggable: PropTypes.bool,
  rightChildren: PropTypes.element,
  nothingSearched: PropTypes.string,
  showSearchField: PropTypes.bool,
};

AddKnowledgeItems.defaultProps = {
  defaultState: [],
  defaultStateKoidList: [],
  limit: 40,
  validate: true,
  getItems: () => { },
  maxLength: 100,
  handleOnSelection: () => { },
  handleOnSearch: () => { },
  handleOnRecentContributions: () => { },
  sendAnalyticsForHelpModal: () => { },
  minimumItemsMessage: "",
  handleDocvizPreview: () => { },
  searchLabel: title,
  searchPlaceHolder: searchbox,
  recentTitle: recentContributionsTitle,
  showHelpIcon: true,
  className: "",
  showDelete: true,
  isDraggable: true,
  rightChildren: null,
  nothingSearched: "",
  showSearchField: true,
};