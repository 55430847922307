
const CONFIG = {
    footerWorkflow: [
        {
            order: 1,
            id: "btn-preview",
            text: "Generate Preview",
            classname: "previewBtn",
            disabled: false,
            visible: true,
            isClick: true,
        },
        {
            type: "button",
            order: 2,
            id: "btn-download",
            text: "Download",
            classname: "downloadBtn",
            disabled: true,
            visible: true,
            isClick: true,
        }
    ],
    proposalFooterWorkflow: [
        {
            order: 1,
            id: "btn-preview",
            text: "Generate Preview",
            classname: "previewBtn",
            disabled: false,
            visible: true,
            isClick: true,
        },
        {
            type: "button",
            order: 2,
            id: "btn-upload",
            text: "Upload to Profile",
            classname: "uploadBtn",
            disabled: true,
            visible: true,
            isClick: true,
            title: "You are not eligible to edit profile of selected person",
        },
        {
            type: "button",
            order: 3,
            id: "btn-download",
            text: "Download",
            classname: "downloadBtn",
            disabled: true,
            visible: true,
            isClick: true,
        }
    ]
};

export default CONFIG;
