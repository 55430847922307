import { put, call, takeLatest, delay } from 'redux-saga/effects';
import moment from 'moment';
import CONSTANTS from "globalConstants";
// Constant
import { FCG_NEW } from "redux/constants";

// Config
import CONFIG from "config";
// Axios Util
import { doAxiosRequest } from "config-axios";
import { newRequestAPIPending, newRequestAPISuccess, newRequestAPIFailure, pollingAPIStart, pollingAPISuccess, pollingAPIFailure, pollingImagesAPISuccess, pollingImagesAPIStart, pollingImagesAPIFailure } from "./faceCardGeneratorv1.actions";
import { downloadFileFromBlobResoponse, triggerUsabillaSurvey } from 'utils/helpers/helpers';

export function* handleNewRequestAPI(action) {
  try {
    yield put(newRequestAPIPending());
    const { XA, API_URL: { FACE_CARD_GENERATOR_ACKNOWLEDGE } } = CONFIG;
    const { payload } = action;
    const axiosConfig = {
      method: 'POST',
      endpoint: FACE_CARD_GENERATOR_ACKNOWLEDGE,
      params: payload,
      headers: {
        'x-api-key': XA
      }
    };
    const response = yield call(doAxiosRequest, axiosConfig);
    if (response?.error.code === "200" && response?.requestId) {
      yield put(newRequestAPISuccess(response?.requestId));
      if (payload.templateType === "image") {
        yield put(pollingImagesAPIStart(response?.requestId));
      } else {
        yield put(pollingAPIStart(response?.requestId));
      }
    } else {
      yield put(newRequestAPIFailure(response?.error.message));
    }
  }
  catch (error) {
    yield put(newRequestAPIFailure(error.toString()));
  }
}


const getCurrentDateTime = () => {
  let date = new Date();
  let value = date.getTime();
  const formattedDate = moment(value).format('DDMMYYYY_HHmmss');
  return formattedDate;
}
const triggerUsabilla = () => {
  const {
    USABILLA_SURVEY_KEYS: {
      FACECARD_DOWNLOAD
    }
  } = CONSTANTS;
  triggerUsabillaSurvey(FACECARD_DOWNLOAD);
}

export function* downloadFileFromCloudFront(action) {
  try {
    let { payload: { url } } = action;
    const axiosConfig = {
      method: 'GET',
      responsetype: 'blob',
      endpoint: url,
      headers: {
        'x-api-key': process.env.REACT_APP_CLIPPING_API_KEY
      }
    };
    const response = yield call(doAxiosRequest, axiosConfig);
    const formattedDate = getCurrentDateTime();
    downloadFileFromBlobResoponse(response, "Face Card Slides_" + formattedDate + ".pptx");
    triggerUsabilla();

  } catch (error) {
    console.log('Error in downloadFileFromCloudFront', error);
  }
}

export function* downloadFileFromCloudFrontImages(action) {
  try {
    const { payload: { url } } = action;
    const axiosConfig = {
      method: 'GET',
      responsetype: 'blob',
      endpoint: url,
      headers: {
        'x-api-key': process.env.REACT_APP_CLIPPING_API_KEY,
      },
    };
    const response = yield call(doAxiosRequest, axiosConfig);
    const formattedDate = getCurrentDateTime();
    downloadFileFromBlobResoponse(response, "facecard_" + formattedDate + ".zip");
    triggerUsabilla();
  } catch (error) {
    console.error("Error in downloadFileFromCloudFrontImages", error);
  }
}
export function* handlePollingAPI(action) {
  try {
    const { API_URL: { FACE_CARD_GENERATOR_POLLING } } = CONFIG;
    const maxAttempts = CONFIG.MAX_FACECARD_GEN_POLL;
    const delayDuration = CONFIG.MAX_FACECARD_GEN_DELAY;

    for (let attempts = 0; attempts < maxAttempts; attempts++) {
      const axiosConfig = {
        method: 'GET',
        endpoint: FACE_CARD_GENERATOR_POLLING + '?requestId=' + action.payload.requestId,
        headers: {
          'x-api-key': CONFIG.XA
        }
      };
      const response = yield call(doAxiosRequest, axiosConfig);
      const status = response?.status?.toLowerCase();
      if (response?.error.code === "200" && status !== 'error') {
        if (status === 'completed') {
          yield put(pollingAPISuccess(response));
          return;
        } else {
          yield delay(delayDuration);  // Wait for 1 second before next poll
        }
      }
      else {
        yield put(pollingAPIFailure(response?.error.message));
        return;
      }
    }

    // If max attempts are completed, dispatch failure message
    yield put(pollingAPIFailure(CONFIG.POLLING_ERROR_MESSAGE));


  } catch (error) {
    yield put(pollingAPIFailure(error.toString()));
  }
}
export function* handlePollingImagesAPI(action) {
  try {
    const { requestId } = action.payload
    const pollingUrl = CONFIG.API_URL.DOWNLOAD_ONLY_IMAGES_POLLING(action.payload.requestId);
    const maxAttempts = CONFIG.MAX_FACECARD_GEN_POLL;
    const delayDuration = CONFIG.MAX_FACECARD_GEN_DELAY;

    for (let attempts = 0; attempts < maxAttempts; attempts++) {
      const axiosConfig = {
        method: 'GET',
        endpoint: pollingUrl,
        headers: {
          'x-api-key': CONFIG.XA,
        },
      };
      const response = yield call(doAxiosRequest, axiosConfig);
      const status = response?.status?.toLowerCase();

      if (response?.error.code === "200" && status !== 'error') {
        if (status === 'completed') {
          yield put(pollingImagesAPISuccess(response));
          const fileUrlsImage = response?.slideUrl
          if (fileUrlsImage) {
            yield put({
              type: FCG_NEW.DOWNLOAD_FILE_FROM_CLOUDFRONT_IMAGES,
              payload: { url: fileUrlsImage, requestId }

            })
          }
          return;
        } else {
          yield delay(delayDuration);
        }
      } else {
        yield put(pollingImagesAPIFailure(response?.error.message));
        return;
      }
    }

    // If max attempts are completed, dispatch failure message
    yield put(pollingImagesAPIFailure(CONFIG.POLLING_ERROR_MESSAGE));

  } catch (error) {
    yield put(pollingImagesAPIFailure(error.toString()));
  }
}

export default function* faceCardGeneratorSagaV1() {
  yield takeLatest(FCG_NEW.GET_FC_SLIDE_DATA, handleNewRequestAPI);
  yield takeLatest(FCG_NEW.GET_FC_DOWNLOAD_IMAGE_ONLY, handleNewRequestAPI);
  yield takeLatest(FCG_NEW.DOWNLOAD_FILE_FROM_CLOUDFRONT, downloadFileFromCloudFront);
  yield takeLatest(FCG_NEW.FC_POLLING_API_PENDING, handlePollingAPI);
  yield takeLatest(FCG_NEW.FC_POLLING_IMAGES_PENDING, handlePollingImagesAPI);
  yield takeLatest(FCG_NEW.DOWNLOAD_FILE_FROM_CLOUDFRONT_IMAGES, downloadFileFromCloudFrontImages);
}
