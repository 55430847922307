import {
  CONTRIBUTE_KNOWLEDGE
} from 'redux/constants';
import { doAxiosRequest } from 'config-axios';
import CONFIG from 'config';
import { put, takeLatest, call } from '@redux-saga/core/effects';
import { contributeKnowledgePending, contributeKnowledgeSuccess, contributeKnowledgeFailure } from './contributeknowledge.actions';

export function* getKnowledgeType() {

  try {
    yield put(contributeKnowledgePending());
    const knowledgeTypeResponse = yield call(doAxiosRequest, {
      method: 'GET',
      endpoint: CONFIG.API_URL.GET_CONTRIBUTE_KNOWLEDGE_TYPE,
      headers: {
        'x-api-key': CONFIG.XA
      }
    });

    yield put(contributeKnowledgeSuccess(knowledgeTypeResponse));
  }
  catch (error) {
    yield put(contributeKnowledgeFailure(error));
  }
}

export default function* contributeknowledgeSaga() {
  try {
    yield takeLatest(CONTRIBUTE_KNOWLEDGE.GET_CONTRIBUTE_KNOWLEDGE, getKnowledgeType);
  } catch (error) {
    yield put(contributeKnowledgeFailure(error, contributeknowledgeSaga))
  }
}