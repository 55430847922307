// React
import React from "react";
// semantic
import { Form } from "semantic-ui-react";

// shared component
import RichEditor from "components/shared/RichEditor/";
import Counter from "components/shared/Counter";
import { PeopleSelector } from "components/PeopleSelector";
import { ImageSelectorWithModal } from "components/ImageSelectorWithModal";
import LABELS from "labels";
import {
  onPageLoadAnalytics,
  // kbCreatorAnalytics,
  additionalContributorsAnalytics
} from "../kb_analytics";
import { stripHtml, removeSpaces, replaceInLabel } from "utils/helpers/helpers";
// config
import CONFIG from "../KnowledgeBundles.config";
import GLOBAL_CONFIG from "config";
import warningIcon from "assets/images/warning/warning.svg";
import Image from "components/shared/Image";
import GLOBAL_CONSTANTS from 'globalConstants';
import {
  PAGES,
  PRIMARY_CATEGORY
} from "utils/analytics/analytics_constants";

// scss
import "./KBStep1.scss";
class KBStep1 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      localtitle: "",
      localtitleLength: 0,
      localDesc: "",
      queryParamTopicPage: []
    }
  }

  componentDidMount() {
    const { apidata: { title, description }, kbId, isKnowledgeBundle } = this.props;
    const { localtitle, localDesc } = this.state;
    if (localtitle !== title) {
      this.setState({
        localtitle: title,
        localtitleLength: title.length
      });
    }
    if (localDesc !== description?.trim()) {
      this.setState({
        localDesc: stripHtml(description?.trim())
      })
    }
    onPageLoadAnalytics(kbId, isKnowledgeBundle ? PAGES.KB_CREATION : PAGES.TC_CREATION, isKnowledgeBundle ? PRIMARY_CATEGORY.KB : PRIMARY_CATEGORY.TC);

  }
  componentDidUpdate(prevProps) {
    const { apidata: { title: prevTitle, description: prevDesc } } = prevProps;
    const { apidata: { title, description } } = this.props;
    if (prevTitle !== title) {
      this.setState({
        localtitle: title ?? '',
        localtitleLength: title?.length ?? 0
      });
    }
    if (prevDesc !== description) {
      this.setState({
        localDesc: stripHtml(description?.trim()),
      });
    }
  }

  handleOnDescFieldBlur = (value) => {
    const { handleChange } = this.props;
    this.setState({
      localDesc: stripHtml(value)
    })
    handleChange(value, "description");
  }

  handleFormInputChange = (event) => {
    this.setState({
      localtitle: event.target.value,
      localtitleLength: event.target.value.length
    });
  }

  titleBlurHandler = (data, title) => {
    const { handleChange } = this.props;
    const trimmedName = removeSpaces(data);
    this.setState({
      localtitle: trimmedName,
      localtitleLength: trimmedName.length
    });
    // update the state - with data
    handleChange(trimmedName, title)
  };

  errorMsg = (error, errorMsg) =>
    !error ? (
      <div className="knowledgepage__step1__wrap__error">
        <Image
          src={warningIcon}
          alt="icon warning"
          className="knowledgepage__step1__wrap__error--icon"
        />
        <span className="knowledgepage__step1__wrap__errormsg">{errorMsg}</span>
      </div>
    ) : null;

  render() {
    const { isKnowledgeBundle, topicId, images, errors, handleChange, apidata } = this.props;
    const { localtitleLength, localtitle, localDesc } = this.state;
    const {
      OPTIONAL_TEXT,
      CREATION: {
        TITLE_MIN_LENGTH_MESSAGE
      },
      KB_FORM_PLACEHOLDERS: { KB_TITLE_PLACEHOLDER_TEXT },
      METADATA: {
        ADDITION_CONTRIBUTORS,
        ORIGINAL_REQUESTOR,
        COVER_IMAGE,
        // CREATED_BY,
        DESCRIPTION,
        BUNDLE_TITLE,
        PEOPLE_SELECTOR_PLACEHOLDER,
        TC_COVER_TITLE_PLACEHOLDER,
        TC_COVER_DESC_PLACEHOLDER,
        TC_DATE_PLACEHOLDER_TEXT,
        TC_FILES_PLACEHOLDER_TEXT,
        MANDATORY_FORM_FIELD_TEXT
      },
    } = LABELS;
    const {
      MANDATORY_FIELDS: {
        COLLECTION_TITLE,
        COLLECTION_TITLE_MIN_LENGTH,
        ORIGINAL_REQUESTOR_FIELD
      }
    } = CONFIG;
    const { LABEL_VALUE } = GLOBAL_CONSTANTS;
    const { KNOWLEDGE_BUNDLE_LIMITS: { KBPAGE_MIN_TITLE_CHARLIMIT } } = GLOBAL_CONFIG;
    const titlePlaceholderText = replaceInLabel(KB_TITLE_PLACEHOLDER_TEXT, LABEL_VALUE, KBPAGE_MIN_TITLE_CHARLIMIT);
    const titleErrorMsz = replaceInLabel(TITLE_MIN_LENGTH_MESSAGE, LABEL_VALUE, KBPAGE_MIN_TITLE_CHARLIMIT);
    const originalRequestor = apidata?.originalRequestor?.toString() ? [apidata.originalRequestor] : []
    return (
      <>
        <div className="KBStep1">
          <Form widths="equal">
            <div className="KBStep1__inner layout__fix--inner">
              <Form.Group>
                <Form.Field>
                  <label>
                    {BUNDLE_TITLE}
                    <span>{MANDATORY_FORM_FIELD_TEXT}</span>
                  </label>
                  <Form.Input
                    type="text"
                    placeholder={titlePlaceholderText}
                    value={localtitle}
                    maxLength={
                      GLOBAL_CONFIG.KNOWLEDGE_BUNDLE_LIMITS
                        .BUNDLE_TITLE_CHARLIMIT
                    }
                    onBlur={(e) => this.titleBlurHandler(e.target.value, "title")}
                    onChange={(e) => this.handleFormInputChange(e)}
                    className={
                      errors.includes(COLLECTION_TITLE) ||
                        errors.includes(COLLECTION_TITLE_MIN_LENGTH) ?
                        "highlight titleField" : "titleField"
                    }
                    icon={
                      <Counter
                        inputLength={localtitleLength}
                        maxLength={
                          GLOBAL_CONFIG.KNOWLEDGE_BUNDLE_LIMITS
                            .BUNDLE_TITLE_CHARLIMIT
                        }
                        style={CONFIG.style}
                      />
                    }
                  />
                  {this.errorMsg(!(errors.includes(CONFIG.MANDATORY_FIELDS.COLLECTION_TITLE_MIN_LENGTH)), titleErrorMsz)}
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field>
                  <label>{DESCRIPTION}</label>
                  <div
                    className={
                      errors.includes(CONFIG.MANDATORY_FIELDS.DESCRIPTION)
                        ? "richEditor customhighlight"
                        : "richEditor"
                    }
                  >
                    <RichEditor
                      updatedKey={apidata?.kbId}
                      initialState={apidata?.description?.trim()}
                      handleOnblur={(e) => this.handleOnDescFieldBlur(e)}
                      charLimit={GLOBAL_CONFIG.KNOWLEDGE_BUNDLE_LIMITS.BUNDLE_RICHEDITOR_CHARLIMIT}
                    />
                  </div>
                </Form.Field>
              </Form.Group>
              <Form.Group className="displaynone">
                <Form.Field>
                  <PeopleSelector
                    label={ORIGINAL_REQUESTOR}
                    placeholder={PEOPLE_SELECTOR_PLACEHOLDER}
                    name="originalRequestor"
                    maxLimit={1}
                    className={
                      errors.includes(ORIGINAL_REQUESTOR_FIELD)
                        ? "peopleselector__error"
                        : ""
                    }
                    preFillData={[originalRequestor]}
                    onChange={(data) => {
                      handleChange(data.toString(), "originalRequestor");
                    }}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field>
                  <PeopleSelector
                    maxLimit={10}
                    label={ADDITION_CONTRIBUTORS}
                    optionalTxt={OPTIONAL_TEXT}
                    placeholder={PEOPLE_SELECTOR_PLACEHOLDER}
                    name="contributors"
                    topicId={apidata?.topicPages[0]?.topicId ?? topicId}
                    kbId={apidata?.kbId}
                    preFillData={[apidata?.contributors]}
                    getSuggestionValueCallback={additionalContributorsAnalytics}
                    onChange={(data) => {
                      handleChange(data, "contributors");
                    }}
                    suggestions={{
                      useDragDrop: true
                    }}
                  />
                </Form.Field>
              </Form.Group>
              {!isKnowledgeBundle && (
                <Form.Group>
                  <Form.Field>
                    <label>{COVER_IMAGE}</label>
                    {images && (
                      <>
                        <ImageSelectorWithModal
                          handleChange={handleChange}
                          preFilledImage={apidata?.coverImage.Name}
                          images={images}
                          className={
                            errors.includes(CONFIG.MANDATORY_FIELDS.COVER_IMAGE)
                              ? " customhighlight"
                              : ""
                          }
                          tcTitle={localtitle ? localtitle : TC_COVER_TITLE_PLACEHOLDER}
                          tcDesc={localDesc ? localDesc : TC_COVER_DESC_PLACEHOLDER}
                          tcDate={TC_DATE_PLACEHOLDER_TEXT}
                          tcFiles={TC_FILES_PLACEHOLDER_TEXT}
                        />

                      </>
                    )}
                  </Form.Field>
                </Form.Group>
              )}
            </div>
          </Form>
        </div>
      </>
    );
  }
};
export default KBStep1;
