import { CONTRIBUTE_KNOWLEDGE } from "redux/constants";

export const fetchKnowledgeType = () => ({
  type: CONTRIBUTE_KNOWLEDGE.GET_CONTRIBUTE_KNOWLEDGE,
});

export const contributeKnowledgePending = () => ({
  type: CONTRIBUTE_KNOWLEDGE.GET_CONTRIBUTE_KNOWLEDGE_PENDING,
});

export const contributeKnowledgeSuccess = (response) => ({
  type: CONTRIBUTE_KNOWLEDGE.GET_CONTRIBUTE_KNOWLEDGE_SUCCESS,
  payload: response,
});

export const contributeKnowledgeFailure = (error) => ({
  type: CONTRIBUTE_KNOWLEDGE.GET_CONTRIBUTE_KNOWLEDGE_FAILURE,
  payload: error,
});

