import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Image from "components/shared/Image";
import Modal from "components/shared/Modal";
import { TabComponent } from "components/shared/TabComponent";
import { AutosuggestSelector } from "components/shared/AutosuggestSelector";
import crossIcon from "assets/images/cross-icon.svg";
import crossIconBlue from "assets/images/cross-icon-blue.svg";
import { Button } from "components/shared/Button";
import { Tooltip } from "components/shared/Tooltip";
import rightIcon from "assets/images/right-icon.svg";
import plusicon from "assets/images/plusicon.svg";
import { copyObject, removeCurlyBracketFromId, getTagsInfoIconDetailsifAny } from "utils/helpers/helpers";
import bigCrossIcon from "assets/images/big-cross-icon.svg";
import { v4 as uuidv4 } from 'uuid';
// Shared component
import MailButton from "components/shared/MailButton";
// scss
import "./TaxonomyModal.scss";
import CONSTANTS from "globalConstants";

import { TAXONOMY } from "redux/constants";
import { resetAllParentTags } from "redux/actions/taxonomy/Taxonomy.actions";
import { selectors as taxonomySelector } from "redux/reducers/taxonomy/taxonomy_reducer";
import { ImageWithToolip } from "components/shared/ImageWithToolip";
import PolyHIcon from "assets/images/polyHierarchi/polyHierarchiIcon.svg";
import PolyHPostFixIcon from "assets/images/polyHierarchi/polyHierarchiPostFixICon.svg";
import CONFIG from "config";
import LABELS from "labels";


const { TAB1, TAB2, TAB3 } = CONSTANTS;
const tabArray = [TAB1, TAB2, TAB3];
class TaxonomyModalContainer extends React.PureComponent {
  state = {
    activeColumns: [],
    isMouseLeave: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      searchData,
      getSLparentTags,
      clearAllParentTags,
      taxonomyTypeSearchName,
      isShowParentTagsTooltip,
      isOpen
    } = this.props;
    if (isOpen && isShowParentTagsTooltip && prevProps.searchData !== searchData) {
      clearAllParentTags();
      getSLparentTags(CONFIG.TBDB_IDS[taxonomyTypeSearchName[0].searchKey], searchData.map(item => item.id));
    }
  }

  setChildState = (data, colIndex, childIndex) => {
    const { activeColumns } = this.state;
    let arr = [...activeColumns];
    arr[colIndex] = { data, currentIndex: childIndex };
    this.setState({
      activeColumns: arr.slice(0, colIndex + 1),
    });
  };
  mouseEnterHandler = (data, colIndex, index) => {
    this.setState({
      isMouseLeave: false,
    });
    this.setChildState(data, colIndex, index);
  };

  mouseLeaveHandler = () => {
    this.setState(
      {
        isMouseLeave: true,
      },
      () => {
        setTimeout(() => {
          const { isMouseLeave } = this.state;
          if (isMouseLeave) {
            this.setChildState([], 0, 0);
          }
        }, 1500);
      }
    );
  };
  createList = (tab, data, colIndex) => {
    const { activeColumns } = this.state;
    const { clickHandler, selTaxonomy, fnToCheckIDIsPolyHierarchi, hideTagsIds } = this.props;
    const { TAXONOMY: { TOOLTIPS: { POLY_HIERARCHI_TAXONOMY_SUGGESTION_MSG } } } = LABELS;
    return data && data.length ? (
      <ul className="tx__column" key={colIndex}>
        {data.map((ele, index) => {
          const { Name, Id, Children } = ele;
          let liClass = "";
          if (
            activeColumns.length &&
            activeColumns[colIndex] &&
            activeColumns[colIndex].currentIndex === index
          ) {
            liClass = liClass + " hovered";
          }

          if (Children && Children.length) {
            liClass = liClass + " havechild";
          }

          if (selTaxonomy[tab] && selTaxonomy[tab][Id]) {
            liClass = liClass + " selected";
          }

          if (hideTagsIds?.includes(Id)) {
            liClass = liClass + " hideTag";
          }

          const isPolyHierarchi = fnToCheckIDIsPolyHierarchi(Id);
          return (
            <li
              key={Id}
              className={`${liClass} tx__column-listClass`}
              onMouseEnter={() =>
                this.mouseEnterHandler(Children, colIndex, index)
              }
              onClick={() => clickHandler(tab, Id, Name, colIndex, index)}
            >
              {isPolyHierarchi && <ImageWithToolip id={`${Id}-tx-column`} imgIcon={PolyHPostFixIcon} toolTipClass="polyHIconMargin" imgClassName="tx__column-polyHImg polyHRotateImage" toolTipMsg={POLY_HIERARCHI_TAXONOMY_SUGGESTION_MSG} />}
              <div className={`${liClass} tx__column-suggestionName`}>
                <span>{Name}</span>
              </div>
            </li>
          );
        })}
      </ul>
    ) : null;
  };
  getComponent = (data, tab) => {
    const { activeColumns } = this.state;
    return (
      <div className="tx__wrap" onMouseLeave={this.mouseLeaveHandler} key={tab}>
        {this.createList(tab, data, 0)}
        {activeColumns.length
          ? activeColumns.map(({ data }, index) => {
            return (
              <div className="tx__columnwrap" key={index}>
                {this.createList(tab, data, index + 1)}
              </div>
            );
          })
          : null}
      </div>
    );
  };

  getAllSelectedkeys = () => {
    const { selTaxonomy } = this.props;
    const { TAXONOMY_IDS: { IGNOREGUIDS_BST } } = CONFIG;
    const keysList = [];
    Object.keys(selTaxonomy).forEach((tab) => {
      Object.keys(selTaxonomy[tab]).forEach((key) => {
        keysList.push(key);
      });
    });

    return [...keysList, ...IGNOREGUIDS_BST];
  }

  onTabChangeCallback = () => {
    this.setChildState([], 0, 0);
  }

  getSearchData = (value) => {
    const { taxonomyTypeSearchName, getTypeSearchData } = this.props;
    getTypeSearchData(value, taxonomyTypeSearchName);
  }

  mergeParent = (tagsTemp, parentTagsTemp) => {
    let tags = copyObject(tagsTemp);
    if (parentTagsTemp && parentTagsTemp.length) {
      let parentTags = copyObject(parentTagsTemp);

      const mergedTags = parentTags.map((item, index) => {
        item.pop();
        return [...item, tags[index]];
      });
      return mergedTags;
    }
  }

  render() {
    const {
      isOpen,
      onRequestClose,
      selTaxonomy,
      deleteSelected,
      modalHeader,
      searchDataLoading,
      isShowNoResultInTypeAhead,
      submitHandler,
      taxonomyName,
      getTaxonomyData,
      typeSearchplaceholder,
      characterCount,
      responseTypeSearchKeyName,
      sendFilterData,
      showModalCloseOption,
      hideTagsIds,
      isShowParentTagsTooltip,
      taxonomyTypeSearchName,
      typeAheadHandler,
      searchData,
      getParentTagsData,
      activeIndex,
      fnToCheckIDIsPolyHierarchi,
      informationIconsConfig,
      suggestions: {
        tags: suggestionTags,
        heading: suggestionTagsHeading,
        maxLimitLabel,
        suggestionsKeyName,
        getIsExistingModal,
        addTagsFromSuggestions,
        checkLimitAndDisable
      }
    } = this.props;
    const { TAXONOMY: { NORESULT_TYPEAHEAD_HEADING, TOOLTIPS: { POLY_HIERARCHI_TAXONOMY_SUGGESTION_MSG } } } = LABELS;
    const modifiedSuggestionTags = !!suggestionTags?.length ? checkLimitAndDisable(suggestionTags, true) : [];
    const parentTagsData = isShowParentTagsTooltip && searchData.length ? this.mergeParent(searchData, getParentTagsData(CONFIG.TBDB_IDS[taxonomyTypeSearchName[0].searchKey])) : [];
    const isBSTTaxonomy = taxonomyName[0]?.key.toLowerCase() !== "subject";
    return (
      <Modal
        isOpen={isOpen}
        className={`tx__modal ${isBSTTaxonomy ? "" : "tx__modal--full"}`}
        onRequestClose={onRequestClose}
      >
        <div className="tx__modalheader">
          <div className="tx__modalheader--withsearch">
            <h4 className="tx__heading">{modalHeader}</h4>
            {taxonomyTypeSearchName ? <AutosuggestSelector
              placeholder={typeSearchplaceholder}
              characterCount={characterCount}
              responseTypeSearchKeyName={responseTypeSearchKeyName}
              taxonomyTypeSearchName={taxonomyTypeSearchName}
              typeAheadHandler={typeAheadHandler}
              hideList={[...this.getAllSelectedkeys(), ...hideTagsIds]}
              searchData={searchData}
              parentTagsData={parentTagsData}
              getSearchData={(value) => this.getSearchData(value)}
              sendSelectedFilterData={(data) => {
                sendFilterData(data);
              }}
              fnToCheckIDIsPolyHierarchi={fnToCheckIDIsPolyHierarchi}
              noResults={{
                isShow: isShowNoResultInTypeAhead && !searchDataLoading,
                heading: NORESULT_TYPEAHEAD_HEADING,
                emailConfig: CONFIG.MAIL_TO(
                  isBSTTaxonomy
                )
              }}
            /> : ""}
          </div>
          {!!showModalCloseOption && (<span onClick={onRequestClose} className="tx__modalcross">
            <Image src={bigCrossIcon} alt="cross" />
          </span>
          )}
        </div>
        <ul className="tx__topbar">
          {tabArray.map((tab) => (
            <>
              {selTaxonomy[tab] &&
                Object.keys(selTaxonomy[tab]).map((id) => {
                  const taxonomyTabObj = selTaxonomy[tab][id];
                  const isPolyHierarchi = fnToCheckIDIsPolyHierarchi(id);
                  const infoIconData = getTagsInfoIconDetailsifAny(taxonomyTabObj, informationIconsConfig);
                  return (
                    <li key={id} className={(infoIconData?.isTagged) ? "txbase__autoTagged" : ""}>
                      {infoIconData && <ImageWithToolip id={`${id}-informationIconInModal`} imgIcon={infoIconData?.infoIcon} toolTipClass="" imgClassName="txbase__poly-img txbase__iIcon-img" toolTipMsg={infoIconData?.infoIconToolTipMessage} />}
                      {isPolyHierarchi && <ImageWithToolip id={`${id}-selTaxonomy`} imgIcon={PolyHIcon} toolTipClass="" imgClassName="txbase__poly-img" toolTipMsg={POLY_HIERARCHI_TAXONOMY_SUGGESTION_MSG} />}
                      <span>{taxonomyTabObj?.name}</span>
                      <Image
                        src={(infoIconData?.isTagged) ? crossIconBlue : crossIcon}
                        alt="cross"
                        onClick={() => deleteSelected(tab, id, false)}
                      />
                    </li>
                  )
                })}
            </>
          ))}
        </ul>
        <div className="tx__midsection">
          {taxonomyName.length === 1 ? (
            this.getComponent(getTaxonomyData(taxonomyName[0].key), TAB1)
          ) : (
            <TabComponent
              wrapperClassName={`tx__tabWrapper ${!!modifiedSuggestionTags?.length ? " tx__tabWrapper-havesuggestions" : " "}`}
              className="tabs__container tx__tabs"
              menu={{ secondary: true, pointing: true }}
              defaultActiveIndex={activeIndex ? activeIndex : 0}
              hideCount
              onTabChangeCallback={this.onTabChangeCallback}
              arrayOfPaneElements={taxonomyName.map(({ label, key, disabled = false, tooltipMessage = "" }, index) => {
                return {
                  className: `item-style ${disabled ? 'disable' : ''} tab${index + 1}`,
                  heading: label,
                  tooltipMessage: tooltipMessage,
                  disable: disabled,
                  component: this.getComponent(
                    getTaxonomyData(key),
                    "tab" + (index + 1)
                  ),
                };
              })}
            />
          )}
          {!!modifiedSuggestionTags?.length && (
            <div className="tx__suggestions">
              {suggestionTagsHeading && <label className="tx__suggestionsHeading">{suggestionTagsHeading}</label>}
              <ul className="tx__suggestionslist">
                {
                  modifiedSuggestionTags.map((itemMain) => {
                    const guid = itemMain[suggestionsKeyName["id"]];
                    const name = itemMain[suggestionsKeyName["name"]];
                    const type = itemMain[suggestionsKeyName["type"]];
                    const { isDisabled } = itemMain;
                    const isExisting = getIsExistingModal(guid, name, type);
                    const id = removeCurlyBracketFromId(guid)?.toLowerCase()
                    const isPolyHierarchi = fnToCheckIDIsPolyHierarchi(id);
                    if (isDisabled) {
                      return (<Tooltip
                        id={guid + "_inModal"}
                        className={"txbase__suggestions__tooltip"}
                        position="top"
                        delayHide={0}
                        key={uuidv4()}
                        icon={
                          <li key={guid} index={guid} data-type={type} data-id={guid} className={`txbase__suggestions__tooltip__disabled`}>
                            <div className="tx__suggestionslistitem">
                              {isPolyHierarchi && <ImageWithToolip id={`${id}-tx__suggestionslistT`} imgIcon={PolyHIcon} toolTipClass="" imgClassName="txbase__poly-img" toolTipMsg={POLY_HIERARCHI_TAXONOMY_SUGGESTION_MSG} />}
                              <span>{name}</span>
                              <Image
                                src={isExisting ? rightIcon : plusicon}
                                alt="Plus icon"
                                className={isExisting ? "txbase__existing" : "txbase__plusicon"}
                              />
                            </div>
                          </li>
                        }
                      >
                        <p className="txbase__suggestions__tooltip-content" >{maxLimitLabel}</p>
                      </Tooltip>)
                    } else {
                      return (
                        <li key={guid} index={guid} data-type={type} data-id={guid}>
                          <div className="tx__suggestionslistitem">
                            {isPolyHierarchi && <ImageWithToolip id={`${id}-tx__suggestionslistNT`} imgIcon={PolyHIcon} toolTipClass="" imgClassName="txbase__poly-img" toolTipMsg={POLY_HIERARCHI_TAXONOMY_SUGGESTION_MSG} />}
                            <span>{name}</span>
                            <Image
                              src={isExisting ? rightIcon : plusicon}
                              alt="Plus icon"
                              className={isExisting ? "txbase__existing" : "txbase__plusicon"}
                              onClick={() => isExisting ? {} : addTagsFromSuggestions(guid, name, type)}
                            />
                          </div>
                        </li>
                      )
                    }
                  })
                }
              </ul>
            </div>
          )
          }
        </div>
        <div className="tx__footer">
          <div className="tx__footer__left">
            {CONFIG.MAIL_TO_LABEL(taxonomyName[0]?.key ?? "") && (
              <MailButton
                emailState={CONFIG.MAIL_TO(
                  isBSTTaxonomy
                )}
              />
            )}
          </div>
          <div className="tx__footer__right">
            <Button className="white tx__footer__cta" onClick={onRequestClose}>
              Cancel
            </Button>
            {(selTaxonomy[TAB1] && Object.keys(selTaxonomy[TAB1]).length) ||
              (selTaxonomy[TAB2] && Object.keys(selTaxonomy[TAB2]).length) ||
              (selTaxonomy[TAB3] && Object.keys(selTaxonomy[TAB3]).length) ? (
              <Button className="green tx__footer__cta" onClick={submitHandler}>
                Submit
              </Button>
            ) : null}
          </div>
        </div>
      </Modal >
    );
  }
}

TaxonomyModalContainer.defaultProps = {
  searchData: [],
  responseTypeSearchKeyName: {
    id: "id",
    name: "name",
  },
  taxonomyTypeSearchName: [
    { searchKey: "SEARCH_SUBJECT" }
  ],
  isShowNoResultInTypeAhead: false,
  hideTagsIds: [],
  suggestions: {
    tags: [],
    heading: "",
    maxLimitLabel: "",
    suggestionsKeyName: {
      id: "id",
      name: "name",
      type: "type"
    },
    getIsExistingModal: () => { },
    addTagsFromSuggestions: () => { },
    checkLimitAndDisable: (x, y, z) => { return z },
    informationIconsConfig: []
  }

};

TaxonomyModalContainer.propTypes = {
  searchData: PropTypes.array,
  responseTypeSearchKeyName: PropTypes.object,
  taxonomyTypeSearchName: PropTypes.array,
  hideTagsIds: PropTypes.array
};

const mapStateToProps = (state) => ({
  searchData: taxonomySelector.getTypeSearchData(state),
  searchDataLoading: taxonomySelector.getTypeSearchDataLoading(state),
  getParentTagsData: (taxonomyName) => taxonomySelector.getParentTagsData(state, taxonomyName),
});

const mapDispatchToProps = (dispatch) => ({
  getTypeSearchData: (term, typeSearchKey) => dispatch({ type: TAXONOMY.GET_TYPE_SEARCH_DATA, payload: { term, typeSearchKey } }),
  getSLparentTags: (key, ids) => dispatch({ type: TAXONOMY.GET_SL_PARENT_TAGS_DATA, payload: { ids, key, needParentalRoot: true } }),
  clearAllParentTags: () => dispatch(resetAllParentTags()),
});

export { TaxonomyModalContainer };

export const TaxonomyModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxonomyModalContainer);
