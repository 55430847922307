import React from "react";
import { connect } from "react-redux";
// Semantic UI
import { PeopleSelector } from "components/PeopleSelector";
import { selectors as globalSelectors } from "redux/reducers/global/global_reducer";
import ToggleSwitch from "components/shared/ToggleSwitch/ToggleSwitch";
import { Button } from "components/shared/Button";
import {
	copyObject,
	getPeopleApiDataByHrids,
	sortDataByKey,
	getmyCurrentLocation
} from "utils/helpers/helpers";
import LABELS from "labels";
import { selectors as fcgSelectors } from "redux/reducers/faceCardGeneratorv1/faceCardGeneratorv1_reducer";
import { selectors as proposalBioSelectors } from "redux/reducers/proposalBio/proposalBio_reducer";
import { bindActionCreators } from "redux";
import { actions as peopleResultsActions } from "redux/actions/peopleresults/peopleresults_actions";
import { proposalBioAcknowledgementPending, updateExperienceFields } from "redux/actions/proposalBio/proposalBio.actions";

//////////// Importing Constants & Helper /////////

import FACE_CARD_CONFIG from "containers/FaceCardGeneratorv1/FaceCardv1.config";
import Image from "components/shared/Image";
import LoaderIcon from "assets/images/spinner/spinner-v3.gif";
import closeFcg from "assets/images/fcg_close.svg";
import "./FCG_ProposalBio.scss";
import CONFIG from "config";
import { TabComponent } from "components/shared/TabComponent/TabComponent";
import {
	PAGES,
	PRIMARY_CATEGORY,
	DTMRULE,
	TOOLNAME,
	TRIGGERS,
} from "utils/analytics/analytics_constants";
import ANALYTICS from "utils/analytics/analytics";
import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";
import CONSTANTS from "globalConstants";
import warningIcon from "assets/images/warning/warning.svg";


const {
	METADATA: { PEOPLE_SELECTOR_PLACEHOLDER },
	FACE_CARD_SELECT_TEXTS: { BY_PEOPLE, BY_CASE_NO, BY_EMAIL_ID },
} = LABELS;

const {
	ENTITLEMENTS: {
		PROFILES,
		EDIT_COLLEAGUE_PROFILE
	}
} = CONSTANTS;

class FCG_ProposalBio extends React.PureComponent {
	constructor(props) {
		super();
		this.state = {
			isTitleChecked: false,
			isLocationChecked: false,
			peopleIds: !!props?.hrid ? [props.hrid] : [],
			peopleIdsWithData: [],
			fcgClose: true,
			selectedShape: " ",
			isPreviewDisable: true,
			isDownloadDisable: true,
			isUploadDisable: true,
			isPreviewInProgress: false,
			slideValue: "",
			localSlideTitleLength: 0,
			peopleData: null,
			isPreviewClicked: false,
			showPollingError: false
		};
	}

	componentDidMount() {
		ANALYTICS.page.initialize({
			pageName: PAGES.PROPOSAL_BIO_GENERATOR,
			category: PRIMARY_CATEGORY.PROPOSAL_BIO_GENERATOR,
			trackPageLoad: true
		});
	}


	getHridArray = (obj) => {
		let temp = [];
		temp = obj?.map((obj) => obj?.hrid || obj?.id);
		return temp;
	};

	componentDidUpdate(prevProps, prevState) {
		const { peopleIdsWithData } = this.state;
		const { isPollingError } = this.props;
		if (peopleIdsWithData !== prevState.peopleIdsWithData && peopleIdsWithData.length) {
			this.setState({ isPreviewDisable: false });
		}

		if (isPollingError && isPollingError !== prevProps.isPollingError) {
			this.setState({ showPollingError: true });
		}
	}
	handleChange = (hrids) => {
		const { resetCallback, peopleResultsActions } = this.props;
		const { peopleIdsWithData } = this.state;
		try {
			let newPeopleIdsWithData = [];
			if (peopleIdsWithData.length) {
				newPeopleIdsWithData = sortDataByKey(peopleIdsWithData, hrids, "id");
				newPeopleIdsWithData = newPeopleIdsWithData.filter((person) =>
					hrids.includes(person.id)
				);
			}

			this.setState({
				peopleIds: hrids,
				peopleIdsWithData: newPeopleIdsWithData,
				isPreviewDisable: hrids.length === 0,
				isDownloadDisable: true,
			});
			if (!hrids.length && resetCallback) {
				resetCallback();
			}
			peopleResultsActions.getPeopleDataUsingIDs(hrids).then((data) => {
				if (data && data.length) {
					this.setState({ peopleData: data[0] });
				}
			});
		} catch (error) {
			console.log("Error in handle Change ", error);
		}
	};

	handleFaceCardBtnClick = (id) => {
		try {
			this.setState({ showPollingError: false }, () => {
				if (id === "btn-preview") {
					this.handlePreview();
				} else if (id === "btn-download") {
					this.handleDownload();
				}
			});
		} catch (error) {
			console.log("Error in handleFaceCArdBtnClick ", error);
		}
	};
	handleDownload = () => {
		const { isGrayscale, isSquareShape, proposalPeopleData, userDetails, experienceFields, isGreenTheme } = this.props;
		const { NEWLINE_REGEX, PARAGRAPH_REGEX } = CONFIG.REGEX;

		const cleanExperienceFields = (fields) => {
			const cleanedFields = {};
			for (const key in fields) {
				if (fields.hasOwnProperty(key)) {
					cleanedFields[key] = fields[key].replace(NEWLINE_REGEX, "").replace(
						PARAGRAPH_REGEX,
						(match, content) => content.trim() === ""
							? '<p style="margin-bottom:0; margin-top:0; padding:0">&nbsp;</p>'
							: `<p style="margin-bottom:0; margin-top:0; padding:0">${content}</p>`
					);
				}
			}
			return cleanedFields;
		};

		const updatedExperienceFields = cleanExperienceFields(experienceFields);

		if (!proposalPeopleData) {
			return;
		}


		const payload = {
			appName: "C&M",
			templateType: isGreenTheme ? "proposal-bio-green" : "proposal-bio",
			requestedBy: userDetails.id,
			filters: {
				hasName: true,
				hasTitle: true,
				hasLocation: true,
				size: "M",
				grayscale: isGrayscale,
				shape: isSquareShape ? "square" : "circle",
			},
			employees: [
				{
					hrid: proposalPeopleData.id,
					name: `${proposalPeopleData.preferredFirstName || ""} ${proposalPeopleData.preferredLastName || ""}`.trim(),
					title: proposalPeopleData.businessTitle || "Unknown Title",
					image: proposalPeopleData.picture || "default-image-url",
					location: proposalPeopleData.hostOfficeLocation?.name || "Unknown Location",
				},
			],
			experience: updatedExperienceFields,

		};
		this.props.proposalBioAcknowledgementPending(payload);
		this.triggerPreviewDownloadAnalytics(TRIGGERS.DOWNLOADS, LABELS.FACE_CARD_SELECT_TEXTS.BY_PEOPLE);
	};
	handlePreview = async () => {
		const {
			peopleResultsActions: { getPeopleDataUsingIDs }, userDetails, isEditColleagueProfileEntitlement
		} = this.props;
		const { peopleIds } = this.state;

		try {
			if (peopleIds.length > 0) {
				const finalData = await getPeopleApiDataByHrids(
					peopleIds,
					getPeopleDataUsingIDs,
					false,
					true
				);

				if (finalData && finalData.length > 0) {
					const peopleData = finalData[0];
					this.setState({
						peopleData: {
							...peopleData,
						},
						isPreviewDisable: true,
						isDownloadDisable: false,
						isUploadDisable: false,
						isPreviewClicked: true
					});

					if (this.props.onPreviewData) {
						this.props.onPreviewData({
							...peopleData,
						});
					}

					// Enable the "Upload to Profile" button based on the specified logic
					const userHrid = userDetails.id;
					const assistantIds = peopleData.assistantIds || [];
					const isOwnProfile = peopleData.id === userHrid;
					const isAssistedProfile = assistantIds.includes(userHrid);

					if (isOwnProfile || (isAssistedProfile && isEditColleagueProfileEntitlement)) {
						this.setState({ isUploadDisable: false });
					}
					else {
						this.setState({ isUploadDisable: true });
					}

				}
			}
			this.triggerPreviewDownloadAnalytics(TRIGGERS.PREVIEW, LABELS.FACE_CARD_SELECT_TEXTS.BY_PEOPLE);
		} catch (error) {
			this.setState({ isPreviewDisable: false, isDownloadDisable: false, isUploadDisable: false });
			console.error("Error in handlePreview:", error);
		}
	};

	triggerPreviewDownloadAnalytics = (tabName, byType) => {
		const { CONTRIBUTE_KNOWLEDGE } = TOOLNAME;
		const newAdobeData = {
			page: {
				pageInfo: {
					pageId: PAGES.PROPOSAL_BIO_GENERATOR,
					pageName: PAGES.PROPOSAL_BIO_GENERATOR,
					pageURL: getmyCurrentLocation(),
				},
				category: {
					primaryCategory: PRIMARY_CATEGORY.PROPOSAL_BIO_GENERATOR,
				}
			},
			search: {
				term: "NA",
				searchTrigger: byType,
				typeHeadOptionText: "NA",
				typeHeadOptionRank: "NA",
				filterTerm: "NA",
				filterLabel: "NA",
				filterLevel: "NA",
				toolName: "NA",
			},
			preFilterTerm: "NA",
			officePage: {
				mainCategory: "NA",
			},
			rc: {
				tabName: tabName, //eVar48
			},
		};

		ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.TAB_CLICKS, CONTRIBUTE_KNOWLEDGE);
	};

	updateButtonsConfig = (buttons) => {
		try {
			const { getLoading, isError } = this.props;
			const { peopleIds, isPreviewDisable, isDownloadDisable, isUploadDisable } = this.state;
			if (buttons?.length > 0) {
				if (peopleIds.length === 0 || getLoading) {
					buttons[0].disabled = true;
					buttons[1].disabled = true;
					buttons[2].disabled = true;
				} else {
					buttons[0].disabled = false;
				}
				if (peopleIds.length) {
					if (isPreviewDisable || getLoading) {
						buttons[0].disabled = true;
					} else {
						buttons[0].disabled = false;
					}
				}
				if (isDownloadDisable || getLoading || isError) {
					buttons[2].disabled = true;
				} else {
					buttons[2].disabled = false;
				}
				// Update the state of the "Upload to Profile" button
				const uploadButton = buttons.find(button => button.id === 'btn-upload');
				if (uploadButton) {
					uploadButton.disabled = isUploadDisable;
				}
			}
		} catch (error) {
			console.log("Error in updateButtonsConfig function ", error);
		}
	};

	crossClickCallback = () => {
		const { resetCallback, handleClearProposalData } = this.props;
		this.setState(
			{
				peopleIds: [],
				peopleIdsWithData: [],
				isDownloadDisable: true,
				isPreviewDisable: true,
				isUploadDisable: true,
				isPreviewClicked: false,
				showPollingError: false
			},
			() => {
				if (resetCallback) resetCallback();
				if (handleClearProposalData) handleClearProposalData();
			}
		);
	};

	callBackAfterPeopleApiFetch = (data) => {
		const finalPeopleIds = this.getHridArray(data);
		this.setState(
			{
				peopleIds: finalPeopleIds,
				peopleIdsWithData: data,
			}
		);
	};

	handleClose = () => {
		const { dispatch, onParentClose } = this.props
		this.setState({
			fcgClose: true,
		});
		dispatch(
			updateExperienceFields({
				role: "",
				summaryRelevantExperience: "",
				profileSummary: "",
				relevantExperience: "",
				priorExperienceAndEducation: "",
			})
		);
		if (onParentClose) {
			onParentClose();
		}
	}

	render() {
		const { getLoading, isGrayscale, isSquareShape, onToggleShape, onToggleGrayscale, selectedTemplateTitle, isPollingError } = this.props;
		const buttons = copyObject(FACE_CARD_CONFIG.proposalFooterWorkflow);
		const { peopleIds, isUploadDisable, isPreviewClicked, showPollingError } = this.state;
		//Update buttons config
		this.updateButtonsConfig(buttons);
		let typeFilters = [{ key: BY_PEOPLE, text: BY_PEOPLE, value: BY_PEOPLE }];

		const {
			FACE_CARD_GENERATOR: { USE_B_W, PROFILE_PIC_SHAPE },
		} = LABELS;
		return (
			<>
				<div className="FCG_ProposalBio__header">
					<div className="FCG_ProposalBio__title-wrapper">
						<div className="FCG_ProposalBio__title">
							<h2>{selectedTemplateTitle || "Default Title"}</h2>
						</div>
					</div>
					<button className="FCG_ProposalBio__closeBtn" onClick={this.handleClose}>
						<span>Close</span>
						<img src={closeFcg} alt="Close" />
					</button>
				</div>
				<div className="FCG_ProposalBio">
					<div className="FCG_ProposalBio-header">
						<div className="FCG_ProposalBio-searchSec">
							<>
								<TabComponent
									className="FCG_ProposalBio__tabsComponent"
									defaultActiveIndex={0}
									menu={{ secondary: true, pointing: true }}
									hideCount
									arrayOfPaneElements={[
										{
											heading: LABELS.FACE_CARD_SELECT_TEXTS.BY_PEOPLE,
										},
									]}
								></TabComponent>
							</>
							<PeopleSelector
								placeholder={PEOPLE_SELECTOR_PLACEHOLDER}
								isInputFirst
								name="authors"
								preFillData={[peopleIds]}
								isStateReset={!peopleIds.length}
								isBigImage
								maxLimit={1}
								excludeAlumni
								callBackAfterPeopleApiFetch={this.callBackAfterPeopleApiFetch}
								onChange={(data) => {
									this.handleChange(data);
								}}
								suggestions={{
									useDragDrop: true,
								}}
								getSuggestionValueCallback={() => { }}
								className="FCG_ProposalBio__peopleselector"
								isScrollable={true}
								crossClickCallback={this.crossClickCallback}
							/>
							{showPollingError && (
								<div className="FCG_ProposalBio__errorMessage">
									<Image src={warningIcon} className="FCG_ProposalBio__errorMessage-icon" />
									{isPollingError && <div>{isPollingError}</div>}
								</div>
							)}
						</div>
						<div className="FCG_ProposalBio__content">
							<div className="FCG_ProposalBio__slideTitleCheckboxes">
								<div className="FCG_ProposalBio__blackWhiteToggle__shapeToggle">
									<span>{PROFILE_PIC_SHAPE}</span>
									<div className="FCG_ProposalBio__blackWhiteToggle__shapeToggle__shapeOptions">
										<span className="FCG_ProposalBio__blackWhiteToggle__shapeToggle__shapeOptions__circle">
											{"Circle"}
										</span>
										<ToggleSwitch
											onToggle={onToggleShape}
											isActive={isSquareShape}
										/>
										<span className="FCG_ProposalBio__blackWhiteToggle__shapeToggle__shapeOptions__square">
											{"Square"}
										</span>
									</div>
								</div>

								<div className="FCG_ProposalBio__blackWhiteToggle__bwToggle">
									<span>{USE_B_W}</span>
									<ToggleSwitch
										onToggle={onToggleGrayscale}
										isActive={isGrayscale}
									/>
								</div>
							</div>
						</div>
						<div className="FCG_ProposalBio-btnSec">
							{getLoading && (
								<Image className="loadericon" src={LoaderIcon} alt="In progress" />
							)}
							{buttons &&
								buttons.map((btn, ind) => (
									<React.Fragment key={`btn-child-${ind}`}>
										{btn?.visible && (
											<Button
												className={`${ind == 1 ? "green" : "black"} ${btn.classname
													} ${btn.disabled ? "btn-disbled" : ""}`}
												onClick={() => this.handleFaceCardBtnClick(btn?.id)}
												disabled={btn.disabled}
												title={btn.id === 'btn-upload' && isUploadDisable && isPreviewClicked ? btn.title : ""}

											>
												<span>{btn.text}</span>
											</Button>
										)}
									</React.Fragment>
								))}
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	getLoading: proposalBioSelectors.getAcknowledgmentLoading(state) || proposalBioSelectors.getPollingLoading(state),
	preSignedUrl: fcgSelectors.getPresignedURL(state),
	userDetails: globalSelectors.getUserDetails(state),
	experienceFields: proposalBioSelectors.getExperienceFields(state),
	isEditColleagueProfileEntitlement: entitlementSelectors.getEntitlementValue(state, PROFILES, EDIT_COLLEAGUE_PROFILE),
	isPollingError: proposalBioSelectors.getPollingError(state),
});

const mapDispatchToProps = (dispatch) => ({
	dispatch,
	proposalBioAcknowledgementPending: (payload) => dispatch(proposalBioAcknowledgementPending(payload)),
	peopleResultsActions: bindActionCreators({ ...peopleResultsActions }, dispatch),
});

export const FCG_ProposalBiov1 = connect(mapStateToProps, mapDispatchToProps)(FCG_ProposalBio);
