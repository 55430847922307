import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { CardView, CardViewSubject, GlobalStyle, OverlayView, OverlayViewSubject, ThumbnailViewSubject, SUBSCRIBERS } from '@kdshared/docviz';
import { DOCVIZ } from "redux/constants";
import LOCAL_CONFIG from './Docviz.config';
import LABELS from "labels";
import CONSTANTS from "globalConstants";
import ANALYTICS from "utils/analytics/analytics";
import { DTMRULE, MATERIAL_STATUS, PAGES, PRIMARY_CATEGORY, SCREEN_NAMES, TRIGGERS } from "utils/analytics/analytics_constants";
import { enableBodyOverflow, disableBodyOverflow } from "redux/actions/global/global.actions";
import { selectors as docvizSelectors } from "redux/reducers/docviz/docviz_reducer";
import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";
import { selectors as kpSelectors } from "redux/reducers/knowledgepage/knowledgepage_reducer"
import { showDownloadIcon, checkIsGivenValueIsPresentInObj, getFileExtension, getPreSignedApiEndPoint } from "utils/helpers/helpers";
import styled from "styled-components";
import fonts from "global-styles/_typography.scss";
import colors from "global-styles/_colors.scss";
import "./Docviz.scss";
import CONFIG from 'config';
import DocvizOverlayWrapper from "components/DocvizOverlayWrapper/DocvizOverlayWrapper";
import { triggerClippingAnalytics } from "utils/analytics/utils_analytics";

export const OverlayWrapper = styled(OverlayView)`
  .docViz-modal {
    .attachment {
      .attachTitle {
        color: ${colors.grayDarkest};
        font-family: ${fonts.sansBold};
        font-size: 14px;
        line-height: 20px;
      }
    }

    .slideSelection-action--2 {
      display: none;
    }

    .docViz-header__actions {
      img.ui.image {
        max-width: 27px;
      }
    }

    .headerMask.row {
      .col-md-1 {
        position: static;
      }
    }

  }
`;

export const CardViewWrapper = styled(CardView)`
  .previewBtn:hover {
    text-decoration: none;
    cursor: initial !important;
  }
  .card-actions-block {
    button { 
      img.ui.image {
        max-width: 24px;
      }
    }
  }
`;

const {
  ENTITLEMENTS: {
    KNOWLEDGE_CONTRIBUTIONS,
    PREVIEW_DOWNLOAD,
    PREVIEW_DOWNLOAD_R
  }
} = CONSTANTS;

const { DOWNLOAD_APPLICATION_NAME: { KNOWLEDGE_PAGE_APP_NAME }, DOCVIZ_SCROLL_VIEW_EXTNS, IS_FRAMEWORK_ENABLED } = CONFIG;

class DocvizContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      currentSlideNumber: props?.docvizDoc?.currentSlideIndex,
      selectedSlides: []
    }
    this.cardSubscriber = null;
    this.overlaySubscriber = null;
    this.thumbnailViewSubscriber = null;
  }

  componentDidMount() {
    const {
      download,
      docvizDoc,
      handlePreviewFromGrid,
      showCardScrollConsumptionView,
      shouldHandleClipDownload = true,
      isPreviewDownloadEntitlement,
      isPreviewDownloadREntitlement,
      isMDPRestricted,
      handleTitleClickFromGrid,
      handlePreviewCloseAction,
      handleErrorAnalyticsFromGrid
    } = this.props;

    this.overlaySubscriber = OverlayViewSubject.subscribe(action => {
      switch (action.type) {
        case SUBSCRIBERS.CLOSE_CLICK:
          const { globalActions: { enableBodyOverflow } } = this.props;
          this.setState({ open: false });
          this.setState({ currentSlideNumber: docvizDoc?.currentSlideIndex })
          enableBodyOverflow();
          handlePreviewCloseAction();
          break;
        case SUBSCRIBERS.CLIP_DOWNLOAD_CLICK:
          if (shouldHandleClipDownload) {
            const { slides } = action;
            const slideNumbers = slides.map(s => s.slideNumber);
            this.clippedDownload(slideNumbers);
          }
          break;
        default:
          break;
      }
    });

    this.cardSubscriber = CardViewSubject.subscribe(action => {
      switch (action.type) {
        case SUBSCRIBERS.PREVIEW_CLICK:
          if (handlePreviewFromGrid) {
            let id = action?.doc?.kpId;
            handlePreviewFromGrid(id)
          } else {
            this.fnOpenDocvizOverlay();
          }
          break;
        case SUBSCRIBERS.DOWNLOAD_CLICK:
          let id = action?.doc?.kpId
          download(id);
          break;
        case SUBSCRIBERS.FRAMEWORK_POST_SUCCESS:
          const { frameworkObj } = action;
          this.frameworkPostCallBack(frameworkObj);
          break;
        case SUBSCRIBERS.DOCVIZ_LOAD_ERROR:
          const { errMsz, doc } = action;
          if (handleErrorAnalyticsFromGrid) {
            handleErrorAnalyticsFromGrid(errMsz, doc?.kpId);
          } else {
            this.handleDocvizLoadErrorAnalytics(errMsz);
          }
          break;
        case SUBSCRIBERS.TITLE_CLICK:
          if (handleTitleClickFromGrid) {
            let id = action?.doc?.kpId;
            handleTitleClickFromGrid(id)
          }
          break;
        default:
          break;
      }
    });

    this.thumbnailViewSubscriber = ThumbnailViewSubject.subscribe(action => {
      switch (action.type) {
        case SUBSCRIBERS.SLIDE_CLICK:
          if (showCardScrollConsumptionView) {
            this.handleSlideClick(action.slide);
          }
          break;
        case SUBSCRIBERS.CHECKBOX_CLICK:
          if (showCardScrollConsumptionView) {
            this.handleCheckBoxClick(action.slide);
          }
          break;
        default:
          break;
      }
    });

    this.props.setEntitled(
      // if consumption view, compute entitlement flag
      // if creation view, always show preview
      showDownloadIcon(isPreviewDownloadEntitlement, isPreviewDownloadREntitlement, isMDPRestricted)
    );
  }

  fnOpenDocvizOverlay = () => {
    const { globalActions: { disableBodyOverflow } } = this.props;
    this.setState({ open: true });
    disableBodyOverflow();
    this.handleOpenModalAnalytics();
  }

  componentWillUnmount = () => {
    if (this.cardSubscriber) {
      this.cardSubscriber.unsubscribe();
    }
    if (this.overlaySubscriber) {
      this.overlaySubscriber.unsubscribe();
    }
    if (this.thumbnailViewSubscriber) {
      this.thumbnailViewSubscriber.unsubscribe();
    }
  }

  handleSlideClick = (slideData) => {
    if (this.state.open) {
      return false;
    }
    const { slideNumber } = slideData;
    this.setState({ currentSlideNumber: slideNumber - 1 });
    this.fnOpenDocvizOverlay();
    const {
      PA_RECOMMENDED,
      NOT_PA_RECOMMENDED
    } = MATERIAL_STATUS;

    const { kpMetaData: { id }, isConsumptionView, kpMetaData: { paRecommended } } = this.props;
    const newAdobeData = {
      // KCpage: {
      //   id: id
      // },
      materialID: "NA",
      displayMode: "Slide overlay",
      materialPAStatus: paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED,
      attachmentID: "NA",
      collection: {
        trigger: "KP_Slide_View",
        ID: id
      },
      page: {
        category: {
          primaryCategory: isConsumptionView ? PRIMARY_CATEGORY.KP_CONSUMPTION : PRIMARY_CATEGORY.KP,
        },
        pageInfo: {
          pageName: isConsumptionView ? PAGES.KP_CONSUMPTION : PAGES.KP_CREATION,
          pageURL: window.location.href
        },
      },
    };
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.PREVIEW_SLIDES);
  }

  handleCheckBoxClick = (slideData) => {
    const { selectedSlides } = this.state;
    let newSelectedSlides = [...selectedSlides];

    if (newSelectedSlides.includes(slideData)) {
      newSelectedSlides = newSelectedSlides.filter(slide => slide !== slideData);
    }
    else {
      newSelectedSlides.push(slideData);
    }
    this.setState(prevState => ({ ...prevState, selectedSlides: newSelectedSlides }));
  }

  componentDidUpdate = (prevProps) => {
    const {
      docvizDoc,
      isPreviewDownloadEntitlement,
      isPreviewDownloadREntitlement,
      isMDPRestricted,
      openDocvizOverlay
    } = this.props;

    if (prevProps.docvizDoc !== docvizDoc || prevProps.isPreviewDownloadEntitlement !== isPreviewDownloadEntitlement || prevProps.isPreviewDownloadREntitlement !== isPreviewDownloadREntitlement) {
      this.props.setEntitled(
        // if consumption view, compute entitlement flag
        // if creation view, always show preview
        showDownloadIcon(isPreviewDownloadEntitlement, isPreviewDownloadREntitlement, isMDPRestricted)
        , () => {
          if (openDocvizOverlay && this.props.isEntitled) {
            this.fnOpenDocvizOverlay();
          }
        });
    }
    if (docvizDoc?.currentSlideIndex !== prevProps?.docvizDoc.currentSlideIndex) {
      this.setState({ currentSlideNumber: docvizDoc?.currentSlideIndex });
    }
  }

  handleDocvizLoadErrorAnalytics(msz) {
    const { kpMetaData: { id }, isConsumptionView } = this.props;
    const newAdobeData = {
      KCpage: {
        id: id
      },
      page: {
        category: {
          primaryCategory: isConsumptionView ? PRIMARY_CATEGORY.KP_CONSUMPTION : PRIMARY_CATEGORY.KP,
        },
        pageInfo: {
          pageName: isConsumptionView ? PAGES.KP_CONSUMPTION : PAGES.KP_CREATION,
          pageURL: window.location.href
        },
      },
      docviz: {
        error: msz
      }
    };
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.DOCVIZ_PAGE_LOAD_FAIL);
  }

  clippedDownload(slideNumbers) {
    const {
      downloadSelectedSlides,
      kpMetaData,
      docvizDoc,
      isConsumptionView
    } = this.props;

    downloadSelectedSlides(slideNumbers, kpMetaData.id, docvizDoc);
    triggerClippingAnalytics({
      id: "NA",
      kpid: docvizDoc.kpId,
      isCases: false,
      paRecommended: docvizDoc?.paRecommended,
      pageName: isConsumptionView ? PAGES.KP_CONSUMPTION : PAGES.KP_CREATION,
      primaryCategory: isConsumptionView ? PRIMARY_CATEGORY.KP_CONSUMPTION : PRIMARY_CATEGORY.KP,
      slideNumber: slideNumbers.join("|")
    });
  }

  handleOpenModalAnalytics() {
    const {
      kpMetaData,
      docvizDoc,
      isConsumptionView
    } = this.props;
    const {
      id,
      legacyMaterialId,
      legacyAttachmentId,
      paRecommended
    } = kpMetaData;
    const {
      slides
    } = docvizDoc;
    const {
      DISPLAY_MODE
    } = LABELS;
    const {
      PA_RECOMMENDED,
      NOT_PA_RECOMMENDED
    } = MATERIAL_STATUS;

    const newAdobeData = {
      attachmentID: legacyAttachmentId,
      collection: {
        trigger: TRIGGERS.KP_PREVIEW
      },
      displayMode: DISPLAY_MODE.DOCVIZ,
      KCpage: {
        id: id
      },
      materialID: legacyMaterialId,
      materialPAStatus: paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED,
      numberofSlides: slides?.length,
      page: {
        category: {
          primaryCategory: isConsumptionView ? PRIMARY_CATEGORY.KP_CONSUMPTION : PRIMARY_CATEGORY.KP,
        },
        pageInfo: {
          pageName: isConsumptionView ? PAGES.KP_CONSUMPTION : PAGES.KP_CREATION,
          pageURL: window.location.href
        },
      },
      resultType: SCREEN_NAMES.KP
    };
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.DOCVIZ_OVERLAY_OPEN);
  }

  triggerAnalyticsFramework = (slidesTagged, guId) => {
    const slidesTaggedString = slidesTagged.map(({ id }) => id).join("|");

    const {
      kpMetaData: { paRecommended },
      isConsumptionView
    } = this.props;
    const {
      PA_RECOMMENDED,
      NOT_PA_RECOMMENDED
    } = MATERIAL_STATUS;

    const newAdobeData = {
      collection: {
        ID: "NA"
      },
      KCpage: {
        id: guId
      },
      materialPAStatus: paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED,
      search: {
        term: "NA"
      },
      numberofSlides: !!slidesTaggedString ? slidesTaggedString : "nf",
      page: {
        category: {
          primaryCategory: isConsumptionView ? PRIMARY_CATEGORY.KP_CONSUMPTION : PRIMARY_CATEGORY.KP,
        },
        pageInfo: {
          pageName: isConsumptionView ? PAGES.KP_CONSUMPTION : PAGES.KP_CREATION,
          pageURL: window.location.href
        },
      },
      resultType: "NA",
      displayMode: "NA", // DISPLAY_MODE.DOCVIZ,
      preFilterTerm: "NA",
      preOrderTerm: "NA",
      caseID: "NA",
      SERPview: "NA"
    };
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.DOCVIZ_FRAMEWORK_CLICK);
  }

  frameworkPostCallBack = (frameworkObj) => {
    const { slidesTagged, id: guId } = frameworkObj;
    const { updateOnlyFrameWorkSlides } = this.props;
    updateOnlyFrameWorkSlides(slidesTagged);
    this.triggerAnalyticsFramework(slidesTagged, guId);
  }

  /**
   * Fucntion used to show and hide card scroll view in Docviz
   * @returns bool
   */
  getIsCardScrollView = (fileextn) => {
    const { docvizDoc, isDocvizNotSupported, isEntitled } = this.props;
    let showUnsupportedPreview = isEntitled && isDocvizNotSupported;
    if ((DOCVIZ_SCROLL_VIEW_EXTNS.indexOf(fileextn) > -1) && !showUnsupportedPreview && isEntitled) {
      return true
    }
    return false;
  }

  /**
   * Fucntion used to show and hide card scroll view fit to content height based on below conditions
   * @returns bool
   */
  getScrollViewHeightToFitContent = () => {
    const { activeStep, docvizDoc } = this.props;
    let slideCount = 0;
    if (docvizDoc && checkIsGivenValueIsPresentInObj(docvizDoc, 'slides')) {
      slideCount = docvizDoc.slides;
    }
    if (((slideCount < 2 && activeStep === 3) || (slideCount < 4 && activeStep === 1))) {
      return true;
    }
    return false
  }

  getDocvizActions = (fileextn) => {
    const { isKpContributionPage, docvizActions } = this.props;
    let showPreview = true;
    if (isKpContributionPage && this.getIsCardScrollView(fileextn)) {
      showPreview = false;
    }
    return { ...docvizActions, showPreview }
  }

  render() {
    const {
      docvizDoc,
      downloadStatus,
      docvizActions,
      isDocvizNotSupported,
      isKpContributionPage,
      pollingData,
      initiatePolling,
      isConsumptionView,
      isMultipleCardView,
      showFrameworkWarning,
      showFrameWorkRibbon,
      showReleventMarkers,
      isMDPRestricted,
      isPreviewDownloadREntitlement,
      kpMetaData,
      isTagFrameworkEntitlement,
      isDetailCardView,
      getKPFile,
      downloadAllStatus,
      showCardScrollView,
      showFileHeaderOnScrollView,
      showCardScrollConsumptionView,
      selectable,
      isShowFrameworkStripe
    } = this.props;
    const { currentSlideNumber } = this.state;

    let fileextn = getFileExtension(kpMetaData?.attachment?.fileName);

    const preSignedApiEndPoint = getPreSignedApiEndPoint(kpMetaData?.attachment?.documentPath)
    const isPdf = preSignedApiEndPoint.search('.pdf') !== -1;
    
    return (
      <div className="docviz-wrapper">
        <GlobalStyle />
        {
          docvizDoc && this.state.open &&
          <DocvizOverlayWrapper
            doc={{ ...docvizDoc, currentSlideIndex: currentSlideNumber }}
            preSignedApiEndPoint={isPdf ? preSignedApiEndPoint : ""}
            {...LOCAL_CONFIG.otherProps}
            downloadStatus={downloadStatus}
            actions={docvizActions}
            kpDocvizAnalyticsData={{
              paRecommended: docvizDoc?.paRecommended,
              category: isConsumptionView ? PAGES.KP_CONSUMPTION : PAGES.KP_CREATION,
            }}
            getKPFile={getKPFile}
            downloadAllStatus={downloadAllStatus}
            isShowFrameworkIcons={IS_FRAMEWORK_ENABLED}
            defaultSelectedSlides={this.state.selectedSlides}
          />
        }
        <CardViewWrapper
          doc={docvizDoc}
          {...LOCAL_CONFIG.otherProps}
          preSignedApiEndPoint={isPdf ? preSignedApiEndPoint : ""}
          pdfWidth={464}
          entitled={this.props.isEntitled}
          actions={this.getDocvizActions(fileextn)}
          isHideExpandText
          isDocvizNotSupported={isDocvizNotSupported}
          polling={pollingData}
          initiatePolling={initiatePolling}
          isShowFramework={isKpContributionPage && isTagFrameworkEntitlement && this.getIsCardScrollView(fileextn) && IS_FRAMEWORK_ENABLED}
          //showCardScrollView={isKpContributionPage && this.getIsCardScrollView()}
          showCardScrollView={showCardScrollView}
          scrollViewHeightToFitContent={isPdf ? false : this.getScrollViewHeightToFitContent()}
          isMultipleCardView={isMultipleCardView}
          isShowMDPRestrictedMessage={isMDPRestricted && isPreviewDownloadREntitlement && showCardScrollConsumptionView}
          showFileHeaderOnScrollView={showFileHeaderOnScrollView}
          showFrameworkWarning={showFrameworkWarning}
          showFrameWorkRibbon={showFrameWorkRibbon}
          showReleventMarkers={showReleventMarkers}
          kpDescription={kpMetaData?.description}
          pagesHostUrl={window.location.origin}
          isDetailCardView={isDetailCardView}
          showCardScrollConsumptionView={showCardScrollConsumptionView}
          selectable={selectable}
          isShowFrameworkStripe={isShowFrameworkStripe}
          downloadStatus={downloadStatus}
          downloadAllStatus={downloadAllStatus}
          defaultSelectedSlides={this.state.selectedSlides}
        />
      </div>
    );
  }
}

DocvizContainer.propTypes = {
  docvizActions: PropTypes.shape(),
  download: PropTypes.func,
  kpMetaData: PropTypes.shape().isRequired,
  isConsumptionView: PropTypes.bool.isRequired,
  isMDPRestricted: PropTypes.bool,
  docvizDoc: PropTypes.shape().isRequired,
  shouldHandleClipDownload: PropTypes.bool,
  isMultipleCardView: PropTypes.bool,
  handlePreviewCloseAction: PropTypes.func,
  showFrameworkWarning: PropTypes.bool,
  showFrameWorkRibbon: PropTypes.bool,
  showReleventMarkers: PropTypes.bool,
  isDetailCardView: PropTypes.bool
};

DocvizContainer.defaultProps = {
  docvizActions: {
    showDownload: false,
    showEmail: false,
    showPreview: true,
    showEyePreview: false
  },
  download: () => { },
  downloadApplicationName: KNOWLEDGE_PAGE_APP_NAME,
  shouldHandleClipDownload: true,
  isMultipleCardView: false,
  handlePreviewCloseAction: () => { },
  showFrameworkWarning: false,
  showFrameWorkRibbon: false,
  showReleventMarkers: false,
  isDetailCardView: false
};

const mapStateToProps = (state) => ({
  downloadStatus: docvizSelectors.getDownloadStatus(state),
  isPreviewDownloadEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PREVIEW_DOWNLOAD),
  isPreviewDownloadREntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PREVIEW_DOWNLOAD_R),
  downloadAllStatus: kpSelectors.getLoadingDownloadAll(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  globalActions: bindActionCreators({ enableBodyOverflow, disableBodyOverflow }, dispatch),
  updateOnlyFrameWorkSlides: (slidesTagged) => dispatch({ type: DOCVIZ.UPDATE_ONLY_FRAMEWORK_SLIDES, payload: { slidesTagged } }),
  downloadSelectedSlides: (pages, documentId, docvizDoc) => dispatch({
    type: DOCVIZ.DOWNLOAD_SELECTED_SLIDES,
    payload: {
      pages,
      documentId,
      doc: docvizDoc,
      appName: ownProps.downloadApplicationName
    }
  }),
});

export const Docviz = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocvizContainer);

export { DocvizContainer };
