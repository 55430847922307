import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import history from "redux/history";

import { KnowledgeListing } from "components/KnowledgeListing";
import { KnowledgeFilters } from "components/KnowledgeFilters";
import { WarningModal } from "components/WarningModal/WarningModal";
import ANALYTICS from "utils/analytics/analytics";
import CONSTANTS from "globalConstants";
import { Pagination } from "components/Pagination";
import { KnowledgeNoResults } from "components/KnowledgeNoResults";
import { selectors as mynavigatorSelectors } from "redux/reducers/mynavigator/mynavigator_reducer";
import { DTMRULE, PAGES, PRIMARY_CATEGORY, SCREEN_NAMES, TRIGGERS, MATERIAL_STATUS } from "utils/analytics/analytics_constants";
import { ENTITLEMENTS, KNOWLEDGEOBJECT_VERSIONING, MATERIALS_STATUS } from 'redux/constants';
import { areObjectsEqual, checkAllEntitlementValue, copyObject, getmyCurrentLocation, openURL, routeToInQueueStatus } from "utils/helpers/helpers";
import { actions as mynavigatorActions } from "redux/actions/mynavigator/mynavigator_actions";
import { isArray } from 'lodash';
import GLOBAL_CONFIG from "config";

import { selectors as caseOwners } from "redux/reducers/caseOwners/caseOwners_reducer";
import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";


import CONFIG from "config";
import LABELS from "labels";

import "./MyContribution.scss";
import { FullPageLoader } from "components/shared/FullPageLoader";
import { getDownloadsCount } from "redux/actions/mynavigator/mynavigator.actions";
import { selectors as materialsStatusSelectors } from "redux/reducers/materialstatus/materialstatus_reducer";


const {
  ENTITLEMENTS: {
    KNOWLEDGE_CONTRIBUTIONS,
    ALL,
    VIEW,
    CREATE_EDIT_DRAFT,
    EDIT_PUBLISHED_CONTENT,
    PUBLISH_CONTENT,
  }
} = CONSTANTS;
const {
  MY_NAVIGATOR: {
    ENTITY_ACTIONS_MODAL_TITLES: {
      KP_NEW_UNPUBLISHED_MODAL_TITLE
    }
  },
} = LABELS;

class MyContributionContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isUnPublishSuccess: false,
      previousPill: null
    }
  }
  componentDidMount = async () => {
    const {
      mynavigatorActions: {
        searchKnowledge
      },
      searchCriteria,
      checkSiteAdmin,
      getMaterialStatus
    } = this.props;
    searchKnowledge(searchCriteria);
    checkSiteAdmin();
    getMaterialStatus();

    const currentPill = new URLSearchParams(window.location.search).get('pill');
    this.setState({ previousPill: currentPill });
    this.handlePillNavigation(currentPill);
  }
  componentDidUpdate(prevProps) {
    const {
      searchCriteria,
      knowledgeEntities,
      downloadCounts,
      getMaterialsStatusData
    } = this.props;
    const {
      MY_NAVIGATOR: {
        STATUS_FILTERS: {
          PUBLISHED
        },
        ENTITY_TYPES: {
          KP
        }
      },
    } = LABELS;
    const { status } = searchCriteria;
    const previousPill = this.state.previousPill;
    // calling download count action with kpids for published status
    const { entityType } = this.getEntityData(knowledgeEntities);

    if (status === PUBLISHED && entityType === KP) {
      if (knowledgeEntities && knowledgeEntities.length > 0 && !areObjectsEqual(knowledgeEntities, prevProps?.knowledgeEntities)) {
        const kpids = knowledgeEntities.map(cur => cur?.id);
        downloadCounts(kpids);
      }
    }

    const currentPill = new URLSearchParams(window.location.search).get('pill');

    if (prevProps.getMaterialsStatusData !== getMaterialsStatusData || previousPill !== currentPill) {
      if (currentPill === 'inqueue' && (!getMaterialsStatusData || getMaterialsStatusData.length === 0)) {
        this.handlePillNavigation('publish');
      } else {
        this.handlePillNavigation(currentPill);
      }
      this.setState({ previousPill: currentPill });
    }
  }

  handlePillNavigation = (pill) => {
    switch (pill) {
      case 'inqueue':
        this.statusFilterHandler('In Queue');
        break;
      case 'draft':
        this.statusFilterHandler('Draft');
        break;
      case 'publish':
        this.statusFilterHandler('Published');
        break;
      case 'underreview':
        this.statusFilterHandler('Review');
        break;
      case 'archived':
        this.statusFilterHandler('Archived');
        break;
      case 'unpublish':
        this.statusFilterHandler('Withdraw');
        break;
      default:
        this.statusFilterHandler('Draft');
        break;
    }
  }

  typeFilterHandler = (type) => {
    const {
      mynavigatorActions: {
        changeSearchType
      },
      searchCriteria,
      getMaterialStatus
    } = this.props;

    this.appendPillToUrl(searchCriteria.status.toLowerCase());

    if (searchCriteria.searchType !== type) {
      const newAdobeData = {
        page: {
          pageInfo: {
            pageName: PAGES.MY_NAVIGATOR
          },
          category: {
            primaryCategory: PRIMARY_CATEGORY.MY_NAVIGATOR
          }
        },
        collection: {
          screenName: SCREEN_NAMES.MY_CONTRIBUTIONS
        },
        resultsShowOptions: type === "kb" ? "Knowledge Bundle" : "Knowledge Page"
      };
      ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.RESULTS_SHOW);

      searchCriteria.searchType = type;
      searchCriteria.pageNumber = 1;
      searchCriteria.bundleType = type;
      changeSearchType(searchCriteria);
      getMaterialStatus();
    }
  };

  changePageHandler = (pageNumber) => {
    const {
      mynavigatorActions: {
        changePage
      },
      searchCriteria,
      getMaterialStatus
    } = this.props;
    const {
      MY_NAVIGATOR: {
        STATUS_FILTERS: {
          ALL
        },
        SORT_FILTERS: {
          DATE_DESC
        },
      },
    } = LABELS;
    const { entityType } = this.getEntityData(null);

    if (searchCriteria.pageNumber !== pageNumber) {
      const newAdobeData = {
        page: {
          pageInfo: {
            pageName: PAGES.MY_NAVIGATOR
          },
          category: {
            primaryCategory: PRIMARY_CATEGORY.MY_NAVIGATOR
          }
        },
        collection: {
          screenName: SCREEN_NAMES.MY_CONTRIBUTIONS
        },
        preFilterTerm: searchCriteria.status === "" ? ALL : searchCriteria.status,
        preOrderTerm: `${DATE_DESC} | ${entityType}`,
        searchResultRanking: pageNumber
      };
      ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.MY_CREATION_ACTIONS);

      searchCriteria.pageNumber = pageNumber;
      changePage(searchCriteria);
      getMaterialStatus();
    }
  }
  actionHandler = (event, source, entity) => {
    event && event.stopPropagation();
    const {
      deleteKnowledgeAsset,
      unpublishKnowledgeAsset,
      archiveKnowledgeAsset,
      searchCriteria,
      getMaterialStatus,
      mynavigatorActions: {
        searchKnowledge
      }
    } = this.props;
    const {
      TIMEOUT_LIMITS: {
        LIMIT_SEARCH_KNOWLEDGE_API
      }
    } = CONFIG;
    const {
      KNOWLEDGE_ENTITY_ACTIONS: {
        DELETE,
        UNPUBLISH,
        ARCHIVE
      }
    } = CONSTANTS;
    if (entity) {
      const id = entity.id;

      try {
        if (source === DELETE) {
          deleteKnowledgeAsset(id);
        } else if (source === UNPUBLISH) {
          unpublishKnowledgeAsset(id);
        } else if (source === ARCHIVE) {
          archiveKnowledgeAsset(id);
        }
        setTimeout(() => {
          searchKnowledge(searchCriteria);
          getMaterialStatus();
        }, LIMIT_SEARCH_KNOWLEDGE_API);
      } catch (error) {
        this.showErrorNotification();
      }
    }
  }
  deleteHandler = (event, entity) => {
    const {
      KNOWLEDGE_ENTITY_ACTIONS: {
        DELETE
      }
    } = CONSTANTS;
    this.actionHandler(event, DELETE, entity);
  };
  unpublishHandler = (event, entity) => {
    if (event) {
      event.stopPropagation()
    }
    console.log('unpublishHandler');
    this.setState({ isUnPublishSuccess: true });
    const {
      KNOWLEDGE_ENTITY_ACTIONS: {
        UNPUBLISH
      }
    } = CONSTANTS;

    const {
      searchCriteria: {
        status,
        pageNumber,
        pageSize
      },
      knowledgeEntities
    } = this.props;

    const {
      MY_NAVIGATOR: {
        STATUS_FILTERS: {
          ALL
        },
        SORT_FILTERS: {
          DATE_DESC
        }
      },
    } = LABELS;
    const { id: kid, entityType, isBundles, isCollection } = this.getEntityData(entity);
    const itemRanking = knowledgeEntities.map((item) => (item.id)).indexOf(kid);
    const statusFilter = status === "" ? ALL : status;
    const newAdobeData = {
      page: {
        pageInfo: {
          pageName: PAGES.MY_NAVIGATOR,
          pageURL: getmyCurrentLocation(),
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.MY_NAVIGATOR
        }
      },
      collection: {
        myTrigger: TRIGGERS.UNPUBLISH,
        screenName: SCREEN_NAMES.MY_CONTRIBUTIONS,
        ID: isBundles || isCollection ? kid : ""
      },
      KCpage: {
        id: isBundles || isCollection ? "" : kid
      },
      preFilterTerm: statusFilter,
      preOrderTerm: `${DATE_DESC} | ${entityType}`,
      searchResultRanking: ((pageNumber - 1) * pageSize) + itemRanking + 1
    };
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.MY_CREATION_ACTIONS);
    this.actionHandler(event, UNPUBLISH, entity);

  }

  unpublishCardClickAnalytics = (entity) => {
    const { id, isBundles, isCollection } = this.getEntityData(entity);
    const { KB, KP, MY_CONTRIBUTIONS } = SCREEN_NAMES;
    const { PA_RECOMMENDED, NOT_PA_RECOMMENDED } = MATERIAL_STATUS;
    const newAdobeData = {
      page: {
        pageInfo: {
          pageName: PAGES.MY_NAVIGATOR,
          pageURL: getmyCurrentLocation(),
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.MY_NAVIGATOR
        }
      },
      collection: {
        myTrigger: (isBundles || isCollection ? TRIGGERS.MY_KN_KB_VIEW_PAGE : TRIGGERS.MY_KN_KP_VIEW_PAGE),
        screenName: MY_CONTRIBUTIONS,
        ID: isBundles || isCollection ? id : ""
      },
      KCpage: {
        id: isBundles || isCollection ? "" : id
      },
      resultType: (isBundles || isCollection ? KB : KP),
      materialPAStatus: isBundles || isCollection ? "" : entity.paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED,
    };
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.VIEW_MATERIAL_PAGE);
  }

  previewHandler = (event, entity) => {
    if (event) {
      event.stopPropagation()
    }
    const {
      KNOWLEDGE_STATUS: {
        UNPUBLISHED
      }
    } = CONFIG;
    const { url } = this.getEntityData(entity);
    if (entity.status === UNPUBLISHED) {
      this.unpublishCardClickAnalytics(entity);
    }
    else {
      const { id } = entity;
      this.actionAnalytics(id, TRIGGERS.PREVIEW);
    }
    openURL(url, true);
  };

  archiveHandler = (event, entity) => {
    const {
      KNOWLEDGE_ENTITY_ACTIONS: {
        ARCHIVE
      }
    } = CONSTANTS;
    this.actionHandler(event, ARCHIVE, entity);
  };

  searchSubmitHandler = (searchTerm) => {
    const {
      mynavigatorActions: {
        searchKnowledge
      },
      searchCriteria,
      getMaterialStatus
    } = this.props;

    const newAdobeData = {
      page: {
        pageInfo: {
          pageName: PAGES.MY_NAVIGATOR
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.MY_NAVIGATOR
        }
      },
      search: {
        type: TRIGGERS.MY_CONTRIBUTION,
        term: searchTerm,
        searchTrigger: TRIGGERS.MY_CONTRIBUTION
      }
    };
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.INTERNAL_SEARCH);

    searchCriteria.text = searchTerm;
    searchKnowledge(searchCriteria);
    getMaterialStatus();
  };

  getEntityData = (entity) => {
    const {
      searchCriteria: {
        searchType
      }
    } = this.props;
    const {
      KNOWLEDGE_TYPE_KEYS: {
        BUNDLES,
        COLLECTIONS
      },
      UI_URL: {
        KP_CONSUMPTION,
        KB_CONSUMPTION
      }
    } = CONFIG;
    const {
      MY_NAVIGATOR: {
        ENTITY_TYPES: {
          KB,
          KP,
          TC
        }
      },
    } = LABELS;

    const isBundles = (searchType && searchType === BUNDLES);
    const isCollection = (searchType && searchType === COLLECTIONS);
    const entityType = isBundles ? KB : isCollection ? TC : KP;

    let id = null, url = '';
    if (entity) {
      id = entity.id;
      url = isBundles || isCollection ? KB_CONSUMPTION(id) : KP_CONSUMPTION(id);
    }
    const temp = { entityType: entityType, id: id, url: url, isBundles: isBundles, isCollection: isCollection };
    return temp;
  }

  actionAnalytics = (id, trigger) => {
    const {
      searchCriteria,
      knowledgeEntities
    } = this.props;
    const {
      status,
      pageNumber,
      pageSize
    } = searchCriteria;
    const {
      KNOWLEDGE_TYPE_KEYS: {
        PAGES
      }
    } = CONFIG;
    const {
      MY_NAVIGATOR: {
        STATUS_FILTERS: {
          ALL
        },
        SORT_FILTERS: {
          DATE_DESC
        }
      },
    } = LABELS;

    const { entityType, isBundles, isCollection } = this.getEntityData(null);
    const itemRanking = knowledgeEntities?.map((item) => (item.id)).indexOf(id);
    const statusFilter = status === "" ? ALL : status;

    const newAdobeData = {
      page: {
        pageInfo: {
          pageName: PAGES.MY_NAVIGATOR
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.MY_NAVIGATOR
        }
      },
      collection: {
        screenName: SCREEN_NAMES.MY_CONTRIBUTIONS,
        ID: isBundles || isCollection ? id : "",
        myTrigger: trigger || TRIGGERS.EDIT
      },
      KCpage: {
        id: !isBundles || isCollection ? id : ""
      },
      preFilterTerm: statusFilter,
      preOrderTerm: `${DATE_DESC} | ${entityType}`,
      searchResultRanking: ((pageNumber - 1) * pageSize) + itemRanking + 1
    };
    ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.MY_CREATION_ACTIONS);
  }

  appendPillToUrl = (pill) => {
    const { MYNAVIGATOR_TABS: { CONTRIBUTIONS } } = CONSTANTS;
    history.push({
      pathname: CONFIG.UI_URL.HOME,
      search: `?tabselected=${CONTRIBUTIONS}&pill=${pill}`
    });
  };

  statusFilterHandler = (status) => {
    const {
      mynavigatorActions: {
        changeStatusFilter
      },
      searchCriteria,
      getMaterialStatus
    } = this.props;

    const {
      MY_NAVIGATOR: {
        STATUS_FILTERS: {
          ALL,
          UNPUBLISHED,
        }
      },
    } = LABELS;
    const {
      KNOWLEDGE_STATUS: {
        WITHDRAW,
      }
    } = CONFIG;
    let updatedStatus = status;
    if (status === WITHDRAW) {
      updatedStatus = UNPUBLISHED;
    } else if (status === "") {
      updatedStatus = ALL;
    }

    if (status === 'Draft') {
      this.appendPillToUrl('draft');
    } else if (status === 'Withdraw') {
      this.appendPillToUrl('unpublish');
    } else if (status === 'Published') {
      this.appendPillToUrl('publish');
    } else if (status === 'Archived') {
      this.appendPillToUrl('archived');
    } else if (status === 'Review') {
      this.appendPillToUrl('underreview');
    } else if (status === 'In Queue') {
      this.appendPillToUrl('inqueue');
    }

    if (searchCriteria.status !== status) {
      const newAdobeData = {
        page: {
          pageInfo: {
            pageName: PAGES.MY_NAVIGATOR
          },
          category: {
            primaryCategory: PRIMARY_CATEGORY.MY_NAVIGATOR
          }
        },
        collection: {
          screenName: SCREEN_NAMES.MY_CONTRIBUTIONS
        },
        selectedDisplayOption: updatedStatus
      };
      ANALYTICS.kc.sendEventData(newAdobeData, DTMRULE.DISPLAY_OPTION_SELECTION);
      searchCriteria.status = status;
      searchCriteria.pageNumber = 1;
      changeStatusFilter(searchCriteria);
      getMaterialStatus();
    }
  };
  cloneSubmitCallback = (id) => {
    // Adobe Analytics event261 - clone KP
    ANALYTICS.kc.sendEventData({
      page: {
        pageInfo: {
          pageName: PAGES.MY_NAVIGATOR
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.MY_NAVIGATOR
        }
      },
      KCpage: {
        id: id
      }
    }, DTMRULE.CLONE_BUTTON_CLICK);
  }
  closeCloneModalCallback = (id) => {
    // Adobe Analytics event262 - clone cancel click
    ANALYTICS.kc.sendEventData({
      page: {
        pageInfo: {
          pageName: PAGES.MY_NAVIGATOR
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.MY_NAVIGATOR
        }
      },
      KCpage: {
        id: id
      }
    }, DTMRULE.CLONE_CANCEL_BUTTON_CLICK);
  }
  routeToContribution = () => {
    const { MYNAVIGATOR_TABS: { CONTRIBUTIONS } } = CONSTANTS;
    this.setState({ isUnPublishSuccess: false }, () => {
      routeToInQueueStatus(history);
    });
  }

  addDownloadDetailsInKnowledgeEntities = () => {
    const {
      knowledgeEntities,
      downloadCountData
    } = this.props;
    let data = copyObject(knowledgeEntities);
    // adding download details in knowledge entities list
    if (downloadCountData && downloadCountData.length > 0 && data.length > 0) {
      data = data.map((entity, i) => {
        const downloadIndex = downloadCountData.findIndex((ele) => ele.documentId === entity.id)
        return downloadIndex !== -1 ? Object.assign({}, entity, downloadCountData[downloadIndex]) : entity
      })
    }
    return data;
  }
  render() {
    const {
      knowledgeEntities,
      recordStats,
      searchCriteria,
      allEntitlements,
      isViewEntitlement,
      isCreateEditEntitlement,
      isPublishContentEntitlement,
      isEditPublishedEntitlement,
      isSiteAdmin,
      isContributionLoading,
      downloadCountData,
      getMaterialsStatusData
    } = this.props;
    const { isUnPublishSuccess } = this.state;


    const { status, searchType } = searchCriteria;
    const {
      KNOWLEDGE_ENTITY_ACTIONS: {
        DELETE,
        EDIT,
        PREVIEW,
        ARCHIVE,
        UNPUBLISH
      }
    } = CONSTANTS;
    const {
      IS_ARCHIVE_VISIBLE
    } = CONFIG;
    const areAllEntitlementsFalse = checkAllEntitlementValue(allEntitlements, false);
    const { searchResult } = recordStats;
    const { pageNumber, pageSize } = searchCriteria;

    const entitiesToShow =
      status === "In Queue" ? getMaterialsStatusData : knowledgeEntities;
    const showPagination = status !== "In Queue";
    return (
      !areAllEntitlementsFalse && isViewEntitlement &&
      <>
        <div className="mycontribution__content">
          <div className="mycontribution__filters">
            <KnowledgeFilters
              selectedStatus={status}
              selectedType={searchType}
              recordStats={recordStats}
              onStatusClick={this.statusFilterHandler}
              onTypeChange={this.typeFilterHandler}
              searchTerm={searchCriteria.text}
              onSearchSubmit={this.searchSubmitHandler}
              isArchiveVisible={IS_ARCHIVE_VISIBLE}
              isInqueue={getMaterialsStatusData}
            />
          </div>
          {entitiesToShow && entitiesToShow.length > 0 && isArray(entitiesToShow) &&
            <Fragment>
              <KnowledgeListing
                knowledgeEntities={entitiesToShow}
                entityType={searchType}
                unpublishAction={{
                  callBack: this.unpublishHandler,
                  action: UNPUBLISH
                }}
                archiveAction={{
                  callBack: this.archiveHandler,
                  action: ARCHIVE
                }}
                previewAction={{
                  callBack: this.previewHandler,
                  action: PREVIEW,
                }}
                deleteAction={{
                  callBack: this.deleteHandler,
                  action: DELETE,
                }}
                editAction={{
                  action: EDIT,
                  searchCriteria: searchCriteria,
                  knowledgeEntities: entitiesToShow,
                  analyticsCallBack: this.actionAnalytics
                }}
                isEditPublishedEntitlement={isEditPublishedEntitlement}
                isCreateEditEntitlement={isCreateEditEntitlement}
                isPublishContentEntitlement={isPublishContentEntitlement}
                isSiteAdmin={isSiteAdmin}
                isArchiveVisible={IS_ARCHIVE_VISIBLE}
                onUnpublishCardClick
                closeCloneModalCallback={(id) => this.closeCloneModalCallback(id)}
                cloneSubmitCallback={(id) => this.cloneSubmitCallback(id)}
                downloadCountData={downloadCountData}
              />
              {showPagination && (<Pagination
                totalItems={searchResult}
                onChange={this.changePageHandler}
                activePage={pageNumber}
                itemsPerPage={pageSize}
              />
              )}
            </Fragment>
          }
          {(!entitiesToShow || entitiesToShow.length === 0) &&
            <KnowledgeNoResults />
          }
        </div>
        <WarningModal
          heading={KP_NEW_UNPUBLISHED_MODAL_TITLE}
          button1={{
            text: LABELS.BUTTONS.XMC_HOME,
            clickHandler: this.routeToContribution,
            colorClass: "black"
          }}
          isOpen={isUnPublishSuccess && !isContributionLoading}
          onRequestClose={() => { }}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
          iconName={"check"}
        />
        <FullPageLoader isVisible={isContributionLoading} inSection />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  knowledgeEntities: mynavigatorSelectors.getKnowledgePages(state),
  caseOwnerList: caseOwners.getCaseOwnerList(state),
  recordStats: mynavigatorSelectors.getRecordStatistics(state),
  searchCriteria: mynavigatorSelectors.getsearchCriteria(state),
  isContributionLoading: mynavigatorSelectors.getLoading(state),
  downloadCountData: mynavigatorSelectors.getDownloadCount(state),

  allEntitlements: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, ALL),
  isViewEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, VIEW),
  isCreateEditEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, CREATE_EDIT_DRAFT),
  isEditPublishedEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, EDIT_PUBLISHED_CONTENT),
  isPublishContentEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PUBLISH_CONTENT),
  isSiteAdmin: entitlementSelectors.checkSiteAdmin(state),
  getMaterialsStatusData: materialsStatusSelectors.getMaterialsStatusData(state),
});

const mapDispatchToProps = (dispatch) => ({
  checkSiteAdmin: () => dispatch({ type: ENTITLEMENTS.CHECK_SITE_ADMIN }),
  deleteKnowledgeAsset: (id) => dispatch({ type: KNOWLEDGEOBJECT_VERSIONING.DELETE_KNOWLEDGE_ASSET, payload: { knowledgeId: id, isContributionPage: true } }),
  unpublishKnowledgeAsset: (id) => dispatch({ type: KNOWLEDGEOBJECT_VERSIONING.UNPUBLISH_KNOWLEDGE_ASSET, payload: { knowledgeId: id, isContributionPage: true } }),
  archiveKnowledgeAsset: (id) => dispatch({ type: KNOWLEDGEOBJECT_VERSIONING.ARCHIVE_KNOWLEDGE_ASSET, payload: { knowledgeId: id, isContributionPage: true } }),
  mynavigatorActions: bindActionCreators({ ...mynavigatorActions }, dispatch),
  downloadCounts: (kpids) => dispatch(getDownloadsCount(kpids)),
  getMaterialStatus: () => dispatch({ type: MATERIALS_STATUS.GET_MATERIALS_STATUS, payload: { kpIds: [], type: "" } }),
});

export const MyContribution = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyContributionContainer);

export { MyContributionContainer };