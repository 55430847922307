
import './FaceCardSlideListingv1.scss'
import React, { useState } from 'react'
import LABELS from 'labels';
import Carousel from "components/shared/Carousel/Carousel";

export const AddCarouselWrapper = ({ slidesCount, children, carouselConfig, currentSlide }) => {

    return (
        slidesCount > 1 ? (
            <>
                <div className="faceCardContainerv1__sliderCounter">
                    {currentSlide}/{slidesCount}
                </div>
                <Carousel
                    config={carouselConfig}
                    hideButtonText
                    className="faceCardContainerv1__slider"
                >
                    {children}
                </Carousel>
            </>
        ) : (
            <>{children}</>
        )
    );
};


const FaceCardSlideListingv1 = ({ isTitleShow, isLocationShow, isNameShow, dividedSlides, startImageUrl, endImageUrl, isSlideTitle, selectedShape, isGreyScaleChecked, isSquareShape, showShapeSelector }) => {
    const { FACE_CARD_SLIDE } = LABELS.FACE_CARD_GENERATOR;
    const [isGrey] = React.useState(isGreyScaleChecked);
    const [isSliderShape] = React.useState(isSquareShape);
    const [currentSlide, setCurrentSlide] = useState(1);
    const customCarouselConfig = {
        dots: false,
        afterChange: (current) => {
            setCurrentSlide(current + 1);
        },
        beforeChange: (current, next) => {
            setCurrentSlide(next + 1);
        },
    };
    return (
        <div className="faceCardContainerv1__slider">
            <AddCarouselWrapper slidesCount={dividedSlides.length}
                carouselConfig={customCarouselConfig}
                currentSlide={currentSlide}
            >

                {dividedSlides.map((slide, index) => {
                    return (
                        <div className='faceCardContainerv1' key={"main" + slide[index]?.hrid}>
                            {!isSlideTitle && <p className='faceCardContainerv1__title'>{FACE_CARD_SLIDE}</p>}
                            {isSlideTitle && <p className='faceCardContainerv1__title'>{isSlideTitle}</p>}
                            <div className='faceCardContainerv1__slide'>
                                {slide?.length > 0 && slide.map((personData) => {
                                    const { name, title, image, location, hrid } = personData;
                                    return (
                                        <div className='faceCardContainerv1__slide__item' key={hrid}>
                                            <div className={`faceCardContainerv1__slide__item__image ${showShapeSelector? (isSliderShape ? "faceCardContainerv1__slide__item__image-squareSlide" : "faceCardContainerv1__slide__item__image-circleSlide") : selectedShape} ${isGrey ? " faceCardContainerv1__slide__item__image-greyscale" : ""}`}>
                                                <img src={startImageUrl + image + endImageUrl} />
                                            </div>
                                            {isNameShow && <p className='faceCardContainerv1__slide__item__name'>{name}</p>}
                                            {isTitleShow && <p className='faceCardContainerv1__slide__item__title'>{title}</p>}
                                            {isLocationShow && <p className='faceCardContainerv1__slide__item__location'>{location}</p>}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })
                }
            </AddCarouselWrapper>
        </div>

    )
}

export default FaceCardSlideListingv1