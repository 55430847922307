import React, { useState, useEffect } from "react";
// Prop types
import PropTypes from "prop-types";
// Css
import "./GenAiButton.scss";
import LABELS from "labels";
import crossIcon from "assets/images/cross/crossBlack.svg";
import genAIIcon from "assets/images/ai_icon.svg";
import arrowIcon from "assets/images/arrow-paginantion.svg";

import genAIIconGrey from "assets/images/ai-icon-grey.svg";
import Image from "components/shared/Image";
import { ThumbsUpDown } from 'components/ThumbsUpDown';

const GenAiButton = ({
  isDisable,
  isDescInput,
  suggestions,
  updateCallback,
  thumbsUpCallback,
  title,
  subTitle,
  popupHeading
}) => {
  const [isVisiblePopup, setIsVisiblePopup] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lastSelectedTitle, setLastSelectedTitle] = useState(null);

  useEffect(() => {
    const handleCloseModals = (event) => {
      if (event.detail && event.detail.type === 'ai' || event.detail.type === 'nudge') {
        setIsVisiblePopup(false);
      }
    };
    window.addEventListener('closeModals', handleCloseModals);

    return () => {
      window.removeEventListener('closeModals', handleCloseModals);
    };
  }, []);

  const handleGenAIButtonClick = () => {
    const closeModalsEvent = new CustomEvent('closeModals', { detail: { type: 'ai' } });
    window.dispatchEvent(closeModalsEvent);
    setIsVisiblePopup(!isVisiblePopup);
  }
  const crossIconClick = () => {
    setIsVisiblePopup(false);
  }
  const suggestionClickHandler = (index) => {
    const selectedSuggestion = suggestions[index];
    if (selectedSuggestion !== lastSelectedTitle) {
      updateCallback(selectedSuggestion, index); 
      setLastSelectedTitle(selectedSuggestion);
    }
    setIsVisiblePopup(false);

  };
  return (
    <div className={`genaibutton__wrap ${isDescInput ? 'genaibutton__wrap--description' : ''}`}>
      <button disabled={isDisable} data={"AI Suggestions"} className={`genaibutton ${isDisable ? 'disable' : ''}`} title={title} onClick={handleGenAIButtonClick}>
        <Image src={!isDisable ? genAIIcon : genAIIconGrey} alt="genai icon" />
      </button>

        <div className={`genaipopup ${!isVisiblePopup ? "genaipopup__hide": ""}`}>
          <div className="genaipopup__heading" data={popupHeading}>
            <Image className="genaipopup__heading--icon" src={!isDisable ? genAIIcon : genAIIconGrey} alt="genai icon" />
          </div>
          <div className="genaipopup__subtext">{subTitle}</div>
          {isDescInput ?
            <div className="genaipopup__suggestionsdesc">
              {suggestions && suggestions.length && (
                <>
                  <div
                    className="genaipopup__suggestionsdesc--ele"
                    onClick={() => suggestionClickHandler(selectedIndex)}
                    dangerouslySetInnerHTML={{ __html: suggestions[selectedIndex] }}
                  >
                  </div>

                  <span className="genaipopup__descbuttons">
                    <span className="genaipopup__descbuttons--ele genaipopup__descbuttons--prev"
                      disabled={selectedIndex === 0}
                      onClick={() => setSelectedIndex(selectedIndex - 1)}
                    >
                      <Image src={arrowIcon} alt="genai icon" />
                    </span>
                    {suggestions.map((data, index) => (
                      <span
                        className={`genaipopup__descbuttons--ele ${selectedIndex === index ? "genaipopup__descbuttons--selected" : ""}`}
                        onClick={() => setSelectedIndex(index)}
                      >
                        {index + 1}
                      </span>
                    ))}
                    <span className="genaipopup__descbuttons--ele"
                      disabled={selectedIndex === suggestions.length - 1}
                      onClick={() => setSelectedIndex(selectedIndex + 1)}
                    >
                      <Image src={arrowIcon} alt="genai icon" />
                    </span>
                  </span>
                </>
              )}
            </div>
            :
            <ol className="genaipopup__suggestions">
              {suggestions && suggestions.map((data, index) => (
                <li
                  className={`genaipopup__suggestions--ele`}
                  key={index}
                  onClick={() => suggestionClickHandler(index)}
                >
                  {data}
                </li>
              ))}
            </ol>
          }

          <Image
            className="genaipopup__crossicon"
            src={crossIcon}
            alt="cross"
            onClick={crossIconClick}
          />
          <div className="genaipopup__feedback">
          <a href={process.env.REACT_APP_RAI_POLICY}
            target="_blank"
            rel="noopener noreferrer"
            className="genaipopup__rai-policy">
              {LABELS.GEN_AI_KP.RAI_POLICY}
          </a>            
          <span className="genaipopup__feedback-heading">{"Is this helpful?"}</span>
            <ThumbsUpDown
              isThumbsUpDownV2
              finalSuccess={thumbsUpCallback}
              customClass="kpstep1__thumbsupdown"
            />
          </div>
        </div>

    </div>
  )
};

// GenAiButton Prop types
GenAiButton.propTypes = {
  isDisable: PropTypes.bool,
  isDescInput: PropTypes.bool,
  thumbsUpCallback: PropTypes.func,
  suggestions: PropTypes.array,
  updateCallback: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  popupHeading: PropTypes.string
};

GenAiButton.defaultProps = {
  isDisable: false,
  isDescInput: false,
  suggestions: [],
  updateCallback: () => { },
  thumbsUpCallback: () => { },
  title: "",
  subTitle: "",
  popupHeading: ""
}

// Export GenAiButton as default
export default GenAiButton;


