import { FCG_NEW } from "redux/constants";
export const name = "faceCardGeneratorv1";

export const initialState = {
    requestId: {
        loading: false,
        error: false,
        id: ""
    },
    pollingAPI: {
        loading: false,
        error: false,
        data: {}
    },
    pollingImageAPI: {
        loading: false,
        error: false,
        data: {}
    },

    peopleData: [],
    peoplePayloadData: null
};

// reducer selectors
export const selectors = {
    getRequestLoading: state => state[name].requestId.loading,
    getRequestError: state => state[name].requestId.error,
    getRequestID: state => state[name].requestId?.id,

    getPollingLoading: state => state[name].pollingAPI.loading,
    getPollingError: state => state[name].pollingAPI.error,
    getPollingData: state => state[name].pollingAPI.data,
    getPresignedURL: state => state[name].pollingAPI?.data?.slideUrl,

    getPeopleData: state => state[name].peopleData,
    getPeoplePayloadData: state => state[name].peoplePayloadData,

    getPollingImageLoading: state => state[name].pollingImageAPI.loading,
    getPollingImageError: state => state[name].pollingImageAPI.error,
    getPollingImageData: state => state[name].pollingImageAPI.data,
};


export function reducer(state = initialState, action) {
    const { requestId, pollingAPI, pollingImageAPI } = initialState;

    switch (action.type) {
        case FCG_NEW.FC_REQUEST_API_PENDING:

            return {
                ...state,
                requestId: {
                    ...requestId,
                    loading: true,
                }
            };
        case FCG_NEW.FC_REQUEST_API_SUCCESS:
            return {
                ...state,
                requestId: {
                    ...requestId,
                    id: action.requestId,
                    loading: false
                }
            };
        case FCG_NEW.FC_REQUEST_API_FAILURE:
            return {
                ...state,
                requestId: {
                    ...requestId,
                    error: action.payload.error,
                    loading: false
                }
            };


        case FCG_NEW.FC_POLLING_API_PENDING:
            return {
                ...state,
                pollingAPI: {
                    ...pollingAPI,
                    loading: true
                }
            };
        case FCG_NEW.FC_POLLING_API_SUCCESS:
            return {
                ...state,
                pollingAPI: {
                    ...pollingAPI,
                    data: action.payload
                }
            };
        case FCG_NEW.FC_POLLING_API_FAILURE:
            return {
                ...state,
                pollingAPI: {
                    ...pollingAPI,
                    error: action.payload.error,
                }
            };



        case FCG_NEW.FC_SLIDE_PEOPLE_DATA:
            return {
                ...state,
                peopleData: action.payload
            };
        case FCG_NEW.GET_FC_SLIDE_DATA:
            return {
                ...state,
                peoplePayloadData: action.payload
            };

        case FCG_NEW.FC_POLLING_IMAGES_PENDING:
            return {
                ...state,
                pollingImageAPI: {
                    ...pollingImageAPI,
                    loading: true
                }
            };
        case FCG_NEW.FC_POLLING_IMAGES_SUCCESS:
            return {
                ...state,
                pollingImageAPI: {
                    ...pollingImageAPI,
                    data: action.payload
                }
            };
        case FCG_NEW.FC_POLLING_IMAGES_FAILURE:
            return {
                ...state,
                pollingImageAPI: {
                    ...pollingImageAPI,
                    error: action.payload.error,
                }
            };

        default:
            return state;
    }
};