import LABELS from "labels";
const { KB_FORM_PLACEHOLDERS: { KB_ASSETS_VALIDATION_ONLYTEXT } } = LABELS;
const { BUTTONS: { NEXT, BACK, PUBLISH, PREVIEW_BUNDLE, SEND_REVIEW } } = LABELS;

// Config
const CONFIG = {
  footerWorkflow: [

    {
      align: "left",
      icon: "arrow left",
      iconAlign: "pre",
      id: "btn-back",
      classname: "footer-workflow__link footer-workflow__button--back",
      text: BACK,
      link: "",
      outFilled: true,
      target: false,
      visible: false,
      disabled: false,
      children: [],
      isClick: true,
    }, {
      text: NEXT,
      align: "right",
      type: "button",
      classname: "footer-workflow__btn footer-workflow__button--next",
      id: "btn-next",
      icon: "arrow right",
      iconAlign: "post",
      outFilled: false,
      visible: true,
      disabled: true,
      isClick: true,
      children: [],
      tooltip: KB_ASSETS_VALIDATION_ONLYTEXT
    },
    {
      align: "right",
      children: [
        {
          order: 0,
          text: PREVIEW_BUNDLE,
          id: "btn-previewbundle",
          outFilled: true,
          target: true,
          classname: "footer-workflow__link footer-workflow__button--preview-collection",
          disabled: false,
          visible: false,
          isClick: true,
        },
        {
          type: "submit",
          order: 1,
          id: "btn-publish",
          text: PUBLISH,
          classname: "footer-workflow__btn footer-workflow__button--publish",
          disabled: true,
          visible: false,
          isClick: true,
          tooltip: '',
        },
        {
          type: "submit",
          order: 3,
          id: "btn-review",
          text: SEND_REVIEW,
          classname: "footer-workflow__btn footer-workflow__button--publish",
          disabled: true,
          visible: false,
          isClick: true,
        },
      ],
    },
  ],

  defaultMetaData: {
    title: "",
    description: "",
    coverImage: { id: "", name: "" },
    createdBy: {
      hrid: "",
    },
    topicPages: [
      {
        topicId: "",
        topicName: "",
      },
    ],
    bundleType: {
      id: ""
    },
    knowledgeObjects: [],
    relatedLinks: [],
    createdDate: "",
    updatedDate: "",
    status: "draft",
    originalRequestor: null,
  },
  MANDATORY_FIELDS: {
    COLLECTION_TITLE: "COLLECTION_TITLE",
    COLLECTION_TITLE_MIN_LENGTH: "COLLECTION_TITLE_MIN_LENGTH",
    ORIGINAL_REQUESTOR_FIELD: "ORIGINAL_REQUESTOR_FIELD",
    DESCRIPTION: "DESCRIPTION",
    COVER_IMAGE: "COVER_IMAGE",
    IPA_FPA: "IPA_FPA",
    IPA_FPA_CUSTODIAN: "IPA_FPA_CUSTODIAN",
    SUBJECT: "SUBJECT",
    CONTRIBUTORS: "CONTRIBUTORS",
    CREATED_BY: "CREATED_BY",
    TOPICPAGES: "TOPICPAGES",
  },
  style: { top: "16px", position: "absolute", right: "20px" },
};

// export default
export default CONFIG;
