import React from "react";
import { connect } from "react-redux";
import { Layout } from "containers/Layout";
import { selectors as globalSelectors } from "redux/reducers/global/global_reducer";
import { FaceCardGeneratorv1 } from "containers/FaceCardGeneratorv1/FaceCardGeneratorv1";
import FcgTemplate from "components/FcgTemplate/FcgTemplate";
import './FaceCardWrapper.scss';
import { selectors as caseConsumptionSelectors } from "redux/reducers/caseConsumption/caseConsumption_reducer";
import { selectors as fcgSelectors } from "redux/reducers/faceCardGeneratorv1/faceCardGeneratorv1_reducer";
import ProposalBioContainer from "containers/ProposalBioContainer/ProposalBioContainer";
import { Helmet } from "react-helmet";
import {
	PAGES,
	PRIMARY_CATEGORY
} from "utils/analytics/analytics_constants";
import ANALYTICS from "utils/analytics/analytics";
import CONFIG from "config";
import { CASE_METADATA } from "redux/constants";
import LABELS from "labels";
const {
	GET_CASE_METADATA,
} = CASE_METADATA;

const { PROPOSAL_TEMPLATES, ONE_SLIDER } = CONFIG;

const templates = [
	[
		{ id: "circle2x5", rows: 2, columns: 5, shape: 'circle', label: 'Circle 2X5' },
		{ id: "square2x5", rows: 2, columns: 5, shape: 'square', label: 'Square 2X5' },
		{ id: "1-slider", rows: 5, columns: 12, shape: 'circle', label: '1-Slider' },
	],
	PROPOSAL_TEMPLATES
];
class FaceCardWrapperContainer extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			templateId: false,
		}
	}
	componentDidUpdate(prevProps, prevState) {
		const {
			match: {
				params: { templateid },
			}
		} = this.props;
		const { match: {
			params: { templateid: prevTemplateid },
		} } = prevProps;

		if (prevTemplateid !== templateid) {
			this.setState({ templateId: !!templateid ? templateid : false });
		}
	}
	componentDidMount() {
		const {
			match: {
				params: { templateid },
			}
		} = this.props;
		this.setState({ templateId: !!templateid ? templateid : false });
		ANALYTICS.page.initialize({
			pageName: PAGES.FACE_CARD_GENERATOR,
			category: PRIMARY_CATEGORY.FACE_CARD_GENERATOR,
			trackPageLoad: true,
		});
	}
	getTemplate = (templateId) => {
		if (!templateId) return false;
		const flatTemplates = templates.flat();

		const defaultTemplate = flatTemplates.find((template) => template.id === templateId);
		return defaultTemplate;
	}
	handleTemplateClick = (template) => {
		const { history } = this.props;
		const {
			UI_URL: {
				FACE_CARD_GENERATOR_V1
			}
		} = CONFIG;
		history.push({
			pathname: FACE_CARD_GENERATOR_V1(false, template.id),
			state: { mode: history.location?.state?.mode }
		});
	};
	handleClose = () => {
		const { history } = this.props;
		const {
			UI_URL: {
				FACE_CARD_GENERATOR_V1
			}
		} = CONFIG;

		history.push({
			pathname: FACE_CARD_GENERATOR_V1(true),
			state: { mode: history.location?.state?.mode }
		});
	};

	render() {
		const { userDetails, getLoading, isPollingError, isRequestError, caseDetails, peopleData, peoplePayloadData, getCaseDetails, history } = this.props;
		const { templateId } = this.state;
		const {
			PAGETITLE: {
				FACE_CARD_GENERATOR
			}
		} = LABELS;
		const defaultTemplate = this.getTemplate(templateId);
		const isProposalBio = ["proposalbiow", "proposalbiog", "proposalbio"].includes(templateId);
		const showProposalModal = templateId == "proposalbio";
		return (
			<>
				<Helmet>
					<title>{FACE_CARD_GENERATOR}</title>
				</Helmet>
				<Layout
					isHeaderShow={false}
					isFooterShow={false}
					userDetails={userDetails}
					isNewFCGHeader
					isFCGLogoClicakble={!!defaultTemplate}
					customClass="facecardgeneratorv1">
					<div className="faceCardGeneratorv1__toggleTemplateForm">
						{isProposalBio ?
							<ProposalBioContainer
								selectedTemplateTitle={defaultTemplate?.label}
								handleClose={this.handleClose}
								templateId={templateId}
								showModal={showProposalModal}
								history={history}
							/>
							:
							<>
								{!defaultTemplate ? (
									//STEP 1
									<div className='facecardwrapper__step1'>
										{templates.map((templateObj, rowIndex) => (
											templateObj.length > 0 && (
												<FcgTemplate
													onTemplateClick={this.handleTemplateClick}
													templateObj={templateObj}
													rowIndex={rowIndex}
												/>
											)
										))}
									</div>
								) : (
									//STEP 2
									<FaceCardGeneratorv1
										defaultTemplate={defaultTemplate}
										userDetails={userDetails}
										getLoading={getLoading}
										isPollingError={isPollingError}
										isRequestError={isRequestError}
										caseDetails={caseDetails}
										peopleData={peopleData}
										peoplePayloadData={peoplePayloadData}
										getCaseDetails={getCaseDetails}
										handleClose={this.handleClose}
									/>
								)}
							</>
						}
					</div>
				</Layout>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userDetails: globalSelectors.getUserDetails(state),
		getLoading: fcgSelectors.getRequestLoading(state) || fcgSelectors.getPollingLoading(state) || fcgSelectors.getPollingImageLoading(state),
		isPollingError: fcgSelectors.getPollingError(state),
		isRequestError: fcgSelectors.getRequestError(state),
		caseDetails: caseConsumptionSelectors.getCaseDetails(state),
		peopleData: fcgSelectors.getPeopleData(state),
		peoplePayloadData: fcgSelectors.getPeoplePayloadData(state),
	};
};
const mapDispatchToProps = (dispatch) => ({
	getCaseDetails: (cid) => dispatch({ type: GET_CASE_METADATA, payload: { cid, isConsumption: true, isFcg: true } })
});

export const FaceCardWrapper = connect(
	mapStateToProps,
	mapDispatchToProps
)(FaceCardWrapperContainer);

export { FaceCardWrapperContainer }

