import React from 'react';
import { connect } from 'react-redux';
// Semantic UI
import { PeopleSelector } from "components/PeopleSelector";
import { WarningModal } from "components/WarningModal/WarningModal";
import { CASE_METADATA } from "redux/constants";
import SearchBox from "components/shared/SearchBox/SearchBox";
import ToggleSwitch from "components/shared/ToggleSwitch/ToggleSwitch";
import { Button } from 'components/shared/Button';
import Checkbox from "components/shared/Checkbox";
import CaseSearchItem from "components/CaseSearchItem/CaseSearchItem";
import EmailInput from 'components/shared/EmailInput/EmailInput';
import { validateCaseSearch, copyObject, getPeopleApiDataByEmails, getImageNamePreAndPost, getPeopleApiDataByHrids, sortDataByKey } from "utils/helpers/helpers";
import LABELS from 'labels';
import { getFaceCardv1SlideData, faceCardv1SlidePeopleData, downloadFileFromCloudFront, downloadImageOnly } from 'redux/actions/faceCardGeneratorv1/faceCardGeneratorv1.actions';
import { selectors as caseMetadataSelectors } from "redux/reducers/caseMetadata/caseMetadata_reducer";
import { selectors as fcgSelectors } from "redux/reducers/faceCardGeneratorv1/faceCardGeneratorv1_reducer";
import { selectors as caseConsumptionSelectors } from "redux/reducers/caseConsumption/caseConsumption_reducer";
import { bindActionCreators } from "redux";
import { actions as peopleResultsActions } from 'redux/actions/peopleresults/peopleresults_actions';

//////////// Importing Constants & Helper /////////

import FACE_CARD_CONFIG from "containers/FaceCardGeneratorv1/FaceCardv1.config";
import Image from "components/shared/Image";
import LoaderIcon from "assets/images/spinner/spinner-v3.gif";
import closeFcg from "assets/images/fcg_close.svg";
import './FaceCardFormv1.scss';
import CONFIG from 'config';
import { TabComponent } from 'components/shared/TabComponent/TabComponent';
import Counter from 'components/shared/Counter';
import { TRIGGERS } from "utils/analytics/analytics_constants";
import warningIcon from "assets/images/warning/warning.svg";

const {
    METADATA: {
        PEOPLE_SELECTOR_PLACEHOLDER,
        FACECARD_PEOPLE_SELECTOR_EMAIL,
    },
    FACE_CARD_FORM: { NAME, TITLE, LOCAITON, WITH, SELECT_SIZE },
    FACE_CARD_SELECT_TEXTS: { BY_PEOPLE, BY_CASE_NO, BY_EMAIL_ID },
} = LABELS;
const {
    SEARCH_CASES,
    RESET_SEARCH_RESULTS,
} = CASE_METADATA;

const extractEmails = text => text.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g) || [];
const isValidEmail = email => /^[^\s@]+@[^\s@]+\.[a-zA-Z]+$/.test(email);

class FaceCardFormv1 extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {
            isTitleChecked: false,
            isLocationChecked: false,
            isImageOnlyEnabled: false,
            peopleIds: [],
            peopleIdsWithData: [],
            fcgClose: true,
            selectedTemplateTitle: "",
            selectedShape: " ",
            byType: BY_PEOPLE,
            isPreviewDisable: false,
            selectedCase: {},
            isCaseTeamEmpty: true,
            isResetModalOpen: false,
            isLoadingEmailPeople: false,
            prefillEmailIds: [],
            isResetButtonEnabled: false,
            isCaseLoading: false,

            emailIds: '',
            invalidEmails: [],
            emailError: '',

            isPreviewInProgress: false,
            slideValue: "",
            localSlideTitleLength: 0,
            showPollingError: false,
            showImagePollingError: false
        }
    }

    setEmailIds = (emailIds) => {
        this.setState({ emailIds });
    }
    setInvalidEmails = (invalidEmails) => {
        this.setState({ invalidEmails });
    }
    setEmailError = (emailError) => {
        this.setState({ emailError });
    }

    getHridArray = (obj) => {
        let temp = [];
        temp = obj?.map((obj) => obj?.hrid || obj?.id);
        return temp;
    }

    getEmailsArray = (obj) => {
        let temp = [];
        if (obj?.length)
            temp = obj?.map((obj) => obj?.workEmail?.toLowerCase());
        return temp;
    }

    componentDidUpdate(prevProps, prevState) {
        const { caseTeam, isPollingError, isImagePollingError } = this.props;
        const { byType, peopleIds, prefillEmailIds, isLoadingEmailPeople, invalidEmails } = this.state;
        const isLoading = this.props.getLoading || this.state.isCaseLoading || this.props.isLoadingEmailPeople;
        const shouldEnableReset = (
            (this.state.peopleIds.length > 0 ||
                Object.keys(this.state.selectedCase).length > 0 ||
                this.state.prefillEmailIds.length > 0) &&
            !this.state.isPreviewInProgress &&
            !isLoading
        );
        if (shouldEnableReset !== prevState.isResetButtonEnabled) {
            this.setState({ isResetButtonEnabled: shouldEnableReset });
        }
        if (caseTeam !== prevProps.caseTeam && byType === BY_CASE_NO) {
            const hrids = this.getHridArray(caseTeam);
            this.setState({ peopleIds: hrids });
            if (!caseTeam.length && !this.props.isCaseDetailsLoading) {
                this.setState({ isCaseLoading: false });
            }
        }
        if (peopleIds !== prevState.peopleIds) {
            if (byType === BY_CASE_NO && this.state.isCaseTeamEmpty === false) {
                if (peopleIds.length === 0) {
                    this.setState({ isCaseTeamEmpty: true })
                }
                else {
                    this.setState({ isCaseTeamEmpty: false })
                }
            }
            if (byType === BY_PEOPLE) {
                if (peopleIds.length === 0) {
                    this.setState({ isPreviewDisable: true })
                }
                else {
                    this.setState({ isPreviewDisable: false })
                }
            }
        }
        if (byType === BY_EMAIL_ID && (prefillEmailIds !== prevState.prefillEmailIds || isLoadingEmailPeople !== prevState.isLoadingEmailPeople)) {
            if (prefillEmailIds.length === 0) {
                this.setEmailIds('');
                this.setEmailError('');
            } else {
                const finalList = [...prefillEmailIds, ...invalidEmails];
                if (finalList.length) {
                    this.setEmailIds(finalList.join("; "));
                    this.setEmailError('Unable to find ' + finalList.length + ' email ID' + (finalList.length > 1 ? 's' : ''));
                }
            }
        }

        if (isPollingError && isPollingError !== prevProps.isPollingError) {
            this.setState({ showPollingError: true, showImagePollingError: false });
        }

        if (isImagePollingError && isImagePollingError !== prevProps.isImagePollingError) {
            this.setState({ showPollingError: false, showImagePollingError: true });
        }
    }

    handleEmailOusideClick = () => {
        this.setEmailError('');
        this.setInvalidEmails([]);
    }
    handleEmailKeyPress = e => {
        // if (e.key === 'Enter') {
        //   handleEnter();
        // }
    };

    handleEmailInputChange = (event) => {
        const value = event.target.value;
        const extractedEmails = extractEmails(value) || [];
        const validEmails = extractedEmails.filter(isValidEmail);
        const invalidEmails = extractedEmails.filter(email => !isValidEmail(email));
        this.setEmailIds(value);
        this.setEmailError('');
        this.setState({ isPreviewDisable: !(validEmails.length > 0 && invalidEmails.length === 0) });
    };

    handleEnterEmail = () => {
        const { emailIds, peopleIds } = this.state;
        const extractedEmails = extractEmails(emailIds) || [];
        const validEmails = [];
        const invalidEmailsList = [];
        const emailSemicolons = emailIds.includes(';');

        // Separate valid and invalid emails
        extractedEmails.forEach(email => {
            email = email?.toLowerCase();
            if (isValidEmail(email)) {
                validEmails.push(email);
            } else {
                invalidEmailsList.push(email);
            }
        });

        if (invalidEmailsList.length) {
            this.setInvalidEmails(invalidEmailsList);
        }
        if (validEmails.length > 0 || peopleIds.length) {
            if (validEmails.length > 1 && !emailSemicolons && emailIds.indexOf(' ') === -1) {
                this.setEmailError('Please enter valid email IDs separated by semi-colon');
            } else {
                this.handleSubmitEmail(validEmails);
                this.setEmailIds('');
                this.setEmailError('');
            }
        }
        else {
            this.setEmailError('Please enter valid email IDs separated by semi-colon -1');
        }
    };

    handleChange = (hrids) => {
        const { handlePeopleSelectorUpdate, resetCallback } = this.props;
        const { byType, selectedCase, peopleIdsWithData } = this.state;
        try {
            let newPeopleIdsWithData = [];
            if (peopleIdsWithData.length) {
                newPeopleIdsWithData = sortDataByKey(peopleIdsWithData, hrids, 'id');
                newPeopleIdsWithData = newPeopleIdsWithData.filter((person) => hrids.includes(person.id));
            }

            this.setState({ peopleIds: hrids, peopleIdsWithData: newPeopleIdsWithData });
            if (!hrids.length && byType === BY_CASE_NO && Object.keys(selectedCase).length) {
                this.setState({ selectedCase: {} }, () => {
                    if (resetCallback)
                        resetCallback();
                });
            } else if (!hrids.length && resetCallback) {
                resetCallback();
            }
            handlePeopleSelectorUpdate(hrids);

        } catch (error) {
            console.log('Error in handle Change ', error)
        }
    }

    getDynamicPeopleSelectorClass = () => {
        try {
            const { peopleIds } = this.state;

            if (peopleIds?.length === 0) {
                return true;
            }
        } catch (error) {
            console.log('Error in getDynamicPeopleSelectorClass function ', error)
        }

        return false;
    }


    handleFaceCardBtnClick = (id) => {
        const { byType, isImageOnlyEnabled } = this.state;
        try {
            this.setState({ showPollingError: false, showImagePollingError: false }, () => {
                if (id === 'btn-preview') {
                    if (byType === BY_EMAIL_ID) {
                        this.handleEnterEmail();
                    } else {
                        this.handlePreview();
                    }
                } else if (id === 'btn-download') {
                    isImageOnlyEnabled ? this.handleImageOnlyDownload() : this.handleDownload();
                }
            });
        } catch (error) {
            console.log('Error in handleFaceCArdBtnClick ', error);
        }
    }
    handlePreview = async () => {
        const { handlePreviewShow } = this.props;
        const {
            peopleResultsActions: {
                getPeopleDataUsingIDs
            }
        } = this.props;
        const { byType, peopleIdsWithData } = this.state;
        handlePreviewShow(false);
        try {
            const { peopleIds } = this.state;
            const { getFaceCardv1SlideData, faceCardv1SlidePeopleData, isNameChecked, isGreyScaleChecked, isTitleChecked, isLocationChecked, isSquareShape, handleDownloadBtnStatus, handlePreviewClick, userDetails: { preferredFirstName: loginUserFirstName, preferredLastName: loginUserLastName } } = this.props;
            this.setState({ isPreviewDisable: true });
            handlePreviewClick(byType);
            handleDownloadBtnStatus(true);
            let finalData = peopleIdsWithData;
            if (peopleIds?.length > 0) {
                if (byType === BY_PEOPLE) {
                    finalData = await getPeopleApiDataByHrids(peopleIds, getPeopleDataUsingIDs, false, true);
                }
                let startImageUrl = '';
                let endImageUrl = '';
                const payloadPeopleData = finalData?.length > 0 && finalData?.map((personData) => {
                    const { id, preferredFirstName, preferredLastName, businessTitle, picture: imageUrl, hostOfficeLocation: { name } } = personData;
                    const { imageName, domain, policy } = imageUrl ? getImageNamePreAndPost(imageUrl) : '';

                    if (!startImageUrl || !endImageUrl) {
                        startImageUrl = domain || '';
                        endImageUrl = policy || '';
                    }
                    return {
                        hrid: id,
                        name: `${preferredFirstName} ${preferredLastName}`,
                        title: businessTitle,
                        image: imageName,
                        location: name

                    }
                })
                faceCardv1SlidePeopleData(payloadPeopleData);
                const isSingleSlider = this.props.showShapeSelector;
                const payloadData = {
                    appName: "C&M",
                    templateType: "face-card",
                    requestedBy: loginUserFirstName + " " + loginUserLastName, // TODO make it dynamic
                    startImageUrl,
                    endImageUrl,
                    filters: {
                        hasName: isNameChecked,
                        hasTitle: isTitleChecked,
                        hasLocation: isLocationChecked,
                        size: "M", //TODO make it dynamic in future stories
                        grayscale: isGreyScaleChecked,
                        singleSlider: isSingleSlider,
                        shape: this.props.showShapeSelector ? (isSquareShape ? "square" : "circle") : this.props.selectedShape
                    },
                    employees: payloadPeopleData,
                    slideTitle: this.state.slideValue.trim()
                }
                getFaceCardv1SlideData(payloadData);
                this.setState({ isPreviewDisable: false });
                handleDownloadBtnStatus(false)
                handlePreviewShow(true)
            }
        } catch (error) {
            const { handleDownloadBtnStatus } = this.props
            this.setState({ isPreviewDisable: false, isDownloadDisable: false });
            handleDownloadBtnStatus(false)
            console.log('Error in handlePreview ', error)
        }
    }
    handleDownload = () => {
        const { preSignedUrl, triggerPreviewDownloadAnalytics, downloadFileFromCloudFront } = this.props;
        const { byType } = this.state;
        triggerPreviewDownloadAnalytics(TRIGGERS.DOWNLOADS, byType);
        if (preSignedUrl) {
            downloadFileFromCloudFront(preSignedUrl);
        }
    }
    handleImageOnlyDownload = () => {
        console.log("Image only download");
        const { triggerPreviewDownloadAnalytics, userDetails, isSquareShape, isLocationChecked } = this.props;
        const { peopleIds, byType } = this.state;

        triggerPreviewDownloadAnalytics(TRIGGERS.DOWNLOAD_ONLY_IMAGES, byType);
        const nameFormatValue = isLocationChecked
            ? "{hrId}_{name}_{location}"
            : "{hrId}_{name}";

        const payload = {
            appName: "C&M",
            templateType: "image",
            requestedBy: `${userDetails.preferredFirstName} ${userDetails.preferredLastName}`,
            filters: {
                grayscale: this.props.isGreyScaleChecked,
                shape: this.props.showShapeSelector ? (isSquareShape ? "square" : "circle") : this.props.selectedShape
            },
            hrIds: peopleIds,
            nameFormat: nameFormatValue
        };
        this.props.downloadImageOnly(payload);
    };


    updateButtonsConfig = (buttons) => {
        try {
            const { getLoading, isDownloadDisable, isError } = this.props;
            const { peopleIds, isPreviewDisable, isCaseTeamEmpty, byType, isLoadingEmailPeople, emailIds, isImageOnlyEnabled, isCaseLoading } = this.state;
            if (buttons?.length > 0) {
                if ((peopleIds.length === 0) || getLoading) {
                    buttons[0].disabled = true;
                }
                else {
                    buttons[0].disabled = false;
                }
                if (peopleIds.length) {
                    if (isPreviewDisable || getLoading) {
                        buttons[0].disabled = true;
                    }
                    else {
                        buttons[0].disabled = false;
                    }
                }
                if (isDownloadDisable || getLoading || isError) {
                    buttons[1].disabled = true;
                } else {
                    buttons[1].disabled = false;
                }
                if (byType === BY_CASE_NO) {
                    if (isCaseTeamEmpty || peopleIds.length === 0 || getLoading || isPreviewDisable) {
                        buttons[0].disabled = true;
                    }
                    else {
                        buttons[0].disabled = false;
                    }
                }
                if (byType === BY_EMAIL_ID) {
                    if (isLoadingEmailPeople || getLoading || isPreviewDisable || (emailIds.length < 8 && peopleIds.length === 0)) {
                        buttons[0].disabled = true;
                    }
                    else {
                        buttons[0].disabled = false;
                    }
                }
                if (isImageOnlyEnabled) {
                    buttons[0].visible = false;
                    buttons[1].disabled = peopleIds.length === 0 || isCaseLoading;
                }

            }
        } catch (error) {
            console.log('Error in updateButtonsConfig function ', error);
        }
    }
    onTypeChange = (value) => {
        const that = this;
        const { resetCallback } = this.props;
        const { emailIds, prefillEmailIds } = this.state;
        const clearEmailData = (value !== BY_EMAIL_ID) && (this.state.byType === BY_EMAIL_ID);
        this.setState({
            byType: value,
            selectedCase: {},
            isPreviewDisable: false,
            prefillEmailIds: clearEmailData ? [] : prefillEmailIds,
            emailIds: clearEmailData ? '' : emailIds,
            isCaseLoading: false
        }, () => {
            that.setState({
                peopleIds: [],
                peopleIdsWithData: [],
            });
            if (resetCallback)
                resetCallback();
        });
    }

    searchCaseHandler = (keyword) => {
        this.fetchRelatedCases(keyword);
    }
    clearSearchResults = () => {
        const { resetSearchResults } = this.props;
        resetSearchResults();
    }
    handleOnResultClick = (item) => {
        const { id, caseNumber, sanitizedCaseTitle } = item;
        const selectedItem = {
            caseGuid: id,
            caseNumber,
            caseTitle: sanitizedCaseTitle,
        };
        this.setState({
            selectedCase: selectedItem,
            isCaseLoading: true
        });
        const { callBackTypeChange } = this.props;
        if (callBackTypeChange)
            callBackTypeChange(id);
    }

    crossClickCallback = () => {
        const { resetCallback } = this.props;
        this.setState({
            selectedCase: {},
            peopleIds: [],
            peopleIdsWithData: [],
            isCaseTeamEmpty: true,
            showPollingError: false,
            showImagePollingError: false,
            isCaseLoading: false
        }, () => {
            if (resetCallback)
                resetCallback();
        });
    }
    callBackAfterPeopleApiFetch = (data) => {
        const finalPeopleIds = this.getHridArray(data);
        const { byType } = this.state;
        this.setState({
            peopleIds: finalPeopleIds,
            peopleIdsWithData: data,
            isCaseLoading: false,
            isLoadingEmailPeople: false
        }, () => {
            if (finalPeopleIds.length > 0 && byType === BY_EMAIL_ID) {
                this.handlePreview();
            }
        });
    }

    caseTeamWithNoAlumniCallback = (data) => {
        if (!data?.length) {
            this.setState({ isCaseTeamEmpty: true })
        }
        else {
            this.setState({ isCaseTeamEmpty: false })
        }
    }
    fetchRelatedCases = (keyword) => {
        const { searchRelatedCases } = this.props;
        const {
            CASES_SEARCH_PAGINATION: {
                PAGE_SIZE,
                PAGE_NUMBER,
            }
        } = CONFIG;
        const payload = {
            caseNumber: keyword,
            doExactMatch: false,
            pageNumber: PAGE_NUMBER,
            pageSize: PAGE_SIZE,
        };
        searchRelatedCases(payload);
    }
    handleSubmitEmail = async (inputEmailIds) => {
        const {
            peopleResultsActions: {
                getPeopleDataUsingEmails,
            }
        } = this.props;
        const { peopleIds } = this.state;
        const existingPeopleIds = [...peopleIds];
        const sortedPeopleData = inputEmailIds.length ? await getPeopleApiDataByEmails(inputEmailIds, getPeopleDataUsingEmails) : [];
        if (sortedPeopleData.length) {
            this.setState({
                peopleIds: [],
                peopleIdsWithData: [],
                isLoadingEmailPeople: true,
                prefillEmailIds: []
            });

            const hrids = this.getHridArray(sortedPeopleData);
            const emails = this.getEmailsArray(sortedPeopleData);

            let emailsNotPresent = inputEmailIds.filter(x => !emails.includes(x.toLowerCase()));
            const combinePeopleIds = [...existingPeopleIds, ...hrids];
            this.setState({ peopleIds: combinePeopleIds }, () => {
                const that = this;
                setTimeout(() => {
                    that.setState({ prefillEmailIds: emailsNotPresent });
                    // if (!emails.length) {
                    that.setState({ isLoadingEmailPeople: false });
                    // }
                }, 800);
            });
        } else {
            this.setState({ prefillEmailIds: inputEmailIds, isLoadingEmailPeople: false });
            this.handlePreview();
        }
    };

    resetHandler = () => {
        const { handlePreviewShow, handleDownloadBtnStatus, handleErrorStateChange } = this.props;
        handlePreviewShow(false)
        handleDownloadBtnStatus(true);
        handleErrorStateChange();
        this.setState({
            selectedCase: {},
            peopleIds: [],
            peopleIdsWithData: [],
            prefillEmailIds: [],
            isResetModalOpen: false,
        });
    }
    closeResetModal = () => {
        this.setState({
            isResetModalOpen: false
        });
    }
    toggleFaceCardForm = (templateTitle) => {
        this.setState({
            fcgClose: false,
            selectedTemplateTitle: templateTitle,
        });
    };

    handleClose = () => {
        this.setState({
            fcgClose: true,
        });
    };

    onTabClicked = (e, index) => {
        const { BY_PEOPLE, BY_CASE_NO, BY_EMAIL_ID } = LABELS.FACE_CARD_SELECT_TEXTS;

        const indexMap = [
            BY_PEOPLE,
            BY_CASE_NO,
            BY_EMAIL_ID
        ];
        this.onTypeChange(indexMap[index]);
        this.setState({ showPollingError: false, showImagePollingError: false });
    }

    handleSlideTitleChange = (e) => {
        const { handleDownloadBtnStatus } = this.props
        this.setState({ slideValue: e.target.value, localSlideTitleLength: e.target.value.length });
        if (handleDownloadBtnStatus) {
            handleDownloadBtnStatus(true);
        }
    };
    handleDownloadImageOnly = (isImageOnlyEnabled) => {
        const { handleNameChange } = this.props;
        this.setState({
            isImageOnlyEnabled: isImageOnlyEnabled
        }, () => {
            if (isImageOnlyEnabled)
                handleNameChange(true);
        });
    }

    render() {
        const { handleTitleChange, showShapeSelector, handleSliderShapeChange, handleLocationChange, handleNameChange, isNameChecked, handleGreyScaleChange, isTitleChecked, isLocationChecked, searchResults, isSearchingCases, totalSearchResults, getLoading, isPollingError, isImagePollingError } = this.props;
        const buttons = copyObject(FACE_CARD_CONFIG.footerWorkflow);
        const { selectedCase, peopleIds, byType, isLoadingEmailPeople, localSlideTitleLength, isImageOnlyEnabled, isCaseLoading, showPollingError, showImagePollingError } = this.state;
        //Update buttons config
        this.updateButtonsConfig(buttons);
        let typeFilters = [
            { key: BY_PEOPLE, text: BY_PEOPLE, value: BY_PEOPLE },
            { key: BY_CASE_NO, text: BY_CASE_NO, value: BY_CASE_NO },
            { key: BY_EMAIL_ID, text: BY_EMAIL_ID, value: BY_EMAIL_ID }
        ];

        const {
            WORK_SPACE: {
                WARNING_MODAL_CANCEL,
            },
            FACE_CARD_GENERATOR: {
                RESET_BTN_CONFIRM,
                RESET_WARNING_MESSAGE,
                SEARCH_RELATED,
                SEARCH_PLACEHOLDER,
                USE_B_W,
                OTHER_INFO,
                REFINE_SEARCH,
                NO_RESULTS,
            }
        } = LABELS;
        const { CP_CONTRIBUTION_LIMITS: { SEARCH_CASE_CHAR_LIMIT, SEARCH_CASES_LIMIT } } = CONFIG;
        const tabsArray = [
            {
                heading: LABELS.FACE_CARD_SELECT_TEXTS.BY_PEOPLE,
            },
            {
                heading: LABELS.FACE_CARD_SELECT_TEXTS.BY_CASE_NO,
            }
        ];
        if (!isImageOnlyEnabled)
            tabsArray.push({
                heading: LABELS.FACE_CARD_SELECT_TEXTS.BY_EMAIL_ID,
            });
        const {
            FACE_CARD_GENERATOR: { PROFILE_PIC_SHAPE },
        } = LABELS;

        return (
            <>
                <div className="faceCardFormv1__header">
                    <div className="faceCardFormv1__title-wrapper">
                        {!isImageOnlyEnabled &&
                            <div className="faceCardFormv1__title">
                                <h2>{this.props.selectedTemplateTitle || "Default Title"}</h2>
                            </div>
                        }
                        <button className="faceCardFormv1__changeBtn" onClick={this.props.handleClose}>Change Template</button>
                    </div>
                    <button className="faceCardFormv1__closeBtn" onClick={this.props.handleClose}>
                        <span>Close</span>
                        <img src={closeFcg} alt="Close" />
                    </button>
                </div>
                <div className="faceCardFormv1">
                    <div className="faceCardFormv1__content">
                        <div className="faceCardFormv1__slideTitleCheckboxes">
                            <div className="faceCardFormv1__slideTitle">
                                <div className='faceCardFormv1__slideText'>{"Slide Title"}</div>
                                <input
                                    type="text"
                                    className="faceCardFormv1__inputTitle"
                                    placeholder="Enter Slide Title"
                                    value={this.state.slideValue}
                                    onChange={this.handleSlideTitleChange}
                                    maxLength={CONFIG.KP_LIMITS.SEARCH_TAGS_MAXLIMIT}
                                    disabled={isImageOnlyEnabled}
                                />
                                <div className="faceCardFormv1__slideTitleCounter">
                                    <Counter inputLength={localSlideTitleLength} maxLength={CONFIG.KP_LIMITS.SEARCH_TAGS_MAXLIMIT} />
                                </div>
                            </div>
                            {byType !== BY_EMAIL_ID && (
                                <div className="faceCardFormv1__togglebutton faceCardFormv1__togglebutton--imageonly">
                                    <span>{"Download Only Images"}</span>
                                    <ToggleSwitch
                                        onToggle={this.handleDownloadImageOnly}
                                    />
                                </div>
                            )}
                            <div className="faceCardFormv1__togglebutton">
                                <span>{USE_B_W}</span>
                                <ToggleSwitch
                                    onToggle={handleGreyScaleChange}
                                />
                            </div>
                            <div className={`faceCardFormv1__blackWhiteToggle__shapeToggle ${!showShapeSelector ? "hiddenForm" : ""}`}>
                                <span>{PROFILE_PIC_SHAPE}</span>
                                <div className="faceCardFormv1__blackWhiteToggle__shapeToggle__shapeOptions">
                                    <span className="faceCardFormv1__blackWhiteToggle__shapeToggle__shapeOptions__circle">
                                        {"Circle"}
                                    </span>
                                    <ToggleSwitch
                                        onToggle={handleSliderShapeChange}
                                    />
                                    <span className="faceCardFormv1__blackWhiteToggle__shapeToggle__shapeOptions__square">
                                        {"Square"}
                                    </span>
                                </div>
                            </div>
                            {/* <div className="faceCardFormv1__filterrow"> */}
                            <div className={`faceCardFormv1__checkboxTitlee ${showShapeSelector ? "hiddenForm" : ""}`}>
                                <div className="faceCardFormv1__checkboxTitle">
                                    {OTHER_INFO}
                                </div>
                                <div className="faceCardFormv1-checkboxSec">
                                    <div className="faceCardFormv1__leftsec">
                                        <div className="faceCardFormv1-checkboxSec--name">
                                            <Checkbox
                                                onChange={() => handleNameChange(!isNameChecked)}
                                                checked={isNameChecked}
                                                name="isName"
                                                label={NAME}
                                                className="custom__checkbox"
                                                disabled={isImageOnlyEnabled}
                                            />
                                        </div>
                                        {isImageOnlyEnabled ? (
                                            <div className="faceCardFormv1-checkboxSec--title">
                                                <Checkbox
                                                    // onChange={() => handleTitleChange(!isTitleChecked)}
                                                    checked
                                                    name="isHrid"
                                                    label={"Hrid"}
                                                    className="custom__checkbox"
                                                    disabled
                                                />
                                            </div>
                                        ) : (
                                            <div className="faceCardFormv1-checkboxSec--title">
                                                <Checkbox
                                                    onChange={() => handleTitleChange(!isTitleChecked)}
                                                    checked={isTitleChecked}
                                                    name="isTitle"
                                                    label={TITLE}
                                                    className="custom__checkbox"
                                                />
                                            </div>
                                        )}
                                        <div className="faceCardFormv1-checkboxSec--location">
                                            <Checkbox
                                                onChange={() =>
                                                    handleLocationChange(!isLocationChecked)
                                                }
                                                checked={isLocationChecked}
                                                name="isLocation"
                                                label={LOCAITON}
                                                className="custom__checkbox"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* </div> */}
                        </div>

                    </div>
                    <div className="faceCardFormv1-header">
                        <div className='faceCardFormv1-searchSec'>
                            <>
                                <TabComponent
                                    className="faceCardFormv1__tabsComponent"
                                    defaultActiveIndex={0}
                                    menu={{ secondary: true, pointing: true }}
                                    hideCount
                                    onTabChangeCallback={this.onTabClicked}
                                    arrayOfPaneElements={tabsArray}>
                                </TabComponent>
                                <div className="faceCardFormv1__resetbtn">
                                    <Button
                                        className="green-transparent"
                                        onClick={() => this.setState({ isResetModalOpen: true })}
                                        disabled={
                                            !this.state.isResetButtonEnabled ||
                                            (this.state.byType === BY_CASE_NO && this.state.isCaseTeamEmpty) ||
                                            this.state.isLoadingEmailPeople
                                        }
                                    >
                                        <span>{"Clear All"}</span>
                                    </Button>
                                    <WarningModal
                                        heading={RESET_WARNING_MESSAGE}
                                        button1={{
                                            text: WARNING_MODAL_CANCEL,
                                            clickHandler: this.closeResetModal,
                                        }}
                                        button2={{
                                            text: RESET_BTN_CONFIRM,
                                            clickHandler: this.resetHandler,
                                        }}
                                        iconName={"warning"}
                                        isOpen={this.state.isResetModalOpen}
                                        onRequestClose={this.closeResetModal}
                                    />
                                </div>
                            </>
                            <PeopleSelector
                                placeholder={PEOPLE_SELECTOR_PLACEHOLDER}
                                isInputFirst
                                name="authors"
                                preFillData={[peopleIds]}
                                isStateReset={!peopleIds.length}
                                isBigImage
                                excludeAlumni
                                callBackAfterPeopleApiFetch={this.callBackAfterPeopleApiFetch}
                                caseTeamWithNoAlumniCallback={this.caseTeamWithNoAlumniCallback}
                                hideInput={byType === BY_CASE_NO || byType === BY_EMAIL_ID}
                                onChange={(data) => {
                                    this.handleChange(data);
                                }}
                                suggestions={{
                                    useDragDrop: true
                                }}
                                customSelect={
                                    <>
                                        {byType === BY_CASE_NO &&
                                            // <input className="ffaceCardFormv1__caseinput" type='text' placeholder='Search by Case number' />
                                            <>
                                                <SearchBox
                                                    handleOnSearch={this.searchCaseHandler}
                                                    selectedCase={selectedCase}
                                                    crossClickCallback={this.crossClickCallback}
                                                    handleOutsideClick={this.clearSearchResults}
                                                    label={SEARCH_RELATED}
                                                    placeHolder={SEARCH_PLACEHOLDER}
                                                    showErrorInResults={true}
                                                    noResultsMessage={NO_RESULTS}
                                                    tooManyResultsMessage={REFINE_SEARCH}
                                                    maximumResults={SEARCH_CASES_LIMIT}
                                                    maxLength={SEARCH_CASE_CHAR_LIMIT}
                                                    searchResults={searchResults}
                                                    totalCount={totalSearchResults}
                                                    resultsHeading={"Case Suggestions"}
                                                    isSearching={isSearchingCases}
                                                    validateInput={validateCaseSearch}
                                                    handleOnResultClick={this.handleOnResultClick}
                                                    renderResult={item => {
                                                        const { sanitizedCaseTitle, caseNumber } = item;
                                                        return <CaseSearchItem caseNumber={caseNumber} caseTitle={sanitizedCaseTitle} />
                                                    }}
                                                    hideLabel
                                                />
                                                {isCaseLoading && <Image
                                                    className="loadericon faceCardFormv1__loaderPeoplesCase"
                                                    src={LoaderIcon}
                                                    alt="In progress"
                                                />}
                                            </>
                                        }
                                        {byType === BY_EMAIL_ID &&
                                            <>
                                                <EmailInput
                                                    emailIds={this.state.emailIds}
                                                    error={this.state.emailError}
                                                    handleChange={this.handleEmailInputChange}
                                                    handleOusideClick={this.handleEmailOusideClick}
                                                    handleKeyPress={this.handleEmailKeyPress}
                                                    placeholder={FACECARD_PEOPLE_SELECTOR_EMAIL}
                                                />
                                                {isLoadingEmailPeople && <Image
                                                    className="loadericon faceCardFormv1__loaderPeoples"
                                                    src={LoaderIcon}
                                                    alt="In progress"
                                                />}
                                            </>
                                        }
                                    </>
                                }
                                getSuggestionValueCallback={() => { }}
                                className="faceCardFormv1__peopleselector"
                                isScrollable={true}
                            />
                            {showPollingError && (
                                <div className="FCG_ProposalBio__errorMessage">
                                    <Image src={warningIcon} className="FCG_ProposalBio__errorMessage-icon" />
                                    {isPollingError && <div>{isPollingError}</div>}
                                </div>
                            )}
                            {showImagePollingError && (
                                <div className="FCG_ProposalBio__errorMessage">
                                    <Image src={warningIcon} className="FCG_ProposalBio__errorMessage-icon" />
                                    {isImagePollingError && <div>{isImagePollingError}</div>}
                                </div>
                            )}
                        </div>
                        <div className='faceCardFormv1-btnSec'>
                            {getLoading && <Image
                                className="loadericon"
                                src={LoaderIcon}
                                alt="In progress"
                            />}
                            {buttons &&
                                buttons.map((btn, ind) => (
                                    <React.Fragment key={`btn-child-${ind}`}>
                                        {btn?.visible && (
                                            <Button className={`${ind == 1 ? "green" : "black"} ${btn.classname} ${btn.disabled ? 'btn-disbled' : ''}`} onClick={() => this.handleFaceCardBtnClick(btn?.id)} disabled={btn.disabled} >
                                                <span>{btn.text}</span>
                                            </Button>
                                        )}
                                    </React.Fragment>
                                ))}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    getLoading: fcgSelectors.getRequestLoading(state) || fcgSelectors.getPollingLoading(state) || fcgSelectors.getPollingImageLoading(state),
    preSignedUrl: fcgSelectors.getPresignedURL(state),
    isCaseDetailsLoading: caseConsumptionSelectors.getCaseDetailsLoading(state),
    isSearchingCases: caseMetadataSelectors.getSearching(state),
    searchResults: caseMetadataSelectors.getSearchResults(state),
    totalSearchResults: caseMetadataSelectors.getTotalSearchResults(state),
    isPollingError: fcgSelectors.getPollingError(state),
    isImagePollingError: fcgSelectors.getPollingImageError(state),
});

const mapDispatchToProps = (dispatch) => ({
    getFaceCardv1SlideData: (userDetails) => dispatch(getFaceCardv1SlideData(userDetails)),
    downloadImageOnly: (userDetails) => dispatch(downloadImageOnly(userDetails)),
    peopleResultsActions: bindActionCreators({ ...peopleResultsActions }, dispatch),
    downloadFileFromCloudFront: (url) => dispatch(downloadFileFromCloudFront(url)),
    faceCardv1SlidePeopleData: (peopleData) => dispatch(faceCardv1SlidePeopleData(peopleData)),
    searchRelatedCases: (payload) => dispatch({ type: SEARCH_CASES, payload }),
    resetSearchResults: () => dispatch({ type: RESET_SEARCH_RESULTS })
});

export const FaceCardFormGeneratorv1 = connect(
    mapStateToProps,
    mapDispatchToProps
)(FaceCardFormv1);

export { FaceCardFormv1 };
