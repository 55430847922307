import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchKnowledgeType } from "redux/actions/contributeknowledge/contributeknowledge.actions";
import { selectors as contributeKnowledgeSelectors } from "redux/reducers/contributeknowledge/contributeknowledge_reducer";
import "./ContributeKnowledgeTab.scss";
import infoIcon from 'assets/images/info-orange.svg';
import { Tooltip } from "components/shared/Tooltip";
import Image from "components/shared/Image";
import LABELS from "labels";
import { Link } from "react-router-dom";
import CONFIG from "config";
import CONSTANTS from "globalConstants";
import { PAGES, PRIMARY_CATEGORY, DTMRULE, TRIGGERS, SCREEN_NAMES } from "utils/analytics/analytics_constants";
import ANALYTICS from "utils/analytics/analytics";
import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";

const ContributeKnowledgeTab = () => {
  const dispatch = useDispatch();

  const {
    MY_NAVIGATOR: {
      INTERNAL_MSG,
      NON_INTERNAL_MSG,
      CREATE_BUNDLE,
      FILE,
      URL
    }
  } = LABELS;

  const { KNOWLEDGEBUNDLE, STEP1, ENTITLEMENTS: {
    KNOWLEDGE_CONTRIBUTIONS,
    CREATE_EDIT_DRAFT,
    SHOW_BUNDLE,
    PUBLISH_CONTENT
  } } = CONSTANTS;

  const { CREATION: { CREATE_KB_BTN } } = TRIGGERS;

  useEffect(() => {
    dispatch(fetchKnowledgeType());
  }, []);

  const fetchedKpType = useSelector(contributeKnowledgeSelectors.getKnowledgeType);

  const { isCreateEditEntitlement, showBundleEntitlement, isPublishContentEntitlement } = useSelector(
    (state) => ({
      isCreateEditEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, CREATE_EDIT_DRAFT),
      showBundleEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, SHOW_BUNDLE),
      isPublishContentEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PUBLISH_CONTENT),
    }),
  )

  const hitAnalytics = (triggerparam) => {
    // Adobe Analytics event 232
    ANALYTICS.kc.sendEventData({
      page: {
        pageInfo: {
          pageName: PAGES.CREATION
        },
        category: {
          primaryCategory: PRIMARY_CATEGORY.KP
        }
      },
      collection: {
        trigger: triggerparam,
        screenName: SCREEN_NAMES.CREATION,
      }
    }, DTMRULE.CREATION);
  }

  const showCreateBundleButton = isCreateEditEntitlement && showBundleEntitlement && isPublishContentEntitlement;

  return (
    <div className="contribute-knowledge-tab contribute-knowledge-tab__content">
      <div className="contribute-knowledge-tab__header">
        {showCreateBundleButton &&
          (<Link onClick={() => hitAnalytics(CREATE_KB_BTN)} className="contribute-knowledge-tab__bundle-button" to={{ pathname: CONFIG.UI_URL.METADATA_KB(STEP1), search: `?bundleType=${KNOWLEDGEBUNDLE}` }}>{CREATE_BUNDLE}</Link>)}
      </div>
      <div className="contribute-knowledge-tab__filters">
        {fetchedKpType &&
          fetchedKpType.map((item) => {

            const message = item.Name === "Internal" ? INTERNAL_MSG : NON_INTERNAL_MSG;

            return (
              <div key={item.Id} className="contribute-knowledge-tab__category">
                <h3 className="contribute-knowledge-tab__category-title">
                  <span className="contribute-knowledge-tab__category-title-text">{item.Name}</span>
                  <Tooltip position="top" icon={<Image src={infoIcon} />} id={`tooltip-${item.Id}`}>{message}</Tooltip>
                </h3>
                <div className="contribute-knowledge-tab__items-container">
                  {item.Children.map((child) => (
                    <div key={child.Id} className="contribute-knowledge-tab__item-box">
                      <h4 className="contribute-knowledge-tab__item-title">{child.Name}</h4>
                      <p className="contribute-knowledge-tab__item-desc">{child.Description}</p>
                      <div className="contribute-knowledge-tab__item-button">
                        <Link className="contribute-knowledge-tab__item-button--file" to={{ pathname: CONFIG.UI_URL.METADATA_KP(STEP1), search: `?option=${CONFIG.CREATION_MODAL_OPTION.FILE}&kpType=${child.Id}`, state: { kpTypeName: child.Name } }}>{FILE}</Link>
                        <Link className="contribute-knowledge-tab__item-button--url" to={{ pathname: CONFIG.UI_URL.METADATA_KP(STEP1), search: `?option=${CONFIG.CREATION_MODAL_OPTION.URL}&kpType=${child.Id}`, state: { kpTypeName: child.Name } }}>{URL}</Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ContributeKnowledgeTab;
