import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Layout } from "containers/Layout";
import { TabComponent } from 'components/shared/TabComponent';
import { TopBar } from 'components/shared/TopBar/TopBar';
import { ConsumptionDescriptionv1 } from "components/ConsumptionDescriptionV1";
import { CaseContactsListing } from 'components/CaseContactsListing';
import KPList from 'components/KPList/KPList';
import ANALYTICS from "utils/analytics/analytics";
import { ConsumptionDescription } from 'components/ConsumptionDescription/ConsumptionDescription';
import { ConsumptionTaxonomySectionV1 } from 'components/ConsumptionTaxonomySection/ConsumptionTaxonomySectionV1';
import { CaseType } from "components/CaseElements/CaseType";
import { TimeDuration } from "components/TimeDuration";
import { PAGES, DTMRULE, TRIGGERS, TOOLNAME, MATERIAL_STATUS, PRIMARY_CATEGORY, DISPLAY_MODE } from "utils/analytics/analytics_constants";
import { Location } from "components/shared/Location";
import { RelatedCases } from "components/CasesRelated/RelatedCases";
import DocvizOverlayWrapper from "components/DocvizOverlayWrapper/DocvizOverlayWrapper";
import LABELS from "labels";
import CONFIG from "config";
import CONSTANTS from "globalConstants";
import { CASE_METADATA, CCO_DATA, DOCVIZ, KP } from "redux/constants";
import { GlobalStyle, OverlayViewSubject, SUBSCRIBERS } from '@kdshared/docviz';
import DOCVIZ_CONFIG from '../Docviz/Docviz.config';
import { Loader } from "components/shared/Loader";
import { enableBodyOverflow, disableBodyOverflow } from "redux/actions/global/global.actions";
import { getPolyhierarchyApiData } from "redux/actions/polyhierarchy/polyhierarchy.actions";

// helpers
import {
	getContentToDisplay,
	getFilteredKPCategorizedList,
	areObjectsEqual,
	getmyCurrentLocation,
	isValidCaseGuid,
	getPreSignedApiEndPoint,
	sortArrayObjectByDate
} from "utils/helpers/helpers";
// Selectors
import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";
import { selectors as caseConsumptionSelectors } from "redux/reducers/caseConsumption/caseConsumption_reducer";
import { selectors as associatedKPSelectors } from "redux/reducers/associatedKPs/associatedKPs_reducer";
import { selectors as pvtpeopleresults } from "redux/reducers/pvtpeopleresults/pvtpeopleresults_reducer";
import { selectors as caseMetadataSelectors } from "redux/reducers/caseMetadata/caseMetadata_reducer";
import { selectors as kpSelectors } from "redux/reducers/knowledgepage/knowledgepage_reducer"
import { selectors as docvizSelectors } from "redux/reducers/docviz/docviz_reducer";
import { Helmet } from "react-helmet";
import ErrorPage from "containers/ErrorPage/ErrorPage";

// Styles
import "./CaseConsumption.scss";
import { triggerClippingAnalytics } from "utils/analytics/utils_analytics";

const {
	GET_CASE_METADATA,
	GET_CASE_T_AND_B,
	GET_CASE_CMP,
	GET_CASE_TYPES,
	GET_CASE_TB_DATES
} = CASE_METADATA;
const { GET_CCO_RESULTS } = CCO_DATA;
const {
	ENTITLEMENTS: {
		KNOWLEDGE_CONTRIBUTIONS,
		PREVIEW_DOWNLOAD,
		PREVIEW_DOWNLOAD_R,
		CASE_PAGES,
		VIEW_CASE_DETAIL,
		VIEW_CASE_MATERIALS,
		VIEW_CLIENT_INFO,
		EDIT,
		VIEW_BUDGET,
	}
} = CONSTANTS;
const {
	CP_CREATION: {
		CASES_KP_DOWNLOAD
	}
} = TRIGGERS;
const { DOWNLOAD_APPLICATION_NAME: { CASE_PAGE_APP_NAME } } = CONFIG;

export class CaseConsumptionContainer extends React.PureComponent {

	state = {
		contactsCount: 0,
		caseTeamData: [],
		sortedRelatedCases: [],
		docvizOpen: false,
		documentPath: "",
		kpDocvizAnalyticsData: {},
		isPageRestricted: true,
		isTbAPICalled: false
	};

	componentDidMount = () => {
		const {
			match: { params },
		} = this.props;
		const { cid } = params;
		const { getCaseDetails } = this.props;
		getCaseDetails(cid);

		this.overlaySubscriber = OverlayViewSubject.subscribe(action => {
			switch (action.type) {
				case SUBSCRIBERS.CLOSE_CLICK:
					const { globalActions: { enableBodyOverflow } } = this.props;
					this.setState({
						docvizOpen: false,
						legacyAttachmentId: '',
						legacyMaterialId: ''
					});
					enableBodyOverflow();
					break;
				case SUBSCRIBERS.CLIP_DOWNLOAD_CLICK:
					const { slides } = action;
					const {
						downloadSelectedSlides,
						docvizDoc
					} = this.props;
					const slideNumbers = slides.map(s => s.slideNumber);
					downloadSelectedSlides(slideNumbers, docvizDoc.kpId, docvizDoc);
					triggerClippingAnalytics({
						id: cid,
						kpid: docvizDoc.kpId,
						isCases: true,
						paRecommended: docvizDoc?.paRecommended,
						pageName: PAGES.CASE_PAGE,
						primaryCategory: PRIMARY_CATEGORY.CASE_PAGE,
						slideNumber: slideNumbers.join("|")
					});
					break;
				default:
					break;
			}
		});
		const {
			CASE_PAGE: PAGES_CASE_PAGE
		} = PAGES;
		const {
			CASE_PAGE: PC_CASE_PAGE
		} = PRIMARY_CATEGORY;
		const { CASE_PAGE } = TOOLNAME;
		ANALYTICS.page.initialize({ pageName: PAGES_CASE_PAGE, toolName: CASE_PAGE, category: PC_CASE_PAGE, trackPageLoad: true, pageId: { caseID: cid } });
	}

	prepareCaseContactsListing(caseTeamList, caseTeamHours) {
		let caseTeamListing = caseTeamList.map(itm => {
			const hoursObj = caseTeamHours.find((item) => (Number(item?.hrId) === Number(itm?.hrid)) && itm);
			return {
				positionTitle: itm?.casePosition,
				hrEmployeeId: itm?.hrid,
				timeAndBillingHours: hoursObj?.hours ? hoursObj.hours : '',
			}
		});
		this.setState({
			caseTeamData: caseTeamListing
		});
		const { getPolyhierarchyApiData } = this.props;
		getPolyhierarchyApiData();
	}

	componentDidUpdate = (preProps) => {
		const {
			caseDetails,
			timeAndBillingInfo,
			isTBLoading,
			timeAndBillingInfo: {
				clientName
			},
			getCCOResults,
			caseDetails: {
				relatedCasesAndRelationshipType,
			},
			getCmp,
			getCaseTypes,
			getTimeAndBillingInfo,
			match: { params },
		} = this.props;

		const {
			timeAndBillingInfo: {
				clientName: prevClientName
			},
			caseDetails: {
				relatedCasesAndRelationshipType: prevRelatedCasesAndRelationshipType,
			},
			isCaseDetailsLoading: prevIsCaseDetailsLoading
		} = preProps;

		const {
			caseTeamData,
			isPageRestricted,
			isTbAPICalled
		} = this.state;

		const { caseTeam: caseTeamList = [] } = caseDetails || {};
		const { caseTeam: caseTeamHours = [] } = timeAndBillingInfo || {};
		const { isCaseDetailsLoading } = this.props;
		const { cid } = params;
		const validId = isValidCaseGuid(cid);


		if (validId && isCaseDetailsLoading !== prevIsCaseDetailsLoading && !isCaseDetailsLoading) {
			const isPageRestricted = caseDetails?.isRestrictedCase === 'Y'
			this.setState({
				isPageRestricted
			}, () => {
				/**
				 * Reason for call this inside setState callback is previously we are checking this on condition
				 * that if isPageRestricted == false and then relatedCasesAndRelationshipType and prevRelatedCasesAndRelationshipType
				 * object are not equal then we have to call the api
				 * but because setState will take time in updating the state ans we are not using any callback for set state then
				 * till the time isPageRestricted is set to false the obj for both relatedCasesAndRelationshipType and 
				 * prevRelatedCasesAndRelationshipType is also becoming equal and because of this, condition written in 
				 * if (relatedCasesAndRelationshipType && relatedCasesAndRelationshipType?.length && !areObjectsEqual(relatedCasesAndRelationshipType, prevRelatedCasesAndRelationshipType)) {
				 * inside --- if (!isPageRestricted) { --- is now working as expected because isPageRestricted is false but
				 * areObjectsEqual(relatedCasesAndRelationshipType, prevRelatedCasesAndRelationshipType) are giving true value every time so that'w why using 
				 * this inside callback function.
				 */
				this.callOpenCloseDateApi(relatedCasesAndRelationshipType, prevRelatedCasesAndRelationshipType)
			});

			if (!isPageRestricted && cid) {
				getTimeAndBillingInfo(cid);
				getCmp(cid);
				getCaseTypes(cid);
				const that = this;
				setTimeout(function () {
					that.setState({
						isTbAPICalled: true
					})
				}, 50);

			}
		}

		if (!isPageRestricted) {
			if (caseTeamList?.length && !caseTeamData.length && !isTBLoading && isTbAPICalled) {
				this.prepareCaseContactsListing(caseTeamList, caseTeamHours);
			}

			this.callOpenCloseDateApi(relatedCasesAndRelationshipType, prevRelatedCasesAndRelationshipType);

			if (process.env.REACT_APP_IS_PVT_PEOPLE_API_ENABLED?.trim() === "true" && !!clientName && clientName !== prevClientName) {
				getCCOResults(clientName, true);
			}
			const { isDocvizLoading } = this.props;
			const { isDocvizLoading: preDocvizLoading } = preProps;
			if (preDocvizLoading && !isDocvizLoading) {
				const { globalActions: { disableBodyOverflow } } = this.props;
				this.setState({
					docvizOpen: true
				});
				disableBodyOverflow();
			}
		}

	}

	/**
	 * Below code is used to call getCaseDateAndType function to get cases tb dates
	 * @param {*} relatedCasesAndRelationshipType 
	 * @param {*} prevRelatedCasesAndRelationshipType 
	 */
	callOpenCloseDateApi = (relatedCasesAndRelationshipType, prevRelatedCasesAndRelationshipType) => {
		const { isPageRestricted } = this.state;
		const { getCaseDateAndType } = this.props;
		if (!isPageRestricted &&
			relatedCasesAndRelationshipType &&
			relatedCasesAndRelationshipType?.length &&
			!areObjectsEqual(relatedCasesAndRelationshipType, prevRelatedCasesAndRelationshipType)
		) {
			const cids = relatedCasesAndRelationshipType.map(caseType => caseType.caseGuid);
			getCaseDateAndType(cids)
		}
	}

	removeUndefinedNames = (data) => {
		return data?.filter(({ preferredName }) => !!preferredName);
	}

	setContactsCount = (data) => {
		this.setState({
			contactsCount: this.removeUndefinedNames(data)?.length
		});
	}

	getCaseAssetsCount = () => {
		const {
			isViewCaseDetailEntitlement,
			caseAssets = [],
		} = this.props;
		const { materials } = getFilteredKPCategorizedList(caseAssets);
		if (!isViewCaseDetailEntitlement) return materials.length;
		return caseAssets.length;
	}

	renderCaseAssets = () => {
		const {
			isPreviewDownloadEntitlement,
			isPreviewDownloadREntitlement,
			isViewCaseDetailEntitlement,
			isViewCaseMaterialsEntitlement,
			caseAssets = [],
		} = this.props;

		const {
			CP_CONTRIBUTION: {
				STEP_4_FIELDS: {
					CASE_MATERIALS,
					CASE_VIGNETTES,
				}
			},
			CP_CONSUMPTION: {
				NO_VIGNETTES,
				NO_MATERIALS,
				KNOWLEDGE_PAGES_ASSOCIATED,
			}
		} = LABELS;
		const {
			CP_LIMITS: { ASSETS_LIMIT }
		} = CONFIG;
		const { materials, vignettes } = getFilteredKPCategorizedList(caseAssets);
		const listingObject = [
			{
				heading: CASE_VIGNETTES,
				content: vignettes,
				ids: vignettes.map(item => item.id),
				emptyListHeading: NO_VIGNETTES,
				isVisible: isViewCaseDetailEntitlement,
			},
			{
				heading: CASE_MATERIALS,
				content: materials,
				ids: materials.map(item => item.id),
				emptyListHeading: NO_MATERIALS,
				isVisible: isViewCaseMaterialsEntitlement
			},
		];
		const caseIds = caseAssets.map(item => item.id);
		return (
			<div className="caseConsumption__caseAssetsListing">
				<div className="caseConsumption__caseAssetsListing__heading">{KNOWLEDGE_PAGES_ASSOCIATED}</div>
				{listingObject?.map((group, ind) => {
					const { heading, content, ids, emptyListHeading, isVisible } = group;
					return (
						(!!content?.length || !!ids?.length || !!emptyListHeading) && isVisible &&
						<KPList
							key={ind}
							isConsumptionView
							heading={heading}
							alwaysShowHeading
							KPIds={caseIds}
							knowLedgeItemsInitialLimit={ASSETS_LIMIT}
							kpDownloadCallback={this.handleKPDownloadAnalytics}
							emptyListHeading={emptyListHeading}
							isPreviewDownloadEntitlement={isPreviewDownloadEntitlement}
							isPreviewDownloadREntitlement={isPreviewDownloadREntitlement}
							data={content}
							handleDocvizPreview={(...params) => this.handleDocvizPreview(heading === CASE_VIGNETTES, ...params)}
							downloadApplicationName={CASE_PAGE_APP_NAME}
							isCasePage
						/>
					)
				})}
			</div>
		);
	};

	getCaseTeam = (caseTeamHrIds) => {
		return caseTeamHrIds?.length && caseTeamHrIds.map(team => {
			return { "hrEmployeeId": team }
		})
	}

	prepareRelatedCaseData = (relatedData) => {
		let data = [];
		const {
			caseTypes,
			caseOpenAndCloseDate,
		} = this.props;
		const { CASE_TYPE_LIST,
			RELATED_CASE_LIST: {
				shortDescription,
				description,
				caseOpenDate,
				projectId,
				allCaseTeams,
				caseType
			}
		} = CONFIG;
		relatedData?.length && caseTypes?.length && relatedData.forEach((relatedData => {
			const { caseTypeGuid, sanitizedCaseDescription, caseTitle, caseTeamHrIds, caseNumber, caseGuid } = relatedData;
			let type = ""
			caseTypes.forEach(caseType => {
				const { Id, Name } = caseType;
				if (Id === caseTypeGuid)
					type = CASE_TYPE_LIST[Name]
			})
			data.push({
				data: {
					[shortDescription]: caseTitle,
					[description]: sanitizedCaseDescription,
					caseNumber,
					[projectId]: caseGuid,
					[allCaseTeams]: this.getCaseTeam(caseTeamHrIds),
					[caseOpenDate]: caseOpenAndCloseDate[caseGuid]?.openDate,
					[caseType]: caseOpenAndCloseDate[caseGuid]?.caseType,
				},
				type
			})
		}))
		// sort data
		if (data.length > 0 && Object.values(caseOpenAndCloseDate).length) {
			data = sortArrayObjectByDate(data, "caseOpenDate");
		}
		return data;
	}

	previewAnalyticsTrigger = (paRecommended, kpId, isCaseVignette) => {
		const { CASE_PAGE } = TOOLNAME;
		const { PA_RECOMMENDED, NOT_PA_RECOMMENDED } = MATERIAL_STATUS;
		const {
			match: {
				params: { cid },
			}
		} = this.props;
		const {
			CP_CONSUMPTION: {
				CP_KP_CASE_MATERIAL,
				CP_KP_CASE_VIGNETTE
			}
		} = TRIGGERS;
		const { CASE_PAGE: pageName } = PAGES;
		const { PREVIEW_INTERACTIONS, DOCVIZ_OVERLAY_OPEN } = DTMRULE;
		const { CASE_PAGE: primaryCategory } = PRIMARY_CATEGORY;
		const { DOCVIZ_OVERLAY } = DISPLAY_MODE;

		/** Event 15 preview interactions */
		const newAdobeData = {
			resultType: pageName,
			displayMode: pageName,
			materialPAStatus: paRecommended ? PA_RECOMMENDED : NOT_PA_RECOMMENDED,
			page: {
				category: {
					primaryCategory,
				},
				pageInfo: {
					pageName: pageName,
					pageURL: getmyCurrentLocation(),
				},
			},
			caseID: cid,
			KCpage: {
				id: kpId
			},
			casePage: {
				trigger: isCaseVignette ? CP_KP_CASE_VIGNETTE : CP_KP_CASE_MATERIAL
			}
		};
		ANALYTICS.kc.sendEventData(newAdobeData, PREVIEW_INTERACTIONS, CASE_PAGE);

		/** Event 81 Docvis overlay open event */
		const adobeDataEvent81 = {
			...newAdobeData,
			displayMode: DOCVIZ_OVERLAY,
			search: {
				type: "N/A"
			}
		};
		ANALYTICS.kc.sendEventData(adobeDataEvent81, DOCVIZ_OVERLAY_OPEN, CASE_PAGE);
	}

	handleKPDownloadAnalytics = (kpData) => {
		const { DOWNLOAD_INTERACTIONS } = DTMRULE;
		const { CASE_PAGE } = TOOLNAME;
		const { CASE_PAGE: pageName } = PAGES;
		const { CASE_PAGE: primaryCategory } = PRIMARY_CATEGORY;
		const {
			match: {
				params: { cid },
			}
		} = this.props;

		const adobeData = {
			page: {
				category: {
					primaryCategory,
				},
				pageInfo: {
					pageName: pageName,
					pageURL: getmyCurrentLocation(),
				},
			},
			resultType: pageName,
			search: {
				type: "NA"   //Evar97
			},
			materialPAStatus: kpData?.materialPAStatus,
			KCpage: {
				id: kpData?.pageId
			},
			collection: {
				ID: "NA", // eVar109
				trigger: "NA" //eVar111
			},
			caseID: cid, // Evar118
			casePage: {
				trigger: CASES_KP_DOWNLOAD //Evar132
			},
			SERPview: "NA", //eVar35
		};


		ANALYTICS.kc.sendEventData(adobeData, DOWNLOAD_INTERACTIONS, CASE_PAGE);
	}

	handleDocvizPreview = (isCaseVignette, kpId, legacyMaterialId, legacyAttachmentId, paRecommended, documentPath) => {
		const { getDocvizMetadata } = this.props;
		const {
			match: {
				params: { cid },
			}
		} = this.props;
		const { CASE_PAGE } = PAGES;
		this.setState({
			legacyMaterialId,
			legacyAttachmentId,
			documentPath,
			kpDocvizAnalyticsData: {
				id: cid,
				paRecommended,
				category: CASE_PAGE
			}
		});
		getDocvizMetadata(kpId);
		this.previewAnalyticsTrigger(paRecommended, kpId, isCaseVignette)
	};

	caseContactListingCallback = (analyticsData) => {
		const {
			IDENTIFIER: {
				CARD_CP_VIEW_OTHER_CONTACTS,
				CARD_CP_CASE_TEAM_MEMBER,
				CARD_CP_CCO,
				CARD_CP_KMS_OWNER,
				CARD_CP_BILLING_PARTNER,
				CARD_CP_ALUMNI,
				CARD_ADDITIONAL_TEAM_MEMBER,
				CARD_EMAIL,
				Cases_DESCRIPTION_SHOW_LESS,
				Cases_DESCRIPTION_SHOW_MORE,
				CP_PRICING_IDENTIFIER,
				CP_KN_LEGACY_IDENTIFIER,
				CP_EDIT_IDENTIFIER,
				CP_KNOWLEDGE_ASSETS_ACTIVE_IDENTIFIER,
				CP_KNOWLEDGE_ASSETS_INACTIVE_IDENTIFIER,
				CP_SEARCH_AND_DISCOVERY_TAGS_IDENTIFIER,
				CP_ASSOCIATED_PA_TOPIC_SECTOR_IDENTIFIER,
			},
		} = LABELS;
		const {
			CASE_PAGE_ACTIONS,
			VIEW_AUTHOR_PROFILE,
			AUTHOR_EMAIL_INTERACTION,
		} = DTMRULE;
		const {
			CP_DESCRIPTION_SHOW_MORE,
			CP_DESCRIPTION_SHOW_LESS,
			CP_EDIT,
			CP_PRICING,
			CP_KN_LEGACY,
			CP_KNOWLEDGE_ASSETS_INACTIVE,
			CP_KNOWLEDGE_ASSETS_ACTIVE,
			CP_SEARCH_AND_DISCOVERY_TAGS,
			CP_ASSOCIATED_PA_TOPIC_SECTOR,
			CP_CONTACTS: {
				CP_VIEW_OTHER_CONTACTS,
				CP_CASE_TEAM_MEMBER,
				CP_CCO,
				CP_KMS_OWNER,
				CP_BILLING_PARTNER,
				CP_ALUMNI,
				CP_ADDITIONAL_TEAM,
			}
		} = TRIGGERS;
		const { CASE_PAGE } = TOOLNAME;
		const {
			hrId,
			identifier,
			cardIdentifier
		} = analyticsData;
		let dtmRule, trigger;

		switch (identifier) {
			case CARD_CP_VIEW_OTHER_CONTACTS:
				dtmRule = CASE_PAGE_ACTIONS;
				trigger = CP_VIEW_OTHER_CONTACTS;
				break;

			case CARD_CP_CASE_TEAM_MEMBER:
				dtmRule = VIEW_AUTHOR_PROFILE;
				trigger = CP_CASE_TEAM_MEMBER
				break;

			case CARD_CP_CCO:
				dtmRule = VIEW_AUTHOR_PROFILE;
				trigger = CP_CCO;
				break;

			case CARD_CP_KMS_OWNER:
				dtmRule = VIEW_AUTHOR_PROFILE;
				trigger = CP_KMS_OWNER;
				break;

			case CARD_CP_BILLING_PARTNER:
				dtmRule = VIEW_AUTHOR_PROFILE;
				trigger = CP_BILLING_PARTNER;
				break;

			case CARD_CP_ALUMNI:
				dtmRule = VIEW_AUTHOR_PROFILE;
				trigger = CP_ALUMNI;
				break;

			case CARD_ADDITIONAL_TEAM_MEMBER:
				dtmRule = VIEW_AUTHOR_PROFILE;
				trigger = CP_ADDITIONAL_TEAM;
				break;

			case Cases_DESCRIPTION_SHOW_MORE:
				dtmRule = CASE_PAGE_ACTIONS;
				trigger = CP_DESCRIPTION_SHOW_MORE;
				break;

			case Cases_DESCRIPTION_SHOW_LESS:
				dtmRule = CASE_PAGE_ACTIONS;
				trigger = CP_DESCRIPTION_SHOW_LESS;
				break;

			case CP_PRICING_IDENTIFIER:
				dtmRule = CASE_PAGE_ACTIONS;
				trigger = CP_PRICING;
				break;

			case CP_KNOWLEDGE_ASSETS_ACTIVE_IDENTIFIER:
				dtmRule = CASE_PAGE_ACTIONS;
				trigger = CP_KNOWLEDGE_ASSETS_ACTIVE;
				break;

			case CP_KNOWLEDGE_ASSETS_INACTIVE_IDENTIFIER:
				dtmRule = CASE_PAGE_ACTIONS;
				trigger = CP_KNOWLEDGE_ASSETS_INACTIVE;
				break;

			case CP_EDIT_IDENTIFIER:
				dtmRule = CASE_PAGE_ACTIONS;
				trigger = CP_EDIT;
				break;

			case CP_KN_LEGACY_IDENTIFIER:
				dtmRule = CASE_PAGE_ACTIONS;
				trigger = CP_KN_LEGACY;
				break;

			case CP_SEARCH_AND_DISCOVERY_TAGS_IDENTIFIER:
				dtmRule = CASE_PAGE_ACTIONS;
				trigger = CP_SEARCH_AND_DISCOVERY_TAGS;
				break;

			case CP_ASSOCIATED_PA_TOPIC_SECTOR_IDENTIFIER:
				dtmRule = CASE_PAGE_ACTIONS;
				trigger = CP_ASSOCIATED_PA_TOPIC_SECTOR;
				break;

			default:
				dtmRule = CASE_PAGE_ACTIONS;
				break;

		}
		if (cardIdentifier && cardIdentifier === CARD_EMAIL) {
			dtmRule = AUTHOR_EMAIL_INTERACTION;
		}
		const newAdobeData = this.prepareAnalyticsData(analyticsData, trigger);
		if (hrId) newAdobeData.authorStaffID = hrId;
		ANALYTICS.kc.sendEventData(newAdobeData, dtmRule, CASE_PAGE);
	}

	prepareAnalyticsData = (analyticsData, trigger) => {
		const {
			pageId,
			materialPAStatus,
			materialID,
			attachmentID,
			displayMode
		} = analyticsData;
		const {
			match: {
				params: { cid },
			}
		} = this.props;
		const newAdobeData = {
			caseID: cid,
			casePage: {
				trigger: trigger,
			},
			page: {
				category: {
					primaryCategory: PRIMARY_CATEGORY.CASE_PAGE,
				},
				pageInfo: {
					pageName: PAGES.CASE_PAGE,
					pageURL: getmyCurrentLocation()
				},
			},
			resultType: PRIMARY_CATEGORY.CASE_PAGE,
		};

		if (pageId) newAdobeData.KCpage = { id: pageId };
		if (materialPAStatus) newAdobeData.materialPAStatus = materialPAStatus;
		if (displayMode) newAdobeData.displayMode = displayMode;
		if (attachmentID) newAdobeData.attachmentID = attachmentID;
		if (materialID) newAdobeData.materialID = materialID;

		return newAdobeData;
	}

	sortOrderSelectionCallback = (analyticsData) => {
		const {
			SORT_ORDER_SELECTION,
		} = DTMRULE;
		const { CASE_PAGE } = TOOLNAME;
		const {
			selectedSortOrder,
			preFilterTerm,
			preOrderTerm,
		} = analyticsData;
		const {
			match: {
				params: { cid },
			}
		} = this.props;
		const newAdobeData = {
			caseID: cid,
			page: {
				category: {
					primaryCategory: PRIMARY_CATEGORY.CASE_PAGE,
				},
				pageInfo: {
					pageName: PAGES.CASE_PAGE,
					pageURL: getmyCurrentLocation()
				},
			},
			selectedSortOrder,
			preFilterTerm,
			preOrderTerm,
			resultType: PRIMARY_CATEGORY.CASE_PAGE,
		};
		ANALYTICS.kc.sendEventData(newAdobeData, SORT_ORDER_SELECTION, CASE_PAGE);
	}

	render() {
		const {
			CP_CONSUMPTION: {
				PAGE_TITLE,
				CLIENT_NAME,
				CLIENT_DESCRIPTION,
				CLIENT_BU,
				CLIENT_BU_DESCRIPTION,
				DESCRIPTION_HEADING,
				NO_DESCRIPTION_TEXT,
				WITHDRAWN_CASE_BANNER_HEADING,
				WITHDRAWN_CASE_BANNER_MESSAGE,
				CONTACTS_HEADING,
				KNOWLEDGE_ASSETS,
			},
			KNOWLEDGE_PRODUCT_NAME: {
				CP_CONSUMPTION
			},
		} = LABELS;
		const {
			CP_LIMITS: { DESCRIPTION_HEIGHT },
			CP_DATE_FORMAT,
		} = CONFIG;
		const {
			ERROR_CASE_RESTRICTED_MDP,
			ERROR_INVALID_CASE_GUID
		} = CONSTANTS;
		const {
			caseDetails: {
				clientDescription,
				clientBusinessUnitDescription,
				knowledgeAssets,
				caseVignettesKnowledgeAssets: caseVignettes,
				sanitizedCaseTitle,
				sanitizedCaseDescription,
				projectID,
				isWithdrawn,
				fpaTagList = [],
				bcgInternalList = [],
				ipaTagList = [],
				subjectTags = [],
				office = {},
				relatedCasesAndRelationshipType,
			},
			timeAndBillingInfo: {
				caseNumber,
				caseType,
				openDate,
				closeDate,
				clientName,
				clientBUName,
				tbBillingLocal,
				tbBillingUsd,
				budget,
				billingPartnerHrId,
				clientCoordinatingOfficer,
				executingMDPHrId
			},
			caseAssets = [],
			isViewCaseDetailEntitlement,
			isViewCaseMaterialsEntitlement,
			isViewClientInfo,
			isEditCaseEntitlement,
			isViewBudgetEntitlement,
			knwOwnerHrId,
			match: { params },
			docvizDoc,
			downloadStatus,
			isCaseDetailsLoading,
			getKPFile,
			downloadAllStatus
		} = this.props;
		const { cid } = params;
		const {
			caseTeamData,
			contactsCount,
			docvizOpen,
			documentPath,
			kpDocvizAnalyticsData
		} = this.state;
		const clientNameToShow = getContentToDisplay(clientName, "");
		const clientDescToShow = getContentToDisplay(clientDescription, "", !!isViewCaseDetailEntitlement);
		const clientBUNameToShow = getContentToDisplay(clientBUName, "", clientName?.toLowerCase() !== clientBUName?.toLowerCase());
		const clientBUDescToShow = getContentToDisplay(clientBusinessUnitDescription, "", !!isViewCaseDetailEntitlement);
		const { id: officeId, name: officeName } = office;
		const caseAssetsCount = this.getCaseAssetsCount(caseAssets);
		const sortedRelatedCases = this.prepareRelatedCaseData(relatedCasesAndRelationshipType);
		let billingInfo = false;
		if (isViewBudgetEntitlement || isEditCaseEntitlement) {
			billingInfo = {
				budget,
			};
			if (isEditCaseEntitlement) {
				billingInfo = { ...billingInfo, tbBillingLocal, tbBillingUsd }
			}
		}
		const subjectTagsToShow = getContentToDisplay(subjectTags, []);
		const fpaTagListToShow = getContentToDisplay(fpaTagList, []);
		const bcgInternalListToShow = getContentToDisplay(bcgInternalList, []);
		const ipaTagListToShow = getContentToDisplay(ipaTagList, []);
		const { otherProps } = DOCVIZ_CONFIG;
		const { isPageRestricted } = this.state;
		const preSignedApiEndPoint = getPreSignedApiEndPoint(documentPath);
		const isPdf = preSignedApiEndPoint.search('.pdf') !== -1;
		const validId = isValidCaseGuid(cid);
		return (
			<Layout
				id="caseConsumption"
				layoutClass="layout__caseConsumption"
				pageTitle={PAGE_TITLE}
				productName={CP_CONSUMPTION}
			>
				{(isCaseDetailsLoading ?
					<Loader
						isVisible={true}
					/> :
					(!validId || isPageRestricted ?
						<ErrorPage
							errorCodeProp={!validId ? ERROR_INVALID_CASE_GUID : ERROR_CASE_RESTRICTED_MDP}
						/>
						:
						<>
							<Helmet>
								<title>{`Case${sanitizedCaseTitle ? ": " + sanitizedCaseTitle : ""}`}</title>
							</Helmet>
							{!!cid && <div className="caseConsumption">
								<GlobalStyle />
								{docvizOpen && (
									<DocvizOverlayWrapper
										preSignedApiEndPoint={isPdf ? preSignedApiEndPoint : ""}
										downloadAllStatus={downloadAllStatus}
										getKPFile={getKPFile}
										doc={docvizDoc}
										{...otherProps}
										downloadStatus={downloadStatus}
										kpDocvizAnalyticsData={kpDocvizAnalyticsData}
									/>
								)}
								{isWithdrawn && (
									<TopBar
										heading={WITHDRAWN_CASE_BANNER_HEADING}
										message={WITHDRAWN_CASE_BANNER_MESSAGE}
										barColor="caseConsumption__topbar"
									/>
								)}
								<div className="caseConsumption__caseSummaryWrapper">
									<div className="layout__fix--inner caseConsumption__caseSummary">
										<section className="caseConsumption__caseSummary__section">
											<CaseType caseType={caseType} caseNumber={!!isViewClientInfo ? caseNumber : ""} />
											{(!!openDate || !!closeDate) && (<TimeDuration incomingDateFormat={CP_DATE_FORMAT} startDate={openDate} endDate={closeDate} />)}
										</section>
										<section className="caseConsumption__caseSummary__section right">
											<Location id={officeId} name={getContentToDisplay(officeName)} />
										</section>
									</div>
								</div>
								<ConsumptionDescriptionv1
									title={sanitizedCaseTitle}
									projectId={projectID}
									showEdit={isEditCaseEntitlement}
									caseGuid={cid}
									showBilling={true}
									showAssets={false}
									knowledgeAssets={isViewCaseMaterialsEntitlement ? knowledgeAssets : []}
									caseVignettes={isViewCaseDetailEntitlement ? caseVignettes : []}
									showAssetsv1={isViewCaseMaterialsEntitlement || isViewCaseDetailEntitlement}
									billingInfo={billingInfo}
									showBudget={isViewBudgetEntitlement}
									caseContactListingCallback={this.caseContactListingCallback}
									descriptionObj={[
										{
											heading1: isViewClientInfo ? CLIENT_NAME : null,
											description1: isViewClientInfo ? clientNameToShow : null,
											heading2: CLIENT_DESCRIPTION,
											description2: clientDescToShow,
										},
										{
											heading1: isViewClientInfo ? CLIENT_BU : null,
											description1: isViewClientInfo ? clientBUNameToShow : null,
											heading2: CLIENT_BU_DESCRIPTION,
											description2: clientBUDescToShow,
										},
									]}
								/>
								<div className="caseConsumption__maincontent layout__fix--inner">
									<section className="caseConsumption__maincontent-desc caseConsumption__maincontent__column">
										<TabComponent
											className="caseConsumption__tab-component tabs__container"
											defaultActiveIndex={0}
											hideCount={true}
											disable={true}
											menu={{ secondary: true, pointing: true }}
											arrayOfPaneElements={[
												{
													className: 'item-style',
													heading: DESCRIPTION_HEADING,
													component: (
														<ConsumptionDescription
															description={sanitizedCaseDescription || NO_DESCRIPTION_TEXT}
															initialHeight={DESCRIPTION_HEIGHT}
															needHeaderMargin={false}
															showMoreButtonCallback={this.caseContactListingCallback}
															downloadApplicationName={CASE_PAGE_APP_NAME}
														/>
													),
												},
											]}
										/>
									</section>
									<section className="caseConsumption__maincontent-contacts caseConsumption__maincontent__column">
										<TabComponent
											className="caseConsumption__tab-component tabs__container"
											defaultActiveIndex={0}
											disable={true}
											menu={{ secondary: true, pointing: true }}
											arrayOfPaneElements={[
												{
													className: 'item-style',
													heading: CONTACTS_HEADING,
													count: contactsCount,
													component: (
														<CaseContactsListing
															caseDetails={{
																cco: clientCoordinatingOfficer?.hrId,
																kmsOwner: knwOwnerHrId,
																allCaseTeams: caseTeamData,
																billingPartnerHrId: billingPartnerHrId,
																executingMDPHrId: executingMDPHrId
															}}
															isHideCCO={!isEditCaseEntitlement}
															caseTeamDataSetCallback={this.setContactsCount}
															showBillingPartner
															caseContactsClickCallback={this.caseContactListingCallback}
															sortOrderSelectionCallback={this.sortOrderSelectionCallback}
														/>
													),
												},
											]}
										/>
									</section>
								</div>
								{(isViewCaseDetailEntitlement || isViewCaseMaterialsEntitlement) && (
									<div className="caseConsumption__caseAssetsWrapper layout__fix--inner">
										<TabComponent
											className="caseConsumption__tab-component tabs__container"
											defaultActiveIndex={0}
											isCollapsible={true}
											menu={{ secondary: true, pointing: true }}
											arrayOfPaneElements={[
												{
													className: 'item-style',
													heading: KNOWLEDGE_ASSETS,
													count: caseAssetsCount,
													component: this.renderCaseAssets()
												},
											]}
										/>
									</div>
								)}
								<div className="layout__fix--inner caseConsumption__taxonomyWrapper">
									<ConsumptionTaxonomySectionV1
										subjectsShowMore
										sectorsShowMore
										subjects={subjectTagsToShow}
										ipaTagList={ipaTagListToShow}
										fpaTagList={fpaTagListToShow}
										bcgInternalList={bcgInternalListToShow}
										caseContactListingCallback={this.caseContactListingCallback}
									/>
								</div>
							</div>}
							{!!sortedRelatedCases && sortedRelatedCases?.length > 0 &&
								<div className="cpconsumption__tabs cpconsumption__gap layout__fix--inner">
									<RelatedCases
										relatedCases={sortedRelatedCases}
										projectId={cid}
										redirectOnV1
										isViewClientInfo={isViewClientInfo}
									/>
								</div>
							}
						</>
					)
				)}
			</Layout>
		)
	}
}

const mapStateToProps = (state) => ({
	caseDetails: caseConsumptionSelectors.getCaseDetails(state),
	isCaseDetailsLoading: caseConsumptionSelectors.getCaseDetailsLoading(state),
	timeAndBillingInfo: caseMetadataSelectors.getTimeAndBillingData(state),
	isTBLoading: caseMetadataSelectors.getTimeAndBillingLoading(state),
	isPreviewDownloadEntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PREVIEW_DOWNLOAD),
	isPreviewDownloadREntitlement: entitlementSelectors.getEntitlementValue(state, KNOWLEDGE_CONTRIBUTIONS, PREVIEW_DOWNLOAD_R),
	isViewCaseDetailEntitlement: entitlementSelectors.getEntitlementValue(state, CASE_PAGES, VIEW_CASE_DETAIL),
	isViewCaseMaterialsEntitlement: entitlementSelectors.getEntitlementValue(state, CASE_PAGES, VIEW_CASE_MATERIALS),
	isViewClientInfo: entitlementSelectors.getEntitlementValue(state, CASE_PAGES, VIEW_CLIENT_INFO),
	isViewBudgetEntitlement: entitlementSelectors.getEntitlementValue(state, CASE_PAGES, VIEW_BUDGET),
	isEditCaseEntitlement: entitlementSelectors.getEntitlementValue(state, CASE_PAGES, EDIT),
	caseAssets: associatedKPSelectors.getAssociatedKPs(state),
	primaryCCO: pvtpeopleresults.getPrimaryCCO(state),
	knwOwnerHrId: caseMetadataSelectors.getKnwOwnerHrId(state),
	caseTypes: caseMetadataSelectors.getCaseTypes(state),
	caseOpenAndCloseDate: caseMetadataSelectors.getCaseOpenAndCloseDate(state),
	docvizDoc: docvizSelectors.getDocvizDocument(state),
	isDocvizLoading: docvizSelectors.getLoading(state),
	downloadStatus: docvizSelectors.getDownloadStatus(state),
	downloadAllStatus: kpSelectors.getLoadingDownloadAll(state)
});
const mapDispatchToProps = (dispatch) => ({
	globalActions: bindActionCreators({ enableBodyOverflow, disableBodyOverflow }, dispatch),
	getCaseDetails: (cid) => dispatch({ type: GET_CASE_METADATA, payload: { cid, isConsumption: true } }),
	getTimeAndBillingInfo: (cid) => dispatch({ type: GET_CASE_T_AND_B, payload: { cid } }),
	getCCOResults: (data, isByName) => dispatch({ type: GET_CCO_RESULTS, payload: { data, isByName } }),
	getCmp: (cid) => dispatch({ type: GET_CASE_CMP, payload: { cid } }),
	getCaseDateAndType: (cid) => dispatch({ type: GET_CASE_TB_DATES, payload: { cid } }),
	getDocvizMetadata: (id) => dispatch({ type: DOCVIZ.GET_DOCVIZ_METADATA, payload: { id } }),
	getCaseTypes: () => dispatch({ type: GET_CASE_TYPES }),
	downloadSelectedSlides: (pages, documentId, docvizDoc) => dispatch({
		type: DOCVIZ.DOWNLOAD_SELECTED_SLIDES,
		payload: {
			pages,
			documentId,
			doc: docvizDoc,
			appName: CASE_PAGE_APP_NAME
		}
	}),
	getPolyhierarchyApiData: () => dispatch(getPolyhierarchyApiData()),
	getKPFile: (kpId) => dispatch({
		type: KP.GET_FILE,
		payload: {
			kpId: kpId,
			appName: CASE_PAGE_APP_NAME,
			type: CONFIG.downloadTypeForKP
		}
	}),
});

const CaseConsumption = connect(
	mapStateToProps,
	mapDispatchToProps
)(CaseConsumptionContainer);

export default CaseConsumption;
